import React, { useEffect, useMemo, useState } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Photo from "../User/Photo";
import NULLproductos from "../../assets/images/NULL/nullproducts.svg";
import NULLservices from "../../assets/images/NULL/nullservices.svg";
import { analyticsEvent } from "../../services/analytics";
import { useStateValue } from "../../providers/StateProvider";
import { formatPrice } from "../../shared/utils/helpers/format";
import ProductSlidePhotos from "../Photos/ProductSlidePhotos";

function ItemGrafico(props) {
  const [modalMais, setModalMais] = useState(false);
  const dataArray = props?.salesProducts?.slice(0, 4);
  const [{ activeMoeda, activeLanguage }] = useStateValue();

  const filterDataArray = useMemo(() => {
    return dataArray?.sort((x, y) => {
      let a = parseFloat(x?.porcento);
      let b = parseFloat(y?.porcento);

      // return a - b;
      if (a > b) {
        return -1;
      }
      if (a < b) {
        return 1;
      }

      return 0;
    });
  }, [dataArray]);

  useEffect(() => {
    const chartRef = {};

    if (!chartRef.current) {
      chartRef.current = am4core.create(
        `chartdiv_${props.type}`,
        am4charts.PieChart
      );

      chartRef.current.data = filterDataArray;
      chartRef.current.preloader.disabled = true;
      chartRef.current.invalidateRawData();
      chartRef.current.innerRadius = am4core.percent(38);

      let pieSeries = chartRef.current.series.push(new am4charts.PieSeries());

      am4core.options.autoSetClassName = true;

      pieSeries.dataFields.value = "porcentoNumber";
      pieSeries.dataFields.category = "name";
      pieSeries.interpolationDuration = 0;
      pieSeries.showOnInit = false;
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 1;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;
      pieSeries.slices.template.propertyFields.fill = "color";

      am4core.options.autoDispose = true;
    }
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, [filterDataArray, props.type]);

  return (
    <div style={props.style}>
      <p className="myne-text">{props.typeGrafico}</p>

      <section>
        <div className="myne-chartdiv" id={`chartdiv_${props.type}`}></div>

        <div>
          {filterDataArray?.map((saleProduct, i) => (
            <section key={saleProduct.name + i}>
              <div>
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
                    fill={saleProduct?.color}
                  />
                </svg>
                <p className="myne-text">{saleProduct.name}</p>
              </div>

              <h5 className="myne-text">{saleProduct.porcento}</h5>
            </section>
          ))}
        </div>

        <section>
          <button
            onClick={() => {
              if (props.type === "products") {
                analyticsEvent("PRODUCT_SALE_SEE_MORE_CLICKED");
              }
              if (props.type === "clients") {
                analyticsEvent("SALE_BY_CLIENT_SEE_MORE_CLICKED");
              }
              if (props.type === "services") {
                analyticsEvent("SERVICE_SALE_SEE_MORE_CLICKED");
              }

              setModalMais(true);
            }}
          >
            {activeLanguage?.sales?.graph_sales?.btn_more}
          </button>
        </section>
      </section>

      <div
        style={{
          opacity: modalMais ? "5" : "0",
          visibility: modalMais ? "visible" : "hidden",
        }}
        className="myne-modal"
      >
        <div
          style={{ transform: modalMais ? "scale(1)" : "scale(0.7)" }}
          className="myne-modal__modal-sales"
        >
          {modalMais ? (
            <>
              <div className="modal-sales__header">
                <section>
                  {props.type === "products" ? (
                    <svg
                      width="18"
                      height="21"
                      viewBox="0 0 18 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 5H14C14 3.67392 13.4732 2.40215 12.5355 1.46447C11.5979 0.526784 10.3261 0 9 0C7.67392 0 6.40215 0.526784 5.46447 1.46447C4.52678 2.40215 4 3.67392 4 5H2C1.47005 5.00158 0.962265 5.2128 0.587535 5.58753C0.212805 5.96227 0.00158273 6.47005 0 7V19C0.00158273 19.5299 0.212805 20.0377 0.587535 20.4125C0.962265 20.7872 1.47005 20.9984 2 21H16C16.5299 20.9984 17.0377 20.7872 17.4125 20.4125C17.7872 20.0377 17.9984 19.5299 18 19V7C17.9984 6.47005 17.7872 5.96227 17.4125 5.58753C17.0377 5.2128 16.5299 5.00158 16 5ZM9 2C9.79565 2 10.5587 2.31607 11.1213 2.87868C11.6839 3.44129 12 4.20435 12 5H6C6 4.20435 6.31607 3.44129 6.87868 2.87868C7.44129 2.31607 8.20435 2 9 2ZM9 12C7.89134 12.0013 6.81381 11.6334 5.93742 10.9544C5.06103 10.2753 4.43567 9.32384 4.16 8.25C4.12181 8.10209 4.11801 7.94739 4.14891 7.79778C4.1798 7.64817 4.24456 7.50762 4.33821 7.38693C4.43187 7.26624 4.55193 7.16862 4.68918 7.10154C4.82644 7.03447 4.97723 6.99973 5.13 7C5.35853 7.00721 5.57834 7.08947 5.75546 7.23406C5.93259 7.37865 6.05718 7.57754 6.11 7.8C6.28584 8.43132 6.66356 8.9877 7.18544 9.38409C7.70732 9.78048 8.34465 9.99508 9 9.99508C9.65535 9.99508 10.2927 9.78048 10.8146 9.38409C11.3364 8.9877 11.7142 8.43132 11.89 7.8C11.9428 7.57754 12.0674 7.37865 12.2445 7.23406C12.4217 7.08947 12.6415 7.00721 12.87 7C13.0224 7.0009 13.1726 7.03641 13.3092 7.10384C13.4459 7.17126 13.5654 7.26886 13.6588 7.38924C13.7522 7.50962 13.8171 7.64966 13.8485 7.79877C13.8799 7.94788 13.877 8.10217 13.84 8.25C13.5643 9.32384 12.939 10.2753 12.0626 10.9544C11.1862 11.6334 10.1087 12.0013 9 12Z"
                        fill="#AA00FF"
                      />
                    </svg>
                  ) : props.type === "clients" ? (
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.9 17.2C9.71535 17.1935 8.54999 16.8988 7.50466 16.3413C6.45932 15.7838 5.56541 14.9802 4.90004 14C4.90004 12 8.90004 10.9 10.9 10.9C12.9 10.9 16.9 12 16.9 14C16.2347 14.9802 15.3407 15.7838 14.2954 16.3413C13.25 16.8988 12.0847 17.1935 10.9 17.2ZM10.9 3C11.296 2.99267 11.6894 3.06526 12.0567 3.21343C12.4239 3.3616 12.7576 3.58231 13.0377 3.86236C13.3177 4.14241 13.5384 4.47605 13.6866 4.84334C13.8348 5.21063 13.9074 5.60402 13.9 6C13.9 6.79565 13.5839 7.55871 13.0213 8.12132C12.4587 8.68393 11.6957 9 10.9 9C10.1044 9 9.34132 8.68393 8.77871 8.12132C8.2161 7.55871 7.90004 6.79565 7.90004 6C7.89272 5.60402 7.96525 5.21063 8.11342 4.84334C8.26159 4.47605 8.4823 4.14241 8.76235 3.86236C9.0424 3.58231 9.37608 3.3616 9.74337 3.21343C10.1107 3.06526 10.5041 2.99267 10.9 3ZM10.9 0C8.92223 0 6.98881 0.58649 5.34432 1.6853C3.69983 2.78412 2.41809 4.3459 1.66121 6.17317C0.904337 8.00043 0.706331 10.0111 1.09218 11.9509C1.47804 13.8907 2.43041 15.6725 3.82894 17.0711C5.22746 18.4696 7.00931 19.422 8.94912 19.8079C10.8889 20.1937 12.8996 19.9957 14.7268 19.2388C16.5541 18.4819 18.1159 17.2002 19.2147 15.5557C20.3136 13.9112 20.9 11.9778 20.9 10C20.8924 7.35019 19.8363 4.81109 17.9626 2.93738C16.0889 1.06368 13.5499 0.00765122 10.9 0V0Z"
                        fill="#AA00FF"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="18"
                      height="21"
                      viewBox="0 0 18 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 5H14C14 3.67392 13.4732 2.40215 12.5355 1.46447C11.5979 0.526784 10.3261 0 9 0C7.67392 0 6.40215 0.526784 5.46447 1.46447C4.52678 2.40215 4 3.67392 4 5H2C1.47005 5.00158 0.962265 5.2128 0.587535 5.58753C0.212805 5.96227 0.00158273 6.47005 0 7V19C0.00158273 19.5299 0.212805 20.0377 0.587535 20.4125C0.962265 20.7872 1.47005 20.9984 2 21H16C16.5299 20.9984 17.0377 20.7872 17.4125 20.4125C17.7872 20.0377 17.9984 19.5299 18 19V7C17.9984 6.47005 17.7872 5.96227 17.4125 5.58753C17.0377 5.2128 16.5299 5.00158 16 5ZM9 2C9.79565 2 10.5587 2.31607 11.1213 2.87868C11.6839 3.44129 12 4.20435 12 5H6C6 4.20435 6.31607 3.44129 6.87868 2.87868C7.44129 2.31607 8.20435 2 9 2ZM9 12C7.89134 12.0013 6.81381 11.6334 5.93742 10.9544C5.06103 10.2753 4.43567 9.32384 4.16 8.25C4.12181 8.10209 4.11801 7.94739 4.14891 7.79778C4.1798 7.64817 4.24456 7.50762 4.33821 7.38693C4.43187 7.26624 4.55193 7.16862 4.68918 7.10154C4.82644 7.03447 4.97723 6.99973 5.13 7C5.35853 7.00721 5.57834 7.08947 5.75546 7.23406C5.93259 7.37865 6.05718 7.57754 6.11 7.8C6.28584 8.43132 6.66356 8.9877 7.18544 9.38409C7.70732 9.78048 8.34465 9.99508 9 9.99508C9.65535 9.99508 10.2927 9.78048 10.8146 9.38409C11.3364 8.9877 11.7142 8.43132 11.89 7.8C11.9428 7.57754 12.0674 7.37865 12.2445 7.23406C12.4217 7.08947 12.6415 7.00721 12.87 7C13.0224 7.0009 13.1726 7.03641 13.3092 7.10384C13.4459 7.17126 13.5654 7.26886 13.6588 7.38924C13.7522 7.50962 13.8171 7.64966 13.8485 7.79877C13.8799 7.94788 13.877 8.10217 13.84 8.25C13.5643 9.32384 12.939 10.2753 12.0626 10.9544C11.1862 11.6334 10.1087 12.0013 9 12Z"
                        fill="#AA00FF"
                      />
                    </svg>
                  )}

                  <p className="myne-text">{props.typeGrafico}</p>
                </section>

                <svg
                  onClick={() => setModalMais(false)}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875ZM14.3125 14.3125C14.75 13.8125 14.75 13.3438 14.3125 12.9062L11.4062 10L14.3125 7.09375C14.75 6.65625 14.75 6.1875 14.3125 5.6875C13.8125 5.25 13.3438 5.25 12.9062 5.6875L10 8.59375L7.09375 5.6875C6.65625 5.25 6.1875 5.25 5.6875 5.6875C5.25 6.1875 5.25 6.65625 5.6875 7.09375L8.59375 10L5.6875 12.9062C5.25 13.3438 5.25 13.8125 5.6875 14.3125C6.1875 14.75 6.65625 14.75 7.09375 14.3125L10 11.4062L12.9062 14.3125C13.3438 14.75 13.8125 14.75 14.3125 14.3125Z"
                    fill="black"
                    fillOpacity="0.60"
                  />
                </svg>
              </div>

              <div className="modal-sales__items">
                {props?.salesProducts?.map((saleProduct, i) => (
                  <div
                    key={saleProduct?.name + i}
                    className="myne-modal--animation-top"
                  >
                    {props.type === "products" || props.type === "services" ? (
                      saleProduct?.photo !== null &&
                        saleProduct?.photo !== undefined &&
                        saleProduct?.photo !== "" ? (
                        props.type === "products" ? (
                          <ProductSlidePhotos photos={saleProduct?.photo} />
                        ) : (
                          <img
                            loading="lazy"
                            src={NULLservices}
                            onLoad={(e) => {
                              e.target.src = saleProduct?.photo;
                            }}
                            onError={(e) => {
                              e.target.src = NULLservices;
                            }}
                            alt=""
                          />
                        )
                      ) : props.type === "products" ? (
                        <img src={NULLproductos} alt="" />
                      ) : (
                        <img src={NULLservices} alt="" />
                      )
                    ) : (
                      <Photo
                        style={{
                          borderRadius: props.type === "clients" ? "100%" : "",
                        }}
                        image={saleProduct?.photo}
                      />
                    )}

                    <section>
                      <div>
                        <p className="myne-text">{saleProduct?.name}</p>
                        {props.type === "products" ? (
                          <h5 className="myne-text">
                            {saleProduct?.unidade}{" "}
                            {activeLanguage?.sales?.graph_sales?.title_units}
                          </h5>
                        ) : props.type === "clients" ? (
                          <h5 className="myne-text">
                            {saleProduct?.vendas}{" "}
                            {saleProduct?.vendas === 1
                              ? activeLanguage?.sales?.graph_sales?.title_sales.slice(
                                0,
                                activeLanguage?.sales?.graph_sales
                                  ?.title_sales?.length - 1
                              )
                              : activeLanguage?.sales?.graph_sales?.title_sales}
                          </h5>
                        ) : (
                          ""
                        )}
                      </div>

                      <section>
                        <p className="myne-text">{saleProduct?.porcento}</p>
                        <h5 className="myne-text">
                          {formatPrice(saleProduct?.price, activeMoeda)}
                        </h5>
                      </section>
                    </section>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ItemGrafico;
