import React, { useEffect, useMemo, useRef, useState } from "react";
import NULLproductos from "../../assets/images/NULL/nullproducts.svg";
import { useStateValue } from "../../providers/StateProvider";

const ProductSlidePhotos = ({ photos, ...more }) => {
  const photo = useRef();
  const [{ ifPlusVerified }] = useStateValue()
  const [indexImage, setIndexImage] = useState(0);

  const getPhotosWithoutNull = useMemo(() => {
    if (Array.isArray(photos))
      return photos?.filter((photo) => {
        return photo != null && photo !== "";
      });
  }, [photos]);

  useEffect(() => {
    if (Array.isArray(photos) && ifPlusVerified)
      if (getPhotosWithoutNull?.length > 1) {
        const timeoutId = setTimeout(() => {
          setIndexImage((index) =>
            index >= getPhotosWithoutNull.length - 1 ? 0 : index + 1
          );
        }, 5000);

        return () => clearTimeout(timeoutId);
      }
  }, [getPhotosWithoutNull, indexImage, photos]);

  return Array.isArray(photos) ? (
    <img
      ref={photo}
      src={
        getPhotosWithoutNull[indexImage]
          ? getPhotosWithoutNull[indexImage]
          : NULLproductos
      }
      {...more}
    />
  ) : (
    <img src={photos ? photos : NULLproductos} alt="" {...more} />
  );
};

export default ProductSlidePhotos;
