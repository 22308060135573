import React, { useState } from "react";
import { useStateValue } from "../../../providers/StateProvider";
import parse from "html-react-parser";
import { goToPrice, prices } from "../../../config/plus";

function ModalPlus({
  modal,
  setmodal,
  btn_plus,
  message_locked,
  onModalPlusWithoutLocked,
}) {
  const [loading, setLoading] = useState(false);
  const [{ activeLanguage }] = useStateValue();

  return (
    <div
      style={{
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
        zIndex: 999999999,
      }}
      className="myne-modal"
    >
      <div
        style={{
          transform: modal ? "scale(1)" : "scale(0.7)",
        }}
        className="myne-modal__modal-plus border-plus"
      >
        {modal && (
          <>
            <IconClose
              onClick={() => {
                setmodal(!modal);
                if (onModalPlusWithoutLocked) {
                  onModalPlusWithoutLocked();
                }
              }}
            />

            <div
              style={{ paddingTop: !btn_plus ? 30 : "" }}
              className="myne-modal__modal-plus__content"
            >
              <div className="myne-modal__modal-plus__content-info myne-modal--animation">
                <article>
                  <BackMyne />
                </article>

                <IconMynePlus />

                <section>
                  <h5>{activeLanguage?.be_plus?.title}</h5>
                  <div>
                    <IconLanguage />
                    <p>{parse(activeLanguage?.be_plus?.title_global)}</p>
                  </div>
                  {!btn_plus && (
                    <>
                      <p
                        id="title-plus-limit"
                        className="myne-modal--animation"
                      >
                        {/* {parse(activeLanguage?.be_plus?.limit)} */}
                        {parse(message_locked)}
                      </p>
                    </>
                  )}
                </section>
              </div>
              <div className="myne-modal__modal-plus__content-vantagens">
                <section>
                  <article>
                    <IconCheck />
                    <p>{activeLanguage?.be_plus?.check1}</p>
                  </article>
                  <article>
                    <IconCheck />
                    <p>{activeLanguage?.be_plus?.check2}</p>
                  </article>
                  <article>
                    <IconCheck />
                    <p>{activeLanguage?.be_plus?.check3}</p>
                  </article>
                </section>

                <section>
                  <article>
                    <IconCheck />
                    <p>{activeLanguage?.be_plus?.check4}</p>
                  </article>
                  <article>
                    <IconCheck />
                    <p style={{ position: "relative", top: "-3px" }}>
                      {activeLanguage?.be_plus?.check5}
                    </p>
                  </article>
                </section>
              </div>
              {loading ? (
                <div className="myne-loading-sale">
                  <div className="myne-circle-loading-4"></div>
                </div>
              ) : (
                <div className="myne-modal__modal-plus__content-buttons">
                  {/* YEAR */}
                  <div className="myne-modal__modal-plus__content-buttons-plan myne-modal__modal-plus__content-buttons-plan-active">
                    <div>
                      <h5>
                        {activeLanguage?.be_plus?.btns?.plan?.title}{" "}
                        {activeLanguage?.be_plus?.btns?.year}
                      </h5>

                      <h3>
                        R$ 9,99
                        <p>/{activeLanguage?.be_plus?.btns?.plan?.month}*</p>
                      </h3>
                    </div>

                    <p>{activeLanguage?.be_plus?.btns?.plan?.cobra_year}</p>

                    <button
                      className="myne-btn"
                      onClick={() => {
                        setLoading(true);

                        goToPrice(prices?.priceYear, setLoading);
                      }}
                    >
                      {activeLanguage?.be_plus?.btns?.btn}
                    </button>
                  </div>
                  {/* MONTH */}
                  <div className="myne-modal__modal-plus__content-buttons-plan">
                    <div>
                      <h5>
                        {activeLanguage?.be_plus?.btns?.plan?.title}{" "}
                        {activeLanguage?.be_plus?.btns?.month}
                      </h5>

                      <h3>
                        R$ 24,99
                        <p>/{activeLanguage?.be_plus?.btns?.plan?.month}*</p>
                      </h3>
                    </div>

                    <p>{activeLanguage?.be_plus?.btns?.plan?.cobra_month}</p>

                    <button
                      className="myne-btn"
                      onClick={() => {
                        setLoading(true);

                        goToPrice(prices?.priceMonth, setLoading);
                      }}
                    >
                      {activeLanguage?.be_plus?.btns?.btn}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ModalPlus;

const IconLanguage = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7012 0.515625C16.2324 0.515625 20.6855 4.96875 20.6855 10.5C20.6855 16.0312 16.2324 20.4844 10.7012 20.4844C5.16992 20.4844 0.716797 16.0312 0.716797 10.5C0.716797 4.96875 5.16992 0.515625 10.7012 0.515625ZM17.6387 6.51562C16.6543 4.82812 15.1543 3.5625 13.2793 2.95312C13.8887 4.03125 14.3574 5.25 14.6855 6.51562H17.6387ZM10.7012 2.53125C9.85742 3.75 9.20117 5.0625 8.7793 6.51562H12.623C12.2012 5.0625 11.5449 3.75 10.7012 2.53125ZM2.9668 12.5156H6.3418C6.24805 11.8594 6.20117 11.2031 6.20117 10.5C6.20117 9.79688 6.24805 9.14062 6.3418 8.48438H2.9668C2.7793 9.14062 2.68555 9.79688 2.68555 10.5C2.68555 11.2031 2.7793 11.8594 2.9668 12.5156ZM3.76367 14.4844C4.74805 16.1719 6.24805 17.4375 8.12305 18.0469C7.51367 16.9688 7.04492 15.75 6.7168 14.4844H3.76367ZM6.7168 6.51562C7.04492 5.25 7.51367 4.03125 8.12305 2.95312C6.24805 3.5625 4.74805 4.82812 3.76367 6.51562H6.7168ZM10.7012 18.4688C11.5449 17.25 12.2012 15.9375 12.623 14.4844H8.7793C9.20117 15.9375 9.85742 17.25 10.7012 18.4688ZM13.0449 12.5156C13.1387 11.8594 13.1855 11.2031 13.1855 10.5C13.1855 9.79688 13.1387 9.14062 13.0449 8.48438H8.35742C8.26367 9.14062 8.2168 9.79688 8.2168 10.5C8.2168 11.2031 8.26367 11.8594 8.35742 12.5156H13.0449ZM13.2793 18.0469C15.1543 17.4375 16.6543 16.1719 17.6387 14.4844H14.6855C14.3574 15.75 13.8887 16.9688 13.2793 18.0469ZM15.0605 12.5156H18.4355C18.623 11.8594 18.7168 11.2031 18.7168 10.5C18.7168 9.79688 18.623 9.14062 18.4355 8.48438H15.0605C15.1543 9.14062 15.2012 9.79688 15.2012 10.5C15.2012 11.2031 15.1543 11.8594 15.0605 12.5156Z"
      fill="white"
    />
  </svg>
);

const IconCheck = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 0.511719C11.6484 0.511719 14.9883 3.85156 14.9883 8C14.9883 12.1484 11.6484 15.4883 7.5 15.4883C3.35156 15.4883 0.0117188 12.1484 0.0117188 8C0.0117188 3.85156 3.35156 0.511719 7.5 0.511719ZM5.46094 11.2344C5.77734 11.5156 6.23438 11.5156 6.51562 11.2344L12.2109 5.53906C12.5273 5.22266 12.5273 4.76562 12.2109 4.48438C11.9297 4.16797 11.4375 4.16797 11.1562 4.48438L5.98828 9.61719L3.84375 7.47266C3.52734 7.19141 3.07031 7.19141 2.78906 7.47266C2.47266 7.75391 2.47266 8.24609 2.78906 8.52734L5.46094 11.2344Z"
      fill={"black"}
      fillOpacity={"0.87"}
    />
  </svg>
);

const IconClose = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill={"white"}
      fillOpacity={"0.87"}
    />
  </svg>
);

const IconMynePlus = () => (
  <svg
    width={174}
    height={111}
    viewBox="0 0 174 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.06}
      d="M87.299 111c44.459 0 80.5-1.343 80.5-3s-36.041-3-80.5-3c-44.46 0-80.5 1.343-80.5 3s36.04 3 80.5 3z"
      fill="#000"
    />
    <path
      d="M171.007 69.883a29.473 29.473 0 00-24.384-17.88.687.687 0 00-.744.69V63.73a.693.693 0 00.596.68 17.166 17.166 0 0114.315 14.317.697.697 0 00.681.595h11.038a.693.693 0 00.655-.463.688.688 0 00.035-.28 29.212 29.212 0 00-2.192-8.696z"
      fill="#A0F"
    />
    <path
      d="M155.837 93.37a16.973 16.973 0 01-22.537 1.218 16.97 16.97 0 019.853-30.217.693.693 0 00.673-.685v-7.881a.669.669 0 00-.207-.495.688.688 0 00-.499-.195 26.222 26.222 0 00-24.921 31.516 26.223 26.223 0 0035.372 19.056 26.223 26.223 0 0016.516-23.622.687.687 0 00-.69-.706h-7.879a.706.706 0 00-.689.673 16.899 16.899 0 01-4.992 11.337z"
      fill="#fff"
    />
    <path
      d="M88.572 93.37a16.971 16.971 0 1129.017-12.009h9.251a26.217 26.217 0 00-16.198-24.264 26.223 26.223 0 00-28.606 5.73 26.264 26.264 0 0017.838 44.797.685.685 0 00.706-.69v-7.878a.707.707 0 00-.673-.69 16.913 16.913 0 01-11.335-4.997z"
      fill="#fff"
    />
    <path
      d="M74.329 81.566h-6.533a7.549 7.549 0 01-7.52-7.52V67.02a4.064 4.064 0 014.068-4.068h5.917a4.064 4.064 0 014.068 4.068v14.546z"
      fill="#F1BB8E"
    />
    <path
      d="M58.674 61.041v4.068a3.091 3.091 0 003.082 3.082h5.054a2.052 2.052 0 012.034 2.034v4.5h1.294v-2.528a1.1 1.1 0 011.109-1.109 1.169 1.169 0 011.17 1.171v4.376l2.28 2.28v-13a3.932 3.932 0 00-3.943-3.948h-9.615c-.493.031-.987.031-1.48 0-.554-.248-.985-1.172-.985-.926zM46.655 102.46v5.116h8.506V96.851H52.2a5.617 5.617 0 00-5.546 5.609z"
      fill="#3F4042"
    />
    <path
      d="M68.597 83.045c-3.02 0-4.561 2.465-4.561 5.485v3.515a4.842 4.842 0 01-4.87 4.87h-4.005v10.722h7.088A12.32 12.32 0 0074.576 95.31V83.045h-5.98z"
      fill="#A0F"
    />
    <path
      d="M71.124 85.326h3.2v-5.979h-4.68v4.561a1.488 1.488 0 001.48 1.418z"
      fill="#F1BB8E"
    />
    <path d="M50.168 103.138h-43.7v4.438h43.7v-4.438z" fill="#3F4042" />
    <path
      d="M6.47 103.137H5.237V97.22a3.7 3.7 0 00-3.02-3.636v13.929H6.47v-4.376z"
      fill="#787878"
    />
    <path
      d="M1.539 93.584h-.74v13.929H.86v.062h1.294V93.646a1.899 1.899 0 00-.616-.062z"
      fill="#3F4042"
    />
    <path
      d="M21.508 100.179l-.8.925-4.376-3.945a3.723 3.723 0 00-4.746-.185l10.355 9.307 2.835-3.143-3.268-2.959z"
      fill="#787878"
    />
    <path
      d="M11.092 97.467l-.493.555 10.355 9.307.062-.062.062.062.863-.986-10.356-9.369c-.18.149-.345.314-.493.493zM21.447 100.118l11.34-13.375a2.86 2.86 0 013.822-.43l16.025 12.326-2.712 3.51-12.943-10.29a2.075 2.075 0 00-2.958.308L24.775 103.2l-3.328-3.082zM45.237 87.914H43.45a.713.713 0 01-.74-.74V77.93a.713.713 0 01.74-.74h1.787a.711.711 0 01.74.74v9.245a.711.711 0 01-.74.74z"
      fill="#3F4042"
    />
    <path
      d="M71 90.503l-8.69 8.69a4.355 4.355 0 01-5.854.247L44.128 89.085"
      stroke="#F1BB8E"
      strokeWidth={6.37}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M44.25 79.532c.171 0 .309-.22.309-.493 0-.272-.138-.493-.308-.493-.17 0-.308.22-.308.493 0 .272.138.493.308.493z"
      fill="#FCFCFC"
    />
    <path
      d="M86.638 32.9c13.078 0 23.68-4.424 23.68-9.882 0-5.458-10.602-9.882-23.68-9.882s-23.68 4.424-23.68 9.882c0 5.458 10.602 9.882 23.68 9.882z"
      fill="#FC6928"
    />
    <path
      d="M114.778 9.22l-5.242 23.68-.964 4.46c0 2.29-9.822 4.157-21.933 4.157S64.706 39.65 64.706 37.36l-.964-4.459-5.243-23.68 18.2 11.147L86.638 4.64l9.942 15.727L114.778 9.22z"
      fill="#F9D12F"
    />
    <path
      d="M83.745 24.7l2.854-2.855 2.855 2.855-2.855 2.855-2.854-2.855z"
      fill="#2EC3FC"
    />
    <path
      d="M109.535 32.9l-.964 4.459c0 2.29-9.822 4.158-21.933 4.158s-21.933-1.868-21.933-4.158l-.964-4.46c4.218 1.568 12.895 2.652 22.9 2.652 10.005 0 18.676-1.084 22.894-2.651zM114.778 14.16a4.7 4.7 0 100-9.4 4.7 4.7 0 000 9.4z"
      fill="#FD852B"
    />
    <path
      d="M86.638 9.4a4.7 4.7 0 100-9.4 4.7 4.7 0 000 9.4zM58.499 14.16a4.7 4.7 0 100-9.4 4.7 4.7 0 000 9.4z"
      fill="#FC6928"
    />
    <path
      d="M86.638 21.813v2.892h2.892l-2.892-2.892zM83.746 24.705h2.892v2.832l-2.892-2.832z"
      fill="#1BA4FC"
    />
  </svg>
);

const BackMyne = () => (
  <svg
    width="341"
    height="192"
    viewBox="0 0 341 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M332.908 61.6059C325.775 44.7092 314.198 30.0601 299.408 19.2144C284.617 8.36879 267.166 1.73167 248.906 0.00801857C248.579 -0.0190145 248.249 0.0224792 247.938 0.129858C247.627 0.237237 247.342 0.408148 247.101 0.631711C246.86 0.855274 246.668 1.12659 246.538 1.42839C246.407 1.73019 246.341 2.05585 246.343 2.38465V40.4108C246.347 40.9827 246.557 41.5341 246.933 41.9644C247.31 42.3947 247.829 42.6753 248.395 42.755C260.782 44.6862 272.234 50.504 281.099 59.3686C289.963 68.2331 295.781 79.6855 297.712 92.0722C297.792 92.6385 298.073 93.1573 298.503 93.534C298.933 93.9107 299.485 94.1203 300.057 94.1244H338.083C338.412 94.1266 338.737 94.0604 339.039 93.9299C339.341 93.7994 339.612 93.6074 339.836 93.3663C340.059 93.1252 340.23 92.8402 340.338 92.5294C340.445 92.2186 340.486 91.8889 340.459 91.5612C339.5 81.2468 336.953 71.1425 332.908 61.6059Z"
      fill="url(#paint0_linear_7878_27092)"
      fillOpacity="0.24"
    />
    <path
      d="M280.649 142.517C273.907 149.207 265.637 154.153 256.554 156.929C247.471 159.704 237.849 160.224 228.52 158.445C219.19 156.666 210.435 152.64 203.011 146.717C195.587 140.793 189.718 133.15 185.912 124.448C182.106 115.747 180.478 106.249 181.167 96.7764C181.857 87.3039 184.844 78.1422 189.871 70.0837C194.897 62.0252 201.811 55.3127 210.015 50.5269C218.218 45.7411 227.464 43.0262 236.953 42.617C237.57 42.6002 238.156 42.3449 238.589 41.9047C239.022 41.4645 239.267 40.8737 239.273 40.2566V13.1078C239.274 12.7905 239.211 12.4762 239.089 12.1836C238.966 11.891 238.786 11.626 238.559 11.4043C238.332 11.1826 238.063 11.0088 237.768 10.8932C237.472 10.7775 237.157 10.7224 236.839 10.7311C219.201 11.2405 202.098 16.9048 187.643 27.0246C173.188 37.1444 162.013 51.2765 155.499 67.6757C148.985 84.0748 147.416 102.023 150.988 119.303C154.559 136.584 163.113 152.44 175.595 164.913C188.076 177.387 203.937 185.931 221.219 189.492C238.502 193.053 256.449 191.473 272.844 184.949C289.239 178.425 303.365 167.242 313.476 152.78C323.587 138.319 329.241 121.213 329.739 103.574C329.748 103.257 329.693 102.941 329.577 102.646C329.461 102.35 329.288 102.081 329.066 101.854C328.844 101.627 328.579 101.447 328.287 101.325C327.994 101.202 327.68 101.139 327.363 101.141H300.222C299.605 101.154 299.017 101.401 298.576 101.832C298.135 102.263 297.874 102.845 297.845 103.46C297.265 118.187 291.119 132.145 280.649 142.517Z"
      fill="url(#paint1_linear_7878_27092)"
      fillOpacity="0.24"
    />
    <path
      d="M48.921 142.517C42.16 135.705 37.18 127.333 34.4206 118.14C31.6613 108.947 31.2075 99.2166 33.0993 89.807C34.991 80.3974 39.1702 71.5982 45.2679 64.1862C51.3656 56.7742 59.1942 50.9773 68.0627 47.3073C76.9312 43.6373 86.5668 42.207 96.119 43.1427C105.671 44.0784 114.846 47.3513 122.834 52.6724C130.822 57.9936 137.377 65.1994 141.92 73.6538C146.464 82.1082 148.856 91.551 148.886 101.149H180.755C180.778 83.2558 175.486 65.7591 165.551 50.8776C155.616 35.996 141.486 24.4 124.951 17.5601C108.417 10.7202 90.2244 8.94445 72.6796 12.4581C55.1349 15.9718 39.0288 24.6165 26.4038 37.2961C13.9352 49.8029 5.39918 65.6851 1.84804 82.9846C-1.7031 100.284 -0.114074 118.245 6.41921 134.652C12.9525 151.06 24.1444 165.196 38.6152 175.32C53.0859 185.443 70.2028 191.111 87.8557 191.623C88.1728 191.632 88.4885 191.577 88.7839 191.461C89.0793 191.346 89.3485 191.172 89.5755 190.95C89.8025 190.728 89.9826 190.463 90.1052 190.171C90.2277 189.878 90.2903 189.564 90.2891 189.247V162.106C90.2754 161.49 90.0282 160.901 89.5976 160.46C89.167 160.019 88.585 159.758 87.9692 159.729C73.2448 159.136 59.292 152.986 48.921 142.517Z"
      fill="url(#paint2_linear_7878_27092)"
      fillOpacity="0.24"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7878_27092"
        x1="293.405"
        y1="0"
        x2="293.405"
        y2="94.1245"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7878_27092"
        x1="239.429"
        y1="10.7302"
        x2="239.429"
        y2="191.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_7878_27092"
        x1="90.3776"
        y1="10.6992"
        x2="90.3776"
        y2="191.624"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
