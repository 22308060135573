import { userId } from "../../../providers/reducer";
import { storage } from "../../../services/Firebase";

export const changeUploadImg = (e, upload, setImage) => {
  if (e.target.files?.length === 0) {
    return;
  }
  const image = e.target.files[0];
  upload(image);
  if (image) {
    setImage(URL.createObjectURL(image));
  }
};

//UPLOAD IMAGE

export const uploadImages = async (
  ref,
  uploadImage,
  setLoading,
  activeLanguage,
  setLoadingProgressUpload
) => {
  const uploadSingleImage = (image) =>
    uploadImageUnique(
      ref,
      image,
      setLoading,
      activeLanguage,
      setLoadingProgressUpload
    );

  if (Array.isArray(uploadImage)) {
    try {
      const uploadedImages = await Promise.all(
        uploadImage.map((image) => uploadSingleImage(image))
      );
      return uploadedImages;
    } catch (error) {
      throw new Error("Error uploading images: " + error.message);
    }
  } else {
    return await uploadSingleImage(uploadImage);
  }
};

export const uploadImageUnique = async (
  ref,
  uploadImage,
  setLoading,
  activeLanguage,
  setLoadingProgressUpload
) => {
  const imgUpload = uploadImage;

  if (typeof imgUpload != "string") {
    return new Promise(function (resolve, reject) {
      const filename = Date.now();
      const uploadTask = storage
        .ref(`${userId()}/images/${ref}/${filename}`)
        .put(imgUpload);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          if (setLoadingProgressUpload) {
            setLoadingProgressUpload(progress);
          }
          setLoading(true);
        },
        function error(err) {
          console.log("error", err);
          reject();
          setLoading(false);
          if (setLoadingProgressUpload) {
            setLoadingProgressUpload(0);
          }
        },
        () => {
          storage
            .ref(userId())
            .child(`/images/${ref}/${filename}`)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
              setLoading(false);
              if (setLoadingProgressUpload) {
                setLoadingProgressUpload(0);
              }
            })
            .catch((e) => {
              let errorMessage = e.message;
              alert(activeLanguage?.alerts?.error_upload_image + errorMessage);
            });
        }
      );
    });
  } else {
    return imgUpload;
  }
};
