import React, { useEffect, useRef, useState } from "react";

import {
  IconArrowBack,
  IconClose,
  IconDownload,
  IconExcel,
  IconInfoMin,
  IconNativigationNext,
} from "../../../assets/icons/icons";
import {
  UserService,
  getExtension,
  saveService,
  updatedAt,
} from "../../../providers/reducer";
import Excel from "exceljs";
import { useStateValue } from "../../../providers/StateProvider";
import { analyticsEvent } from "../../../services/analytics";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Tooltip from "../../Tooltip/tooltip";
import toast from "react-hot-toast";
import { formatPrice } from "../../../shared/utils/helpers/format";
import LoadingInButton from "../../Loading/LoadingInButton";

function ModalImportService({
  modal,
  setmodal,
  activeFilterCadastros,
  updateProducts,
}) {
  const [stage, setStage] = useState(1);
  const [{ activeMoeda, activeLanguage }, dispatch] = useStateValue();

  // STRINGS TYPES

  const strProductLong = activeLanguage?.services?.product?.title;
  // const strProduct = activeLanguage?.services?.product?.title_min;
  const strClientLong = activeLanguage?.services?.client?.title;
  // const strClient = activeLanguage?.services?.client?.title_min;

  //

  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("drop-file");
  const onDragLeave = () => wrapperRef.current.classList.remove("drop-file");
  const onDrop = () => wrapperRef.current.classList.remove("drop-file");

  const [fileUpload, setFileUpload] = useState(null);
  const [messageImportedFile, setMessageImportedFile] = useState(null);

  const [rowTitles, setRowTitles] = useState([]);
  const [rowProducts, setRowProducts] = useState([]);

  // CHANGE FILE
  const onChangedFile = (e) => {
    const files = e.target.files;

    if (files?.length === 0) {
      return;
    }

    if (getExtension(files[0]["name"]).toLowerCase() === "xlsx") {
      setFileUpload(files[0]);
      setMessageImportedFile("sucess");
    } else {
      setMessageImportedFile("erro");
    }
  };

  // READ TABLE
  const readTableFile = () => {
    if (fileUpload) {
      setMessageImportedFile(null);

      const file = fileUpload;
      const wb = new Excel.Workbook();
      const reader = new FileReader();

      const rowTitlesR = [];
      const rowProductsR = [];

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          workbook?.eachSheet((sheet, id) => {
            sheet?.eachRow((row, rowIndex) => {
              if (rowIndex === 1) {
                rowTitlesR.push(row?.values);
              } else {
                rowProductsR.push(row?.values);
              }
            });
            setRowTitles(rowTitlesR[0]);
            const newRowProducts = rowProductsR?.map((value, i) => {
              value[4] = value[4]?.richText
                ? value[4].richText?.map((item) => item?.text).join('') : value[4];
              value[3] =
                typeof value[3] === "string"
                  ? value[3]
                  : value[3]?.text;

              return value;
            });

            setRowProducts(newRowProducts);
          });
        });
      };
    }
  };

  useEffect(() => {
    if (stage === 2 && fileUpload) {
      readTableFile();
    }
  }, [stage, fileUpload]);

  // RESET
  const resetBack = () => {
    setFileUpload(null);
    setMessageImportedFile(null);
    setStage(1);
    setRowProducts([]);
    setRowTitles([]);
  };

  const [errorsRows, setErrorsRows] = useState([]);

  // CHECK ERRORS ROWS
  const checkErrosRow = (row) => {
    if (activeFilterCadastros === strClientLong) {
      return row[1] ? true : false;
    } else if (activeFilterCadastros === strProductLong) {
      return row[1] && row[2] && row[3] && row[4] && row[6];
    }
  };

  // SET ERROS ROWS
  useEffect(() => {
    if (rowProducts.length > 0) {
      const errors = [];
      rowProducts?.map((products, i) => {
        if (!checkErrosRow(products)) {
          errors.push({ error: products });
        }
      });
      setErrorsRows(errors);
    }
  }, [rowProducts]);

  const formatN = (n) => {
    return n
      ? parseFloat(
        Number(
          n
            ?.toString()
            ?.replace(",", ".")
            ?.replace(/[^0-9.-]+/g, "")
        )?.toFixed(2)
      )
      : 0;
  };

  const [loading, setLoading] = useState(false);

  // SAVE IMPORT
  const saveImportServices = async (e) => {
    e.preventDefault();

    const hasStore = await UserService?.hasStore();

    setLoading(true);
    await Promise.all(
      rowProducts.map(async (service) => {
        let pd = null;

        if (activeFilterCadastros === strClientLong) {
          pd = {
            name: service[1]?.text ?? service[1] ?? "",
            email: service[3]?.text ?? service[3] ?? "",
            phone: service[2]?.text ?? service[2] ?? "",
            image: null,
            description: service[4]?.text ?? service[4] ?? "" ?? "",
            updatedAt: updatedAt,
          };
        } else if (activeFilterCadastros === strProductLong) {
          pd = {
            name: service[1]?.text ?? service[1] ?? "",
            salePrice: formatN(service[4]),
            stock: parseInt(service[2]) ?? 0,
            costPrice: formatN(service[3]),
            image: null,
            description: service[5]?.text ?? service[5] ?? "",
            minimumStock: parseInt(service[6]) ?? 0,
            discountRef: null,
            updatedAt: updatedAt,
          };
        }

        if (pd !== null) {
          const filter = activeFilterCadastros;
          const gerateId = Math.random().toString(16).slice(2);

          await saveService(
            filter,
            pd,
            gerateId,
            (then) => {
              setmodal(!modal);

              resetBack();
              setLoading(false);

              // dispatch({
              //   type: "SET_MESSAGES",
              //   messages: {
              //     messageSucesseEdit: false,
              //     messageSucesseAdd: true,
              //     messageSucesseRemove: false,
              //     messageErro: false,
              //     plural: true,
              //     type: filter,
              //   },
              // });
            },
            (erro) => {
              setLoading(false);
              toast.error(activeLanguage?.alerts?.erro);
              // dispatch({
              //   type: "SET_MESSAGES",
              //   messages: {
              //     messageSucesseEdit: false,
              //     messageSucesseAdd: false,
              //     messageSucesseRemove: false,
              //     plural: false,
              //     messageErro: true,
              //     type: filter,
              //   },
              // });
            },
            null,
            null,
            true,
            activeLanguage,
            hasStore
          );
        }
      })
    ).then(() => {
      toast.success(
        `${activeLanguage?.alerts?.save_service?.alert
        }${activeFilterCadastros}${" "}${activeLanguage?.alerts?.save_service?.plural
        }${" "}${activeLanguage?.alerts?.save_service?.p2}`
      );

      if (activeFilterCadastros === strProductLong) {
        analyticsEvent("ADD_PRODUCT_BATCH_DONE");
      } else if (activeFilterCadastros === strClientLong) {
        analyticsEvent("ADD_CLIENT_BATCH_DONE");
      }
      if (activeFilterCadastros === strProductLong) {
        if (updateProducts) {
          updateProducts();
        }
      }
    });
  };

  return (
    <div
      style={{
        zIndex: 9999999,
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
      }}
      className="myne-modal"
    >
      <div
        style={{
          transform: modal ? "scale(1)" : "scale(0.7)",
        }}
        className="myne-modal__modal-edit-cadastro myne-modal__modal-edit-cadastro-import-products"
      >
        {modal ? (
          <>
            <header className="myne-modal__modal-edit-header">
              <section className="myne-modal__modal-edit-header__left">
                {stage === 2 ? (
                  <IconArrowBack
                    style={{ cursor: "pointer" }}
                    onClick={resetBack}
                  />
                ) : (
                  ""
                )}
                <p style={{ textTransform: "initial" }}>
                  {activeLanguage?.modals?.modal_add_lote?.title?.p1}{" "}
                  {activeFilterCadastros?.toLowerCase()}{" "}
                  {activeLanguage?.modals?.modal_add_lote?.title?.p2}
                </p>
              </section>

              <section className="myne-modal__modal-edit-header__icons">
                <IconClose onClick={() => setmodal(!modal)} />
              </section>
            </header>

            <div className="myne-modal__modal-edit-cadastro-import-products-content myne-modal--animation">
              <header className="myne-modal-import-products-content--header">
                <section className="myne-modal-import-products-content--header_stages">
                  <div>
                    {stage === 1 ? (
                      <article
                        style={{
                          background: stage === 1 ? "#FAEFFF" : "#AA00FF",
                        }}
                      >
                        1
                      </article>
                    ) : (
                      <IconCheck />
                    )}

                    <p>
                      {
                        activeLanguage?.modals?.modal_add_lote?.import_file
                          ?.title
                      }
                    </p>
                  </div>
                  <IconNativigationNext
                    style={{
                      fill: stage === 1 ? "#000" : "#AA00FF",
                      fillOpacity: stage === 1 ? "0.36" : "1",
                    }}
                  />
                  <div>
                    <article
                      style={{ background: stage === 2 ? "#FAEFFF" : "#fff" }}
                    >
                      2
                    </article>
                    <p>
                      {
                        activeLanguage?.modals?.modal_add_lote?.review_dados
                          ?.title
                      }
                    </p>
                  </div>
                </section>
                {stage === 1 ? (
                  <div className="myne-modal-import-products-content--header_info myne-modal-import-products-content--header_info--download">
                    <IconExcel />
                    <p>
                      {parse(
                        activeLanguage?.modals?.modal_add_lote?.import_file
                          ?.download_file?.p1
                      )}{" "}
                      {activeFilterCadastros?.toLowerCase()}{" "}
                      {
                        activeLanguage?.modals?.modal_add_lote?.import_file
                          ?.download_file?.p2
                      }
                    </p>
                    {activeFilterCadastros === strProductLong ? (
                      <Link
                        to="/myne-planilha-importar-produtos.xlsx"
                        target="_blank"
                        download
                      >
                        <IconDownload />
                        <p>
                          {
                            activeLanguage?.modals?.modal_add_lote?.import_file
                              ?.download_btn
                          }
                        </p>
                      </Link>
                    ) : activeFilterCadastros === strClientLong ? (
                      <Link
                        to="/myne-planilha-importar-clientes.xlsx"
                        target="_blank"
                        download
                      >
                        <IconDownload />
                        <p>
                          {
                            activeLanguage?.modals?.modal_add_lote?.import_file
                              ?.download_btn
                          }
                        </p>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </header>

              {/* CONTENT-IMPORT */}
              <div className="myne-modal-import-products-content__area-import">
                {stage === 1 ? (
                  // IMPORT FILE
                  <section className="myne-modal-import-products-content__area-import-file">
                    {messageImportedFile === "sucess" ? (
                      // SUCESS MESSAGE IMPORT

                      <section className="myne-modal-import-products-content__area-import-file___sucess myne-modal--animation">
                        <div>
                          <IconSucess />
                          <p>
                            {
                              activeLanguage?.modals?.modal_add_lote
                                ?.import_file?.file_sucess?.title
                            }
                          </p>
                          <section>
                            <p>{fileUpload["name"]}</p>

                            <Tooltip
                              icon={
                                <IconCloseBlack
                                  onClick={() => {
                                    setMessageImportedFile(null);
                                    setFileUpload(null);
                                  }}
                                />
                              }
                              hover
                              direction={"bottom"}
                              text={"Cancelar arquivo"}
                            />
                          </section>
                        </div>
                      </section>
                    ) : messageImportedFile === "erro" ? (
                      // ERRO MESSAGE IMPORT

                      <section
                        ref={wrapperRef}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                        className="myne-modal-import-products-content__area-import-file___erro "
                      >
                        <div>
                          <IconErro />
                          <p>
                            {parse(
                              activeLanguage?.modals?.modal_add_lote
                                ?.import_file?.file_error?.title
                            )}
                          </p>

                          <section>
                            <p>
                              {parse(
                                activeLanguage?.modals?.modal_add_lote
                                  ?.import_file?.file_error?.drag_file
                              )}
                            </p>
                            <IconUploadBlack />
                          </section>
                        </div>
                        <input onChange={onChangedFile} type="file" value="" />
                      </section>
                    ) : (
                      // IMPORT AREA

                      <section
                        ref={wrapperRef}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}
                      >
                        <div>
                          <IconUpload />
                          <p>
                            {parse(
                              activeLanguage?.modals?.modal_add_lote
                                ?.import_file?.drag_file
                            )}
                          </p>
                        </div>
                        <input onChange={onChangedFile} type="file" value="" />
                      </section>
                    )}

                    {/* INFO FORMAT */}
                    <div className="myne-modal-import-products-content__area-import-file__form-info">
                      <IconInfoMin />
                      <p>
                        {parse(
                          activeLanguage?.modals?.modal_add_lote?.import_file
                            ?.format
                        )}
                      </p>
                    </div>
                  </section>
                ) : stage === 2 ? (
                  // READ FILE
                  <section className="myne-modal-import-products-content__area-import-file__area-read myne-modal--animation-top">
                    {/* ALERT AND ERROR */}
                    {errorsRows?.length > 0 ? (
                      <section className="myne-modal-import-products-content__area-import-file__form-info-erro myne-modal--animation-scale">
                        <IconErroSmall />
                        <p>
                          {errorsRows.length === 1 ? (
                            <b>
                              {errorsRows.length}{" "}
                              {
                                activeLanguage?.modals?.modal_add_lote
                                  ?.review_dados?.error?.p1
                              }
                            </b>
                          ) : (
                            <b>
                              {errorsRows.length}{" "}
                              {
                                activeLanguage?.modals?.modal_add_lote
                                  ?.review_dados?.error?.p1
                              }
                            </b>
                          )}{" "}
                          {
                            activeLanguage?.modals?.modal_add_lote?.review_dados
                              ?.error?.p2
                          }
                        </p>
                      </section>
                    ) : (
                      <section className="myne-modal-import-products-content__area-import-file__form-info-alert myne-modal--animation-scale">
                        <IconAlert />
                        <p>
                          {parse(
                            activeLanguage?.modals?.modal_add_lote?.review_dados
                              ?.alert
                          )}
                        </p>
                      </section>
                    )}

                    {/* TABLE */}
                    <div className="myne-modal-import-products-content__area-import-products-table">
                      {activeFilterCadastros === strProductLong ? (
                        <>
                          <div className="myne-modal-import-products-content__area-import-products-table__titles">
                            {/* TITLES */}
                            <section className="myne-modal-import-products-read__area-big">
                              {rowTitles[1]}
                            </section>
                            <section className="myne-modal-import-products-read__area-small">
                              {rowTitles[2]}
                            </section>
                            <section className="myne-modal-import-products-read__area-small">
                              {rowTitles[3]}
                            </section>
                            <section className="myne-modal-import-products-read__area-small">
                              {rowTitles[4]}
                            </section>
                            <section
                              style={{ margin: 0 }}
                              className="myne-modal-import-products-read__area-small"
                            >
                              {rowTitles[6]}
                            </section>
                          </div>
                          {/* PRODUCTS */}
                          <div className="myne-modal-import-products-content__area-import-products-table__values">
                            {rowProducts?.map((products, i) => (
                              <div
                                key={`${products[1]?.text ?? products[1] ?? "-"
                                  }${i}`}
                              >
                                <section className="myne-modal-import-products-read__area-big">
                                  {!checkErrosRow(products) ? (
                                    <IconErroSmall />
                                  ) : (
                                    <IconSucessSmall />
                                  )}

                                  {products[1]?.text ?? products[1] ?? "-"}
                                </section>
                                <section className="myne-modal-import-products-read__area-small">
                                  {products[2] ?? "-"}
                                </section>
                                <section className="myne-modal-import-products-read__area-small">
                                  {products[3]
                                    ? formatPrice(products[3], activeMoeda)
                                    : "-"}
                                </section>
                                <section className="myne-modal-import-products-read__area-small">
                                  {products[4]
                                    ? formatPrice(products[4], activeMoeda)
                                    : "-"}
                                </section>
                                <section
                                  style={{ margin: 0 }}
                                  className="myne-modal-import-products-read__area-small"
                                >
                                  {products[6] ?? "-"}
                                </section>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : activeFilterCadastros === strClientLong ? (
                        <>
                          <div
                            style={{ height: "auto" }}
                            className="myne-modal-import-products-content__area-import-products-table__titles"
                          >
                            {/* TITLES */}
                            <section className="myne-modal-import-products-read__area-big">
                              {rowTitles[1]}
                            </section>
                            <section className="myne-modal-import-products-read__area-small-2">
                              {rowTitles[2]}
                            </section>
                            <section className="myne-modal-import-products-read__area-small-2">
                              {rowTitles[3]}
                            </section>
                            <section
                              style={{ margin: 0 }}
                              className="myne-modal-import-products-read__area-small-2"
                            >
                              {rowTitles[4]}
                            </section>
                          </div>
                          {/* PRODUCTS */}
                          <div className="myne-modal-import-products-content__area-import-products-table__values">
                            {rowProducts?.map((products) => {
                              const gerateId = Math.random()
                                .toString(16)
                                .slice(2);
                              return (
                                <div
                                  style={{ height: "initial", minHeight: 48 }}
                                >
                                  <section className="myne-modal-import-products-read__area-big">
                                    {!checkErrosRow(products) ? (
                                      <IconErroSmall />
                                    ) : (
                                      <IconSucessSmall />
                                    )}

                                    {products[1]?.text ?? products[1] ?? "-"}
                                  </section>
                                  <section className="myne-modal-import-products-read__area-small-2">
                                    {products[2] ?? "-"}
                                  </section>
                                  <section
                                    id={`myne-email-${gerateId}`}
                                    className={
                                      "myne-modal-import-products-read__area-small-2"
                                    }
                                  >
                                    {products[3] ?? "-"}
                                    {products[3] && products[3]?.length > 18 ? (
                                      <p
                                        id={`myne-email-${gerateId}-btn`}
                                        onClick={() => {
                                          document.getElementById(
                                            `myne-email-${gerateId}-btn`
                                          ).style.display = "none";
                                          const current =
                                            document.getElementById(
                                              `myne-email-${gerateId}`
                                            );
                                          current.classList.remove(
                                            "myne-modal-import-products-read__area-small-2"
                                          );
                                          current.classList.add(
                                            "myne-modal-import-products-read__area-small-3"
                                          );
                                        }}
                                      >
                                        {"•••"}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </section>
                                  <section
                                    style={{
                                      margin: 0,
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                    }}
                                    className={
                                      "myne-modal-import-products-read__area-small-2"
                                    }
                                    id={`myne-description-${gerateId}`}
                                  >
                                    {products[4] ?? "-"}
                                    {products[4] && products[4]?.length > 18 ? (
                                      <p
                                        id={`myne-description-${gerateId}-btn`}
                                        onClick={() => {
                                          document.getElementById(
                                            `myne-description-${gerateId}-btn`
                                          ).style.display = "none";
                                          const current =
                                            document.getElementById(
                                              `myne-description-${gerateId}`
                                            );
                                          current.classList.remove(
                                            "myne-modal-import-products-read__area-small-2"
                                          );
                                          current.classList.add(
                                            "myne-modal-import-products-read__area-small-4"
                                          );
                                        }}
                                      >
                                        {"•••"}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </section>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="myne-modal-import-products-area-background-overflow"></div>
                  </section>
                ) : (
                  ""
                )}
              </div>

              {/* BUTTON NEXT AND SAVE */}
              <div className="myne-modal__modal-new-sale_bottom--right">
                {stage === 2 ? (
                  loading ? (
                    <button className="active">
                      <LoadingInButton />
                    </button>
                  ) : (
                    <button
                      onClick={(e) => saveImportServices(e)}
                      className="active"
                    >
                      {activeLanguage?.modals?.modal_add_lote?.save_btn}
                    </button>
                  )
                ) : fileUpload && messageImportedFile === "sucess" ? (
                  <button
                    onClick={() => {
                      setStage(2);
                      readTableFile();
                    }}
                    className="active"
                  >
                    {activeLanguage?.modals?.modal_add_lote?.next_btn}
                  </button>
                ) : (
                  <button>
                    {activeLanguage?.modals?.modal_add_lote?.next_btn}
                  </button>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ModalImportService;

const IconAlert = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM10 15.0156C9.4375 15.0156 9.01562 14.5469 9.01562 13.9844V10C9.01562 9.4375 9.4375 9.01562 10 9.01562C10.5625 9.01562 10.9844 9.4375 10.9844 10V13.9844C10.9844 14.5469 10.5625 15.0156 10 15.0156ZM10.9844 7H9.01562V4.98438H10.9844V7Z"
      fill="#FFBD06"
    />
  </svg>
);

const IconUploadBlack = () => (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0938 6H10.5V0.984375C10.5 0.46875 10.0312 0 9.51562 0H5.48438C4.96875 0 4.5 0.46875 4.5 0.984375V6H2.90625C2.01562 6 1.54688 7.07812 2.20312 7.6875L6.79688 12.2812C7.17188 12.7031 7.82812 12.7031 8.20312 12.2812L12.7969 7.6875C13.4062 7.07812 12.9844 6 12.0938 6ZM0.515625 15.9844C0.515625 16.5469 0.9375 17.0156 1.5 17.0156H13.5C14.0625 17.0156 14.4844 16.5469 14.4844 15.9844C14.4844 15.4688 14.0625 15 13.5 15H1.5C0.9375 15 0.515625 15.4688 0.515625 15.9844Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

const IconErro = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0.6875C6.625 0.6875 0.6875 6.625 0.6875 14C0.6875 21.375 6.625 27.3125 14 27.3125C21.375 27.3125 27.3125 21.375 27.3125 14C27.3125 6.625 21.375 0.6875 14 0.6875ZM14 15.3125C13.25 15.3125 12.6875 14.75 12.6875 14V8.6875C12.6875 7.9375 13.25 7.3125 14 7.3125C14.75 7.3125 15.3125 7.9375 15.3125 8.6875V14C15.3125 14.75 14.75 15.3125 14 15.3125ZM15.3125 20.6875H12.6875V18H15.3125V20.6875Z"
      fill="#FE2543"
    />
  </svg>
);

const IconCloseBlack = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);

const IconCheck = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 0.6875C6.375 0.6875 0.4375 6.625 0.4375 14C0.4375 21.375 6.375 27.3125 13.75 27.3125C21.125 27.3125 27.0625 21.375 27.0625 14C27.0625 6.625 21.125 0.6875 13.75 0.6875ZM10.125 19.75L5.375 14.9375C4.8125 14.4375 4.8125 13.5625 5.375 13.0625C5.875 12.5625 6.6875 12.5625 7.25 13.0625L11.0625 16.875L20.25 7.75C20.75 7.1875 21.625 7.1875 22.125 7.75C22.6875 8.25 22.6875 9.0625 22.125 9.625L12 19.75C11.5 20.25 10.6875 20.25 10.125 19.75Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconUpload = () => (
  <svg
    width="19"
    height="23"
    viewBox="0 0 19 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8125 17.3125H12.1875C12.875 17.3125 13.5 16.75 13.5 16V9.3125H15.625C16.8125 9.3125 17.4375 7.875 16.5625 7.0625L10.4375 0.9375C9.9375 0.4375 9.0625 0.4375 8.5625 0.9375L2.4375 7.0625C1.625 7.875 2.1875 9.3125 3.375 9.3125H5.5V16C5.5 16.75 6.125 17.3125 6.8125 17.3125ZM1.5 20H17.5C18.25 20 18.8125 20.625 18.8125 21.3125C18.8125 22.0625 18.25 22.6875 17.5 22.6875H1.5C0.75 22.6875 0.1875 22.0625 0.1875 21.3125C0.1875 20.625 0.75 20 1.5 20Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconSucess = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0.6875C6.625 0.6875 0.6875 6.625 0.6875 14C0.6875 21.375 6.625 27.3125 14 27.3125C21.375 27.3125 27.3125 21.375 27.3125 14C27.3125 6.625 21.375 0.6875 14 0.6875ZM10.375 19.75L5.625 14.9375C5.0625 14.4375 5.0625 13.5625 5.625 13.0625C6.125 12.5625 6.9375 12.5625 7.5 13.0625L11.3125 16.875L20.5 7.75C21 7.1875 21.875 7.1875 22.375 7.75C22.9375 8.25 22.9375 9.0625 22.375 9.625L12.25 19.75C11.75 20.25 10.9375 20.25 10.375 19.75Z"
      fill="#58CC02"
    />
  </svg>
);

const IconSucessSmall = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    style={{ minWidth: 19, minHeight: 19 }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM7.28125 14.3125L3.71875 10.7031C3.29688 10.3281 3.29688 9.67188 3.71875 9.29688C4.09375 8.92188 4.70312 8.92188 5.125 9.29688L7.98438 12.1562L14.875 5.3125C15.25 4.89062 15.9062 4.89062 16.2812 5.3125C16.7031 5.6875 16.7031 6.29688 16.2812 6.71875L8.6875 14.3125C8.3125 14.6875 7.70312 14.6875 7.28125 14.3125Z"
      fill="#58CC02"
    />
  </svg>
);

const IconErroSmall = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    style={{ minWidth: 19, minHeight: 19 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM10 10.9844C9.4375 10.9844 9.01562 10.5625 9.01562 10V6.01562C9.01562 5.45312 9.4375 4.98438 10 4.98438C10.5625 4.98438 10.9844 5.45312 10.9844 6.01562V10C10.9844 10.5625 10.5625 10.9844 10 10.9844ZM10.9844 15.0156H9.01562V13H10.9844V15.0156Z"
      fill="#FE2543"
    />
  </svg>
);
