import Header from "../../layouts/Header/Header";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FilterCadastros from "../../components/Filters/FilterCadastros";
import ListCadastros from "../../components/Cadastro-itens/ListCadastros";
import { useStateValue } from "../../providers/StateProvider";
import { firebase, db, auth } from "../../services/Firebase";

import "../../styles/Cadastros/Cadastros.css";

import {
  ProductSaleService_LIMIT_FREE,
  ProductService,
  UserService,
  checkIfNeedMigrateProduct,
  checkIfNeedProductsAlgoliaMigrateToSearch,
  getProducts,
  phoneMask,
  strAllCategoriesProducts,
  useOnClickOutside,
} from "../../providers/reducer";

import VendasClientes from "../../components/Cadastro-itens/VendasClientes";
import { analyticsEvent } from "../../services/analytics";
import ModalEditService from "../../components/Modals/AddServices/ModalEditService";
import {
  IconAddProductsImport,
  IconAddProductsManual,
} from "../../assets/icons/icons";
import ModalImportService from "../../components/Modals/AddServices/ModalImportService";
import BtnExport from "../../components/Buttons/BtnExport";
import ModalPlus from "../../components/Modals/Plus/ModalPlus";
import ResumeStock from "../../components/Cadastro-itens/ResumeStock";
import ModalMinimumStock from "../../components/Modals/Product/ModalMinimumStock";
import { getProductFilters } from "../../shared/utils/helpers/filters";
import { Product } from "../../models/User/Products/Product";

function Cadastros() {
  const [{ messages, activeLanguage, ifPlusVerified }, dispatch] =
    useStateValue();

  // STRINGS TYPES

  const strProductLong = activeLanguage?.services?.product?.title;
  const strClientLong = activeLanguage?.services?.client?.title;
  const strServiceLong = activeLanguage?.services?.service?.title;
  const strDiscountLong = activeLanguage?.services?.discount?.title;

  //

  const [buttonsCadastros, setButtonsCadastros] = useState([
    strProductLong,
    strClientLong,
    strServiceLong,
    strDiscountLong,
  ]);
  const [activeFilterCadastros, setActiveFilterCadastros] = useState(
    buttonsCadastros[0]
  );
  const [filterCadastros, setFilterCadastros] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalPlus, setModalPlus] = useState(false);
  const [modalPlusWithoutLocked, setModalPlusWithoutLocked] = useState(true);

  // ↓ GET and SET (setFilterCadastros) data

  // useEffect(() => {
  //   if (activeFilterCadastros === strProductLong) {
  //     async function AlgoliaProducts() {
  //       await checkIfNeedMigrateProduct();
  //       // await checkIfNeedProductsAlgoliaMigrateToSearch();
  //     }
  //     AlgoliaProducts();
  //   }
  // }, [activeFilterCadastros, strProductLong]);

  useEffect(() => {
    if (activeFilterCadastros === strProductLong) {
      async function AlgoliaProducts() {
        await checkIfNeedMigrateProduct();
      }
      AlgoliaProducts();
    }
  }, [activeFilterCadastros, strProductLong]);

  // const [nextQueryPagination, setNextQueryPagination] = useState(null);
  const [loadingPaginate, setLoadingPaginate] = useState(false);

  // PAGINATION
  const [lastKey, setLastKey] = useState("");
  // products acess value selectCategoriesForFilter and set
  const [selectCategoriesForFilter, setSelectCategoriesForFilter] = useState([
    strAllCategoriesProducts,
  ]);
  // filters
  const filters = getProductFilters(activeLanguage);
  const [filterSelect, setFilterSelect] = useState(filters[0].index);

  // const listProductByCategorySingleOrPagination = async () => {
  //   const productsReference = db
  //     .collection("users")
  //     .doc(auth?.currentUser?.uid)
  //     .collection("products");
  //   // .limit(30)
  //   // .orderBy("updatedAt", "desc")

  //   const selectAllProducts =
  //     selectCategoriesForFilter[0] === strAllCategoriesProducts;

  //   if (selectAllProducts) {
  //     const { list, nextQuery } = await ProductService.loadPaginate(
  //       productsReference
  //     ).finally(() => {
  //       setLoading(false);
  //     });
  //     if (list?.length > 0) {
  //       setLoading(false);
  //     }

  //     setNextQueryPagination(nextQuery);
  //     setFilterCadastros(list);

  //     // collectionReference?.onSnapshot((snapshot) => {
  //     //   setFilterCadastros(
  //     //     snapshot?.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
  //     //   );
  //     // });
  //   } else {
  //     const data = await ProductService?.listProductByCategorySingle(
  //       selectCategoriesForFilter,
  //       productsReference?.orderBy("name")
  //     ).finally(() => {
  //       setLoading(false);
  //     });
  //     if (data?.length > 0) {
  //       setLoading(false);
  //     }

  //     setFilterCadastros(data);
  //   }
  // };

  const getProductsPagination = useCallback(async () => {
    setLoading(true);
    await ProductService.productsFirstBatch(
      selectCategoriesForFilter,
      filterSelect,
      filters
    )
      .then((res) => {
        setFilterCadastros(res.products);
        setLastKey(res.lastKey);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [filterSelect, selectCategoriesForFilter]);

  // const getProducts = () => {
  //   setLoading(true);

  //   listProductByCategorySingleOrPagination().finally(() => {
  //     setLoading(false);
  //   });
  // };

  useEffect(() => {
    if (activeFilterCadastros === strProductLong) {
      setLoading(true);
      setFilterCadastros([]);
      getProductsPagination();
    }
  }, [activeFilterCadastros, getProductsPagination, strProductLong]);

  useEffect(() => {
    const docReference = db.collection("users").doc(auth?.currentUser?.uid);

    const getClients = () => {
      setLoading(true);
      const snapshot = async () => {
        await docReference.collection("clients").onSnapshot((snapshot) => {
          setFilterCadastros(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
          );
          if (snapshot.docs.length > 0) {
            setLoading(false);
          }
        });
      };
      snapshot().finally(() => {
        setLoading(false);
      });
    };
    const getServices = () => {
      setLoading(true);
      const snapshot = async () => {
        await docReference.collection("services").onSnapshot((snapshot) => {
          setFilterCadastros(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
          );
          if (snapshot.docs.length > 0) {
            setLoading(false);
          }
        });
      };
      snapshot().finally(() => {
        setLoading(false);
      });
    };
    const getDiscounts = () => {
      setLoading(true);
      const snapshot = async () => {
        await docReference.collection("discounts").onSnapshot((snapshot) => {
          setFilterCadastros(
            snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }))
          );
          if (snapshot.docs.length > 0) {
            setLoading(false);
          }
        });
      };
      snapshot().finally(() => {
        setLoading(false);
      });
    };
    switch (activeFilterCadastros) {
      case strClientLong:
        setFilterCadastros([]);
        getClients();
        break;
      case strServiceLong:
        setFilterCadastros([]);
        getServices();
        break;
      case strDiscountLong:
        setFilterCadastros([]);
        getDiscounts();
        break;

      default:
        break;
    }
  }, [
    activeFilterCadastros,
    strClientLong,
    strDiscountLong,
    strProductLong,
    strServiceLong,
  ]);

  // PRODUCTS TO EXPORT PRODUCTS AND RESUME STOCK
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    if (activeFilterCadastros === strProductLong) {
      if (filterCadastros.length >= ProductService.pageProductsLimit) {
        getProducts(auth?.currentUser, setAllProducts, setLoading);
      } else {
        setAllProducts(filterCadastros);
      }
    }
  }, [activeFilterCadastros, filterCadastros, strProductLong]);

  // MODAL ADD

  // product

  const [name, setName] = useState("");
  const [stock, setStock] = useState();
  const [costPrice, setCostPrice] = useState();
  const [salePrice, setSalePrice] = useState();
  const [description, setDescription] = useState("");
  const [minimumStock, setMinimumStock] = useState(0);
  const [minimumStockSelect, setMinimumStockSelect] = useState(null);

  const [modalAddMinimumStock, setModalAddMinimumStock] = useState(false);
  const [ADDminimumStock, setADDminimumStock] = useState("");

  const [arrayMinStock, setArrayMinStock] = useState([]);
  const [onCatalog, setOnCatalog] = useState(true);

  useEffect(() => {
    const setCatalogProduct = async () => {
      const hasStore = await UserService?.hasStore();
      setOnCatalog(hasStore);
    };
    if (modalAdd) {
      setCatalogProduct();
    }
  }, [modalAdd]);

  useEffect(() => {
    let uniq = [minimumStock, 5, 10, 50, 100];
    setArrayMinStock(uniq);
  }, [minimumStock]);

  const addminimumStock = () => {
    let value = parseFloat(ADDminimumStock);

    let uniq = [value, 5, 10, 50, 100];

    setArrayMinStock(uniq);
    setMinimumStockSelect(value);
    setMinimumStock(value);
    setADDminimumStock("");
    setModalAddMinimumStock(false);
  };

  // client

  const [nomeCliente, setNomeCliente] = useState("");
  const [foneCliente, setFoneCliente] = useState();
  const [emailCliente, setEmailCliente] = useState("");
  const [commentsCliente, setCommentsCliente] = useState("");

  // discounts
  const [quantity, setQuantity] = useState();
  const [type, setType] = useState(1);

  // categories
  const [selectCategories, setSelectCategories] = useState([]);

  /*{
    name:name,
    salePrice:parseFloat(salePrice),
    stock:parseFloat(stock),
    costPrice:parseFloat(costPrice),
    categories:selectCategories,
    image:null,
    description:description,
    minimumStock:(
      minimumStockSelect === "" ||
      minimumStockSelect === undefined ||
      isNaN(minimumStockSelect) ||
      minimumStockSelect === null
        ? 0
        : minimumStockSelect),
    discountRef:null,
    updatedAt:firebase.firestore.FieldValue.serverTimestamp(),
    }*/
  const item =
    activeFilterCadastros === strProductLong
      ? new Product(
          name,
          parseFloat(salePrice),
          parseFloat(stock),
          parseFloat(costPrice),
          selectCategories,
          null,
          null,
          description,
          minimumStockSelect === "" ||
          minimumStockSelect === undefined ||
          isNaN(minimumStockSelect) ||
          minimumStockSelect === null
            ? 0
            : minimumStockSelect,
          null,
          firebase.firestore.FieldValue.serverTimestamp()
        )
      : activeFilterCadastros === strClientLong
      ? {
          name: nomeCliente,
          email: emailCliente,
          phone: foneCliente,
          image: null,
          description: commentsCliente,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
      : activeFilterCadastros === strServiceLong
      ? {
          name: name,
          salePrice: parseFloat(salePrice),
          costPrice: parseFloat(costPrice),
          image: null,
          description: description,
          discountRef: null,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
      : {
          name: name,
          quantity:
            type === 0
              ? parseFloat(quantity?.toString()?.replaceAll(",", ".")) / 100
              : parseFloat(quantity?.toString()?.replaceAll(",", ".")),
          type: type,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

  // useEffect(() => {
  //   if (
  //     messages.messageSucesseEdit ||
  //     messages.messageSucesseAdd ||
  //     messages.messageSucesseRemove ||
  //     messages.messageErro ||
  //     messages?.plural
  //   ) {
  //     const timer = setTimeout(() => {
  //       dispatch({
  //         type: "SET_MESSAGES",
  //         messages: {
  //           messageSucesseEdit: false,
  //           messageSucesseAdd: false,
  //           messageSucesseRemove: false,
  //           messageErro: false,
  //           plural: false,
  //           aPt: false,
  //         },
  //       });
  //     }, [2500]);
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }
  // }, [messages]);

  const [selectCliente, setSelectCliente] = useState(null);

  const clear = () => {
    setName("");
    setStock("");
    setCostPrice("");
    setSalePrice("");
    setDescription("");
    setMinimumStock(0);
    setMinimumStockSelect(undefined);
    setQuantity("");
    setType(1);
    setNomeCliente("");
    setFoneCliente("");
    setEmailCliente("");
    setCommentsCliente("");
  };

  const [modalAddProductBtns, setModalAddProductBtns] = useState(false);
  const [modalAddImport, setModalAddImport] = useState(false);

  const RefModalAddProductBtns = useRef();

  useOnClickOutside(RefModalAddProductBtns, () =>
    setModalAddProductBtns(false)
  );

  return (
    <>
      {/* <div
        style={{
          opacity: messages.messageSucesseAdd ? 1 : 0,
          visibility: messages.messageSucesseAdd ? "visible" : "hidden",
          right: messages.messageSucesseAdd ? "21px" : "-100%",
        }}
        className="myne-modal_message myne-modal_message-sucess"
      >
        <IconSucess />
        {activeLanguage?.alerts?.save_service?.alert}
        {messages?.plural
          ? messages?.type
          : messages?.type?.substring(0, messages?.type?.length - 1)}{" "}
        {messages?.plural
          ? messages.aPt
            ? activeLanguage?.alerts?.save_service?.plural?.replaceAll("o", "a")
            : activeLanguage?.alerts?.save_service?.plural
          : messages.aPt
          ? activeLanguage?.alerts?.save_service?.no_plural?.replaceAll(
              "o",
              "a"
            )
          : activeLanguage?.alerts?.save_service?.plural}{" "}
        {activeLanguage?.alerts?.save_service?.p2}
      </div>

      <div
        style={{
          opacity: messages.messageSucesseEdit ? 1 : 0,
          visibility: messages.messageSucesseEdit ? "visible" : "hidden",
          right: messages.messageSucesseEdit ? "21px" : "-100%",
        }}
        className="myne-modal_message myne-modal_message-sucess"
      >
        <IconSucess />
        {activeLanguage?.alerts?.update_service}
      </div>

      <div
        style={{
          opacity: messages.messageSucesseRemove ? 1 : 0,
          visibility: messages.messageSucesseRemove ? "visible" : "hidden",
          right: messages.messageSucesseRemove ? "21px" : "-100%",
        }}
        className="myne-modal_message myne-modal_message-sucess"
      >
        <IconSucess />
        {messages?.type?.substring(0, messages?.type?.length - 1)}{" "}
        {messages.aPt
          ? activeLanguage?.alerts?.delete_service?.replaceAll(
              "excluído",
              "excluída"
            )
          : activeLanguage?.alerts?.delete_service}
      </div>

      <div
        style={{
          opacity: messages.messageErro ? 1 : 0,
          visibility: messages.messageErro ? "visible" : "hidden",
          right: messages.messageErro ? "21px" : "-100%",
        }}
        className="myne-modal_message myne-modal_message-erro"
      >
        <IconErro />
        {activeLanguage?.alerts?.erro}
      </div> */}

      <div className="myne-dashboard myne-modal--animation-top">
        {/* MODAL ADD => minimumStock */}
        {/* <div
          style={{
            opacity: modalAddMinimumStock ? "5" : "0",
            visibility: modalAddMinimumStock ? "visible" : "hidden",
            zIndex: 999999999,
          }}
          className="myne-modal"
        >
          <div
            style={{
              transform: modalAddMinimumStock ? "scale(1)" : "scale(0.7)",
            }}
            className="myne-modal__modal-add-item"
          >
            <header className="myne-modal__modal-add-item-header">
              <p style={{ margin: 0 }}>
                {activeLanguage?.services?.product?.modal_stock?.title}
              </p>
            </header>

            <div className="myne-modal__modal-add-item-content">
              <InputForm
                value={ADDminimumStock}
                onChange={(e) => setADDminimumStock(e.target.value)}
                label={activeLanguage?.services?.inputs?.qty}
                type="number"
                onKeyUp={(e) => {
                  if (e.key?.toLowerCase() === "enter") {
                    addminimumStock();
                  }
                }}
              />

              <div>
                <button onClick={() => setModalAddMinimumStock(false)}>
                  {activeLanguage?.services?.btn?.no}
                </button>
                <button onClick={() => addminimumStock()}>
                  {activeLanguage?.services?.btn?.yes}
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <ModalMinimumStock
          ADDminimumStock={ADDminimumStock}
          addminimumStock={addminimumStock}
          modalAddMinimumStock={modalAddMinimumStock}
          setADDminimumStock={setADDminimumStock}
          setModalAddMinimumStock={setModalAddMinimumStock}
          key={"MINIMUN-STOCK-CADASTROS"}
        />
        {/* ADD PRODUCT IMPORT */}
        <ModalImportService
          modal={modalAddImport}
          setmodal={setModalAddImport}
          activeFilterCadastros={activeFilterCadastros}
          updateProducts={getProductsPagination}
        />
        {/* ADD SERVICE (product, client, service, discount) */}
        <ModalEditService
          modal={modalAdd}
          typeModal={activeLanguage?.services?.modal_new}
          setmodal={setModalAdd}
          filter={activeFilterCadastros}
          gerateId={Math.random().toString(16).slice(2)}
          item={item}
          newcadastro
          // product
          name={name}
          setName={setName}
          stock={stock}
          setStock={setStock}
          costPrice={costPrice}
          setCostPrice={setCostPrice}
          salePrice={salePrice}
          setSalePrice={setSalePrice}
          description={description}
          setDescription={setDescription}
          setSelectCategories={setSelectCategories}
          selectCategories={selectCategories}
          arrayMinStock={arrayMinStock}
          setMinimumStock={setMinimumStock}
          setModalAddMinimumStock={setModalAddMinimumStock}
          setMinimumStockSelect={setMinimumStockSelect}
          minimumStockSelect={minimumStockSelect}
          updateProducts={getProductsPagination}
          setOnCatalog={setOnCatalog}
          onCatalog={onCatalog}
          // client
          nomeCliente={nomeCliente}
          setNomeCliente={setNomeCliente}
          foneCliente={foneCliente}
          setFoneCliente={setFoneCliente}
          phoneMask={phoneMask}
          emailCliente={emailCliente}
          setEmailCliente={setEmailCliente}
          commentsCliente={commentsCliente}
          setCommentsCliente={setCommentsCliente}
          selectCliente={selectCliente}
          setSelectCliente={setSelectCliente}
          // discount
          type={type}
          setType={setType}
          quantity={quantity}
          setQuantity={setQuantity}
        />
        <ModalPlus
          modal={modalPlus}
          onModalPlusWithoutLocked={() => setModalPlusWithoutLocked(true)}
          setmodal={setModalPlus}
          btn_plus={modalPlusWithoutLocked}
          message_locked={modalPlus}
        />

        <Header />

        <div className="dashboard__content">
          <div className="dashboard-content__filter">
            <p className="myne-text">{activeLanguage?.registers?.title}</p>
            <FilterCadastros
              buttonsCadastros={buttonsCadastros}
              setActiveFilterCadastros={setActiveFilterCadastros}
              activeFilterCadastros={activeFilterCadastros}
              setLoading={setLoading}
            />
          </div>

          <div className="dashboard-content__grid">
            <div
              style={{ flex: 1 }}
              className={
                activeFilterCadastros === strClientLong
                  ? "dashboard-content__grid-vendas dashboard-content__grid-vendas__clientes"
                  : "dashboard-content__grid-vendas"
              }
            >
              <ListCadastros
                filterCadastros={filterCadastros}
                setFilterCadastros={setFilterCadastros}
                activeFilterCadastros={activeFilterCadastros}
                selectCliente={selectCliente}
                setSelectCliente={setSelectCliente}
                loading={loading}
                setLoading={setLoading}
                selectCategories={selectCategoriesForFilter}
                setSelectCategories={setSelectCategoriesForFilter}
                filters={filters}
                setFilterSelect={setFilterSelect}
                filterSelect={filterSelect}
                lastKey={lastKey}
                setLastKey={setLastKey}
                updateProducts={getProductsPagination}
                loadingPaginate={loadingPaginate}
                setLoadingPaginate={setLoadingPaginate}
              />

              {activeFilterCadastros === strClientLong ? (
                <VendasClientes
                  selectCliente={selectCliente}
                  setSelectCliente={setSelectCliente}
                />
              ) : (
                ""
              )}

              {activeFilterCadastros === strProductLong ? (
                <BtnExport products={allProducts} />
              ) : (
                ""
              )}

              {activeFilterCadastros !== strProductLong &&
              activeFilterCadastros !== strClientLong ? (
                <div
                  style={{
                    marginRight:
                      activeFilterCadastros === strClientLong ? "310px" : "",
                  }}
                  onClick={() => {
                    setModalAdd(true);
                    setName("");
                    setStock("");
                    setCostPrice("");
                    setSalePrice("");
                    setDescription("");
                    setMinimumStock(0);
                    setMinimumStockSelect(undefined);
                    setQuantity("");
                    setType(1);
                    setNomeCliente("");
                    setFoneCliente("");
                    setEmailCliente("");
                    setCommentsCliente("");
                  }}
                  className="myne-modal--animation dashboard-content__grid-vendas__button myne-btn"
                >
                  <IconAdd />
                  <p className="myne-text">
                    {activeLanguage?.registers?.add_btn?.title}{" "}
                    {activeFilterCadastros
                      ?.toLowerCase()
                      ?.substring(0, activeFilterCadastros?.length - 1)}
                  </p>
                </div>
              ) : (
                <section className="myne-modal--animation myne-area-add-products">
                  <div
                    style={{
                      opacity: modalAddProductBtns ? 1 : 0,
                      visibility: modalAddProductBtns ? "visible" : "hidden",
                      bottom: modalAddProductBtns ? 80 : 50,
                      marginRight:
                        activeFilterCadastros === strClientLong ? "310px" : "",
                    }}
                    ref={RefModalAddProductBtns}
                    className="myne-modal--animation myne-modal-add-products"
                  >
                    <button
                      onClick={() => {
                        if (activeFilterCadastros === strProductLong) {
                          if (
                            !ifPlusVerified &&
                            filterCadastros?.length >=
                              ProductSaleService_LIMIT_FREE
                          ) {
                            setModalPlus(
                              activeLanguage?.be_plus?.limit_products
                            );
                            setModalPlusWithoutLocked(false);
                          } else {
                            setModalAdd(true);
                            setModalAddProductBtns(!modalAddProductBtns);

                            analyticsEvent("ADD_PRODUCT_MANUALLY_CLICKED");
                          }
                        } else {
                          setModalAdd(true);
                          setModalAddProductBtns(!modalAddProductBtns);
                          analyticsEvent("ADD_CLIENT_MANUALLY_CLICKED");
                        }
                      }}
                    >
                      <IconAddProductsManual />
                      <p>
                        {activeLanguage?.registers?.add_btn?.add_manual?.p1}{" "}
                        {activeFilterCadastros?.toLowerCase()}{" "}
                        {activeLanguage?.registers?.add_btn?.add_manual?.p2}
                      </p>
                    </button>
                    <button
                      onClick={() => {
                        if (ifPlusVerified) {
                          setModalAddImport(true);
                          setModalAddProductBtns(!modalAddProductBtns);

                          if (activeFilterCadastros === strProductLong) {
                            analyticsEvent("ADD_PRODUCT_BATCH_CLICKED");
                          } else if (activeFilterCadastros === strClientLong) {
                            analyticsEvent("ADD_CLIENT_BATCH_CLICKED");
                          }
                        } else {
                          setModalPlus(activeLanguage?.be_plus?.locked_plus);
                          setModalPlusWithoutLocked(false);
                        }
                      }}
                    >
                      <IconAddProductsImport />
                      <p>
                        {activeLanguage?.registers?.add_btn?.add_lote?.p1}{" "}
                        {activeFilterCadastros?.toLowerCase()}{" "}
                        {activeLanguage?.registers?.add_btn?.add_lote?.p2}
                      </p>
                    </button>
                  </div>

                  <div
                    style={{
                      marginRight:
                        activeFilterCadastros === strClientLong ? "310px" : "",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setModalAddProductBtns(!modalAddProductBtns);
                      // setModalAdd(true);
                      clear();
                    }}
                    className="myne-modal--animation dashboard-content__grid-vendas__button myne-btn"
                  >
                    <IconAdd />
                    <p className="myne-text">
                      {activeLanguage?.registers?.add_btn?.title}{" "}
                      {activeFilterCadastros
                        ?.toLowerCase()
                        ?.substring(0, activeFilterCadastros?.length - 1)}
                    </p>
                  </div>
                </section>
              )}
            </div>

            {/* RIGHT */}
            {activeFilterCadastros === strProductLong ? (
              <ResumeStock filterCadastros={allProducts} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Cadastros;

const IconAdd = ({ ...rest }) => (
  <svg
    {...rest}
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9 8H8.89999V13C8.89999 13.2652 8.79464 13.5196 8.6071 13.7071C8.41957 13.8946 8.16521 14 7.89999 14C7.63478 14 7.38042 13.8946 7.19289 13.7071C7.00535 13.5196 6.89999 13.2652 6.89999 13V8H1.89999C1.63478 8 1.38042 7.89464 1.19289 7.70711C1.00535 7.51957 0.899994 7.26522 0.899994 7C0.899994 6.73478 1.00535 6.48043 1.19289 6.29289C1.38042 6.10536 1.63478 6 1.89999 6H6.89999V1C6.89999 0.734784 7.00535 0.48043 7.19289 0.292893C7.38042 0.105357 7.63478 0 7.89999 0C8.16521 0 8.41957 0.105357 8.6071 0.292893C8.79464 0.48043 8.89999 0.734784 8.89999 1V6H13.9C14.1652 6 14.4196 6.10536 14.6071 6.29289C14.7946 6.48043 14.9 6.73478 14.9 7C14.9 7.26522 14.7946 7.51957 14.6071 7.70711C14.4196 7.89464 14.1652 8 13.9 8Z"
      fill="white"
    />
  </svg>
);
