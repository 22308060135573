import React from "react";
import { useStateValue } from "../../../providers/StateProvider";
import NULLproductos from "../../../assets/images/NULL/nullproducts.svg";
import { formatPrice } from "../../../shared/utils/helpers/format";
import ProductSlidePhotos from "../../Photos/ProductSlidePhotos";

function ModalAddProductSale({
  addproductsale,
  setaddproductsale,
  amountproduct,
  amountproductsale,
  amountproductitem,
  setAmountproductitem,
  setAmountproductsale,
  addItemSelects,
  setSelectsProducts,
  selectsProducts,
}) {
  const [{ activeLanguage, activeMoeda }] = useStateValue();

  const onUpdateAmountProductSale = (item, amount, selects, setSelects) => {
    const array = selects.map((p) =>
      p.id === item.id
        ? {
          ...p,
          amount: amount,
        }
        : p
    );

    setSelects(array);
  };

  const addItemProductSale = () => {
    if (addproductsale.type === "add") {
      addItemSelects(addproductsale.item, setSelectsProducts, selectsProducts);
      setAmountproductitem(1);
      setaddproductsale({ type: null, item: null });
    } else {
      onUpdateAmountProductSale(
        addproductsale.item,
        amountproductsale,
        selectsProducts,
        setSelectsProducts
      );
      setAmountproductsale(1);
      setaddproductsale({ type: null, item: null });
    }
  };

  return (
    <div
      style={{
        zIndex: 9999999999,
        opacity: addproductsale.item ? "5" : "0",
        visibility: addproductsale.item ? "visible" : "hidden",
      }}
      className="myne-modal"
    >
      <div
        style={{ transform: addproductsale.item ? "scale(1)" : "scale(0.7)" }}
        className="myne-modal__modal-edit-cadastro myne-modal__modal-add-product-sale-item"
      >
        {addproductsale.item && (
          <>
            <header className="myne-modal__modal-edit-header">
              <section className="myne-modal__modal-edit-header__left">
                <IconProduct />
                <p style={{ textTransform: "initial" }}>
                  {
                    activeLanguage?.modals?.new_sale?.details?.modals_btns
                      ?.product?.modal_add?.title
                  }
                </p>
              </section>

              <section className="myne-modal__modal-edit-header__icons">
                <IconClose
                  onClick={() => setaddproductsale({ type: null, item: null })}
                />
              </section>
            </header>

            <div
              style={{
                transform: addproductsale.item ? "scale(1)" : "scale(0.7)",
              }}
              className="myne-modal__modal-add-product-sale-item__container"
            >
              <section className="myne-modal__modal-add-product-sale-item__container-area-product">
                <article>
                  <p>
                    <b>{addproductsale.item?.data?.stock}</b>{" "}
                    {
                      activeLanguage?.modals?.new_sale?.details?.modals_btns
                        ?.product?.modal_add?.unit_stock
                    }
                  </p>
                  <p>
                    <span>
                      {
                        activeLanguage?.modals?.new_sale?.details?.modals_btns
                          ?.product?.modal_add?.price_unit
                      }
                    </span>
                    <b>
                      {addproductsale.item?.data?.salePrice?.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: activeMoeda?.currencyId,
                        }
                      )}
                    </b>
                  </p>
                </article>
                <div>
                  <ProductSlidePhotos
                    style={{
                      objectFit: addproductsale.item?.data?.image || addproductsale.item?.data?.images
                        ? "cover"
                        : "",
                    }}
                    className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                    photos={addproductsale.item?.data?.images ?? addproductsale.item?.data?.image}
                  />
                  {/* <img
                    className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                    src={
                      addproductsale.item?.data?.image
                        ? addproductsale.item?.data?.image
                        : NULLproductos
                    }
                    style={{
                      objectFit: addproductsale.item?.data?.image
                        ? "cover"
                        : "",
                    }}
                    alt=""
                  /> */}
                  <p>{addproductsale.item?.data?.name}</p>
                  <section>
                    <IconRemove
                      onClick={() => {
                        if (amountproduct > 1) {
                          if (addproductsale.type === "add") {
                            if (isNaN(amountproductitem)) {
                              setAmountproductitem(1);
                            } else {
                              setAmountproductitem((remove) => remove - 1);
                            }
                          } else {
                            if (isNaN(amountproductsale)) {
                              setAmountproductsale(1);
                            } else {
                              setAmountproductsale((remove) => remove - 1);
                            }
                          }
                        }
                      }}
                    />
                    <input
                      onChange={(e) => {
                        let v = parseFloat(e.target.value);
                        if (addproductsale.type === "add") {
                          if (isNaN(v)) {
                            setAmountproductitem(1);
                          } else {
                            setAmountproductitem(v);
                          }
                        } else {
                          if (isNaN(amountproductsale)) {
                            setAmountproductsale(1);
                          } else {
                            setAmountproductsale(v);
                          }
                        }
                      }}
                      value={amountproduct}
                    />
                    <IconPlus
                      onClick={() => {
                        if (addproductsale.type === "add") {
                          if (isNaN(amountproductitem)) {
                            setAmountproductitem(1);
                          } else {
                            setAmountproductitem((add) => add + 1);
                          }
                        } else {
                          if (isNaN(amountproductsale)) {
                            setAmountproductsale(1);
                          } else {
                            setAmountproductsale((add) => add + 1);
                          }
                        }
                      }}
                    />
                  </section>
                </div>
                <section>
                  {amountproduct} x{" "}
                  {addproductsale.item?.data?.salePrice?.toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: activeMoeda?.currencyId,
                    }
                  )}{" "}
                  ={" "}
                  <b>
                    {formatPrice(
                      amountproduct * addproductsale.item?.data?.salePrice,
                      activeMoeda
                    )}
                  </b>
                </section>
              </section>

              <button onClick={() => addItemProductSale()}>
                {
                  activeLanguage?.modals?.new_sale?.details?.modals_btns
                    ?.product?.modal_add?.add_btn
                }
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ModalAddProductSale;

const IconProduct = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 16.5001C21.0005 16.6812 20.9514 16.859 20.8579 17.0141C20.7645 17.1693 20.6303 17.2959 20.47 17.3801L12.57 21.8201C12.403 21.9373 12.204 22.0002 12 22.0002C11.796 22.0002 11.597 21.9373 11.43 21.8201L3.53 17.3801C3.36968 17.2959 3.23552 17.1693 3.14208 17.0141C3.04864 16.859 2.9995 16.6812 3 16.5001V7.50011C2.9995 7.319 3.04864 7.14122 3.14208 6.98608C3.23552 6.83093 3.36968 6.70436 3.53 6.62011L11.43 2.18011C11.597 2.06289 11.796 2 12 2C12.204 2 12.403 2.06289 12.57 2.18011L20.47 6.62011C20.6303 6.70436 20.7645 6.83093 20.8579 6.98608C20.9514 7.14122 21.0005 7.319 21 7.50011V16.5001ZM12 4.15011L10.11 5.22011L16 8.61011L17.96 7.50011L12 4.15011ZM6.04 7.50011L12 10.8501L13.96 9.75011L8.08 6.35011L6.04 7.50011ZM5 15.9101L11 19.2901V12.5801L5 9.21011V15.9101ZM19 15.9101V9.21011L13 12.5801V19.2901L19 15.9101Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconClose = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM14.3125 14.3125C13.8906 14.6875 13.2812 14.6875 12.9062 14.3125L10 11.4062L7.09375 14.3125C6.71875 14.6875 6.10938 14.6875 5.6875 14.3125C5.3125 13.8906 5.3125 13.2812 5.6875 12.9062L8.59375 10L5.6875 7.09375C5.3125 6.71875 5.3125 6.10938 5.6875 5.6875C6.10938 5.3125 6.71875 5.3125 7.09375 5.6875L10 8.59375L12.9062 5.6875C13.2812 5.3125 13.8906 5.3125 14.3125 5.6875C14.6875 6.10938 14.6875 6.71875 14.3125 7.09375L11.4062 10L14.3125 12.9062C14.6875 13.2812 14.6875 13.8906 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

const IconRemove = ({ ...rest }) => (
  <svg
    {...rest}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.511719C3.85156 0.511719 0.511719 3.85156 0.511719 8C0.511719 12.1484 3.85156 15.4883 8 15.4883C12.1484 15.4883 15.4883 12.1484 15.4883 8C15.4883 3.85156 12.1484 0.511719 8 0.511719ZM10.9883 8.73828H5.01172C4.58984 8.73828 4.23828 8.42188 4.23828 8C4.23828 7.57812 4.58984 7.26172 5.01172 7.26172H10.9883C11.4102 7.26172 11.7617 7.57812 11.7617 8C11.7617 8.42188 11.4102 8.73828 10.9883 8.73828Z"
      fill="#FE2543"
    />
  </svg>
);

const IconPlus = ({ ...rest }) => (
  <svg
    {...rest}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.015625C4.46875 0.015625 0.015625 4.46875 0.015625 10C0.015625 15.5312 4.46875 19.9844 10 19.9844C15.5312 19.9844 19.9844 15.5312 19.9844 10C19.9844 4.46875 15.5312 0.015625 10 0.015625ZM13.9844 10.9844H10.9844V13.9844C10.9844 14.5469 10.5625 15.0156 10 15.0156C9.4375 15.0156 9.01562 14.5469 9.01562 13.9844V10.9844H6.01562C5.45312 10.9844 4.98438 10.5625 4.98438 10C4.98438 9.4375 5.45312 9.01562 6.01562 9.01562H9.01562V6.01562C9.01562 5.45312 9.4375 4.98438 10 4.98438C10.5625 4.98438 10.9844 5.45312 10.9844 6.01562V9.01562H13.9844C14.5469 9.01562 15.0156 9.4375 15.0156 10C15.0156 10.5625 14.5469 10.9844 13.9844 10.9844Z"
      fill="#58CC02"
    />
  </svg>
);
