import React, { useEffect, useState } from "react";

//
import { useStateValue } from "../../providers/StateProvider";

//
import "../../styles/Cadastros/Cadastros.css";

//
// import Photo from "../User/Photo";
import NULLproductos from "../../assets/images/NULL/nullproducts.svg";
import NULLservices from "../../assets/images/NULL/nullservices.svg";
import NULLuser from "../User/svgnull.svg";
import InputForm from "../inputs/InputForm";

import { firebase } from "../../services/Firebase";
import { deleteService, phoneMask, saveService } from "../../providers/reducer";

import { analyticsEvent } from "../../services/analytics";
import ModalNewCategorie from "../Modals/Categorie/ModalNewCategorie";
import Tooltip from "../Tooltip/tooltip";
import { Product } from "../../models/User/Products/Product";
import SwitchOnCatalog from "../Switch/SwitchOnCatalog";
import { StoreService } from "../../providers/catalogo";
import ModalMinimumStock from "../Modals/Product/ModalMinimumStock";
import toast from "react-hot-toast";
import { formatPrice } from "../../shared/utils/helpers/format";
import LoadingInButton from "../Loading/LoadingInButton";
import { changeUploadImg } from "../../shared/utils/helpers/upload";
import { IconAddPhoto } from "../../assets/icons/icons";
import UploadPhotos from "../Photos/UploadPhotos";
import ProductSlidePhotos from "../Photos/ProductSlidePhotos";

function ItemCadastro(props) {
  const [{ activeMoeda, activeLanguage, ifPlusVerified }, dispatch] =
    useStateValue();

  // STRINGS TYPES

  const strProductLong = activeLanguage?.services?.product?.title;
  const strClientLong = activeLanguage?.services?.client?.title;
  const strServiceLong = activeLanguage?.services?.service?.title;
  const strDiscountLong = activeLanguage?.services?.discount?.title;
  const strQuantityDiscount = activeLanguage?.services?.discount?.qty;

  //

  const [modalEdit, setModalEdit] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [photo, setPhoto] = useState(props?.item?.image);
  const [loading, setLoading] = useState(false);

  const iD = props.id;

  const [photos, setPhotos] = useState(["", "", ""]);
  // const [uploadProductImage, setUploadProductImage] = useState(null);
  const [uploadProductImages, setUploadProductImages] = useState(["", "", ""]);

  useEffect(() => {
    if (props?.type === strProductLong) {
      if (props?.item?.images != null) {
        setPhotos(props?.item?.images);
        setPhoto(props?.item?.images);
        setUploadProductImages(props?.item?.images);
      } else {
        if (props?.item?.image != null) {
          setPhotos([props?.item?.image, "", ""]);
          setPhoto([props?.item?.image, "", ""]);
          setUploadProductImages([props?.item?.image, "", ""]);
        } else {
          setPhotos(["", "", ""]);
          setPhoto(null);
          setUploadProductImages(["", "", ""]);
        }
      }
    } else {
      setPhoto(props?.item?.image);
    }
  }, [props?.item?.image, props?.item?.images, props?.type, strProductLong]);

  const changeUploadImgs = (e, upload, setUpload, id) => {
    if (e.target.files?.length === 0) {
      return;
    }
    if (upload?.length <= 2) {
      setUpload([upload[0], upload[1], upload[3]]);
    }
    setUpload(upload.map((file, i) => (i === id ? e.target.files[0] : file)));
    if (photos?.length <= 2) {
      setPhotos([photos[0], photos[1], photos[3]]);
    }
    setPhotos(
      photos.map((image, i) =>
        i === id ? URL.createObjectURL(e.target.files[0]) : image
      )
    );
  };

  // product

  const [name, setName] = useState("");
  const [stock, setStock] = useState(undefined);
  const [costPrice, setCostPrice] = useState(undefined);
  const [salePrice, setSalePrice] = useState(undefined);
  const [description, setDescription] = useState("");
  const [minimumStock, setMinimumStock] = useState(0);
  const [minimumStockSelect, setMinimumStockSelect] = useState(undefined);

  const [modalAddMinimumStock, setModalAddMinimumStock] = useState(false);
  const [ADDminimumStock, setADDminimumStock] = useState("");
  const [selectCategories, setSelectCategories] = useState([]);
  const [onCatalog, setOnCatalog] = useState(true);

  useEffect(() => {
    if (props.type !== strClientLong) {
      setName(props?.item?.name);
      setStock(props?.item?.stock);
      setCostPrice(props?.item?.costPrice);
      setSalePrice(props?.item?.salePrice);
      setDescription(props?.item?.description);
      setSelectCategories(
        props?.item?.categories?.length > 0 ? props?.item?.categories : []
      );
      if (
        props?.item?.minimumStock !== undefined &&
        props?.item?.minimumStock !== null
      ) {
        setMinimumStock(props?.item?.minimumStock);
        setMinimumStockSelect(props?.item?.minimumStock);
      }
    }
  }, [props?.item, props.type, strClientLong]);

  useEffect(() => {
    const setCatalogProduct = async () => {
      const hasProductInStore = await StoreService?.hasProductInStore({
        ...props?.item,
        id: iD,
      });
      setOnCatalog(hasProductInStore);
    };
    if (modalEdit && props.type === strProductLong) {
      setCatalogProduct();
    }
  }, [iD, modalEdit, props?.item, props.type, strProductLong]);

  const [arrayMinStock, setArrayMinStock] = useState([]);

  useEffect(() => {
    if (props.type === strProductLong) {
      let uniq = [minimumStock, 5, 10, 50, 100];
      setArrayMinStock(uniq);
    }
  }, [minimumStock, props.type, strProductLong]);

  const addminimumStock = () => {
    let value = parseFloat(ADDminimumStock);

    let array = [...arrayMinStock];
    array.push(parseFloat(ADDminimumStock));
    setArrayMinStock(array);
    setMinimumStockSelect(value);
    setMinimumStock(value);
    setADDminimumStock("");
    setModalAddMinimumStock(false);
  };

  // client

  const [nomeCliente, setNomeCliente] = useState("");
  const [foneCliente, setFoneCliente] = useState();
  const [emailCliente, setEmailCliente] = useState("");
  const [commentsCliente, setCommentsCliente] = useState("");

  useEffect(() => {
    if (props.type === strClientLong) {
      setNomeCliente(props?.item?.name);
      if (props?.item?.phone !== "" && props?.item?.phone !== null) {
        setFoneCliente(phoneMask(props?.item?.phone));
      } else {
        setFoneCliente(props?.item?.phone);
      }
      setEmailCliente(props?.item?.email);
      setCommentsCliente(props?.item?.description);
    }
  }, [props?.item, props.type, strClientLong]);

  // discounts

  const [quantity, setQuantity] = useState();
  const [type, setType] = useState(1);

  useEffect(() => {
    if (props.type === strDiscountLong) {
      if (props?.item?.type === 0) {
        setQuantity(props?.item?.quantity * 100);
      } else {
        setQuantity(props?.item?.quantity?.toFixed(2)?.replaceAll(".", ","));
      }

      setType(props?.item?.type);
    }
  }, [props?.item, props.type, strDiscountLong]);

  const [modalSelectCategorie, setModalSelectCategorie] = useState(false);

  const apliqueCategorie = (selectCategories) => {
    setSelectCategories(selectCategories);
  };

  const item =
    // props.type === strProductLong
    //   ? {
    //       name: name,
    //       salePrice: parseFloat(salePrice),
    //       stock: parseFloat(stock),
    //       costPrice: parseFloat(costPrice),
    //       categories: selectCategories,
    //       image: props?.item?.image,
    //       description: description,
    //       minimumStock:
    //         minimumStockSelect === "" ||
    //         minimumStockSelect === undefined ||
    //         isNaN(minimumStockSelect) ||
    //         minimumStockSelect === null
    //           ? 0
    //           : minimumStockSelect,
    //       discountRef: null,
    //       updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    //     }

    props.type === strProductLong
      ? new Product(
        name,
        parseFloat(salePrice),
        parseFloat(stock),
        parseFloat(costPrice),
        selectCategories,
        props?.item?.image,
        props?.item?.images == undefined ? null : props?.item?.images,
        description,
        minimumStockSelect === "" ||
          minimumStockSelect === undefined ||
          isNaN(minimumStockSelect) ||
          minimumStockSelect === null
          ? 0
          : minimumStockSelect,
        null,
        firebase.firestore.FieldValue.serverTimestamp()
      )
      : props.type === strClientLong
        ? {
          name: nomeCliente,
          email: emailCliente,
          phone: foneCliente,
          image: null,
          description: commentsCliente,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
        : props.type === strServiceLong
          ? {
            name: name,
            salePrice: parseFloat(salePrice),
            costPrice: parseFloat(costPrice),
            image: null,
            description: description,
            discountRef: null,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          }
          : {
            name: name,
            quantity:
              type === 0
                ? parseFloat(quantity?.toString()?.replaceAll(",", ".")) / 100
                : parseFloat(quantity?.toString()?.replaceAll(",", ".")),
            type: type,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          };

  return (
    <>
      {modalAddMinimumStock && (
        <ModalMinimumStock
          ADDminimumStock={ADDminimumStock}
          addminimumStock={addminimumStock}
          modalAddMinimumStock={modalAddMinimumStock}
          setADDminimumStock={setADDminimumStock}
          setModalAddMinimumStock={setModalAddMinimumStock}
          key={"MINIMUN-STOCK-ITEM-CADASTRO"}
        />
      )}
      {/* MODAL REMOVE */}
      {modalRemove && (
        <div
          style={{
            opacity: modalRemove ? "5" : "0",
            visibility: modalRemove ? "visible" : "hidden",
            zIndex: 999999999,
            transition: "all 0.3s ease",
          }}
          className="myne-modal myne-modal--animation"
        >
          <div
            style={{
              transform: modalRemove ? "scale(1)" : "scale(0.7)",
              transition: "all 0.3s ease",
            }}
            className="myne-modal__modal-add-item myne-modal--animation-scale"
          >
            {modalRemove && (
              <>
                <header className="myne-modal__modal-add-item-header">
                  <p style={{ margin: 0 }}>
                    {activeLanguage?.services?.modal_remove?.title}{" "}
                    {props.type?.substring(0, props.type?.length - 1)}
                  </p>
                </header>

                <div className="myne-modal__modal-add-item-content">
                  <p>
                    {activeLanguage?.services?.modal_remove?.ask}{" "}
                    {props.type
                      ?.substring(0, props.type?.length - 1)
                      ?.toLowerCase()}
                    ?
                  </p>

                  <div>
                    <button onClick={() => setModalRemove(false)}>
                      {activeLanguage?.services?.btn?.no}
                    </button>
                    <button
                      onClick={() => {
                        deleteService(
                          props.type,
                          item,
                          props.id,
                          () => {
                            setModalEdit(false);
                            setModalRemove(false);
                            if (props.selectCliente?.id === props?.id) {
                              props.setSelectCliente(null);
                            }
                            // dispatch({
                            //   type: "SET_MESSAGES",
                            //   messages: {
                            //     messageSucesseEdit: false,
                            //     messageSucesseAdd: false,
                            //     messageSucesseRemove: true,
                            //     messageErro: false,
                            //     type: props.type,
                            //   },
                            // });
                            toast.success(
                              `${props?.type?.substring(
                                0,
                                props?.type?.length - 1
                              )}${" "}${activeLanguage?.alerts?.delete_service}`
                            );
                            if (props.type === strProductLong) {
                              props.updateProducts();
                            }
                          },
                          (erro) => {
                            toast.error(activeLanguage?.alerts?.erro);
                            // dispatch({
                            //   type: "SET_MESSAGES",
                            //   messages: {
                            //     messageSucesseEdit: false,
                            //     messageSucesseAdd: false,
                            //     messageSucesseRemove: false,
                            //     messageErro: true,
                            //     type: props.type,
                            //   },
                            // });
                          },
                          activeLanguage
                        );
                      }}
                    >
                      {activeLanguage?.services?.btn?.yes}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* CADASTRO EDIT */}
      {modalEdit && (
        <div
          style={{
            opacity: modalEdit ? "5" : "0",
            visibility: modalEdit ? "visible" : "hidden",
          }}
          className="myne-modal myne-modal--animation"
        >
          <ModalNewCategorie
            modal={modalSelectCategorie}
            setmodal={setModalEdit}
            selectCategoriesData={selectCategories}
            setmodalback={setModalSelectCategorie}
            aplicar={apliqueCategorie}
            backIcon
          />
          <div
            style={{
              transform: modalEdit ? "scale(1)" : "scale(0.7)",
              width:
                props.type === strClientLong || props.type === strServiceLong
                  ? 390
                  : "",
              height:
                props.type === strProductLong
                  ? 600
                  : props.type === strClientLong ||
                    props.type === strServiceLong
                    ? 521
                    : "",
            }}
            className="myne-modal__modal-edit-cadastro myne-modal--animation-scale"
          >
            {modalEdit ? (
              <>
                <header className="myne-modal__modal-edit-header">
                  <section className="myne-modal__modal-edit-header__left">
                    {props.type === strProductLong ? (
                      <IconProduto />
                    ) : props.type === strClientLong ? (
                      <IconClientes />
                    ) : props.type === strServiceLong ? (
                      <IconServicos />
                    ) : (
                      <IconDescontos />
                    )}
                    <p>{props.type?.substring(0, props.type?.length - 1)}</p>
                  </section>

                  <section className="myne-modal__modal-edit-header__icons">
                    <IconDelete onClick={() => setModalRemove(true)} />
                    <IconClose onClick={() => setModalEdit(!modalEdit)} />
                  </section>
                </header>

                <div className="myne-modal__modal-edit-cadastro-content">
                  <div
                    style={{
                      width:
                        props.type === strClientLong ||
                          props.type === strServiceLong
                          ? "100%"
                          : "",
                      flex:
                        props.type === strClientLong ||
                          props.type === strServiceLong
                          ? 1
                          : "",
                    }}
                    className="myne-modal__modal-edit-cadastro-content__inputs"
                  >
                    {props.type === strProductLong ? (
                      <>
                        <InputForm
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          label={`${activeLanguage?.services?.inputs?.name}*`}
                        />
                        <InputForm
                          value={stock}
                          onChange={(e) => setStock(e.target.value)}
                          label={activeLanguage?.services?.inputs?.qty}
                          type="number"
                        />
                        <InputForm
                          value={costPrice}
                          onChange={(e) => setCostPrice(e.target.value)}
                          label={activeLanguage?.services?.inputs?.price_cost}
                          type="number"
                        />
                        <InputForm
                          value={salePrice}
                          onChange={(e) => setSalePrice(e.target.value)}
                          label={`${activeLanguage?.services?.inputs?.price_sale}*`}
                          type="number"
                        />
                        <InputForm
                          value={selectCategories?.map(
                            (categorie, i) =>
                              `${i !== 0 ? " " : ""}` + categorie?.name
                          )}
                          label={`Categoria`}
                          expandMore
                          readOnly={selectCategories?.length > 0}
                          onChange={() => { }}
                          onClickExpandMore={() =>
                            setModalSelectCategorie(true)
                          }
                        />
                        <InputForm
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          label={activeLanguage?.services?.inputs?.description}
                        />
                      </>
                    ) : props.type === strClientLong ? (
                      <>
                        <InputForm
                          value={nomeCliente}
                          onChange={(e) => setNomeCliente(e.target.value)}
                          label={`${activeLanguage?.services?.inputs?.name}*`}
                        />
                        <InputForm
                          value={foneCliente}
                          onChange={(e) =>
                            setFoneCliente(phoneMask(e.target.value))
                          }
                          label={activeLanguage?.services?.inputs?.phone}
                          type="text"
                        />
                        <InputForm
                          value={emailCliente}
                          onChange={(e) => setEmailCliente(e.target.value)}
                          label={activeLanguage?.services?.inputs?.email}
                          type="text"
                        />
                        <InputForm
                          value={commentsCliente}
                          onChange={(e) => setCommentsCliente(e.target.value)}
                          label={activeLanguage?.services?.inputs?.comment}
                        />
                      </>
                    ) : props.type === strServiceLong ? (
                      <>
                        <InputForm
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          label={`${activeLanguage?.services?.inputs?.name}*`}
                        />
                        <InputForm
                          value={costPrice}
                          onChange={(e) => setCostPrice(e.target.value)}
                          label={activeLanguage?.services?.inputs?.price_cost}
                          type="number"
                        />
                        <InputForm
                          value={salePrice}
                          onChange={(e) => setSalePrice(e.target.value)}
                          label={`${activeLanguage?.services?.inputs?.price_sale}*`}
                          type="number"
                        />
                        <InputForm
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          label={activeLanguage?.services?.inputs?.description}
                        />
                      </>
                    ) : (
                      <>
                        <p className="myne-modal__modal-edit-cadastro-content__select-p">
                          {activeLanguage?.services?.discount?.modal_add?.type}
                        </p>

                        <div className="myne-modal__modal-edit-cadastro-content__select">
                          <button
                            style={{
                              fontWeight: type === 1 ? 500 : "",
                              border:
                                type === 1 ? "1px solid rgb(170, 0, 255)" : "",
                              color: type === 1 ? "rgb(170, 0, 255)" : "",
                            }}
                            onClick={() => setType(1)}
                          >
                            <IconMoney
                              fill={
                                type === 1
                                  ? "rgb(170, 0, 255)"
                                  : "rgba(0, 0, 0, 0.87)"
                              }
                            />
                            {
                              activeLanguage?.services?.discount?.modal_add
                                ?.money
                            }
                          </button>
                          <button
                            style={{
                              fontWeight: type === 0 ? 500 : "",
                              border:
                                type === 0 ? "1px solid rgb(170, 0, 255)" : "",
                              color: type === 0 ? "rgb(170, 0, 255)" : "",
                            }}
                            onClick={() => setType(0)}
                          >
                            <IconPercentage
                              fill={
                                type === 0
                                  ? "rgb(170, 0, 255)"
                                  : "rgba(0, 0, 0, 0.87)"
                              }
                            />{" "}
                            {
                              activeLanguage?.services?.discount?.modal_add
                                ?.porcent
                            }
                          </button>
                        </div>

                        <InputForm
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          label={`${activeLanguage?.services?.discount?.modal_add?.input_name}*`}
                        />

                        {type === 1 ? (
                          <InputForm
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            label={`${activeLanguage?.services?.discount?.modal_add?.value_discount} (${activeMoeda?.currencySymbol})*`}
                          />
                        ) : (
                          <InputForm
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            label={`${activeLanguage?.services?.discount?.modal_add?.value_discount} (%)*`}
                          />
                        )}
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      border:
                        props.type === strClientLong ||
                          props.type === strServiceLong
                          ? "initial"
                          : "",
                      width:
                        props.type === strClientLong ||
                          props.type === strServiceLong
                          ? "initial"
                          : "",
                      flex:
                        props.type === strClientLong ||
                          props.type === strServiceLong
                          ? "initial"
                          : "",
                      padding:
                        props.type === strClientLong ||
                          props.type === strServiceLong
                          ? 0
                          : "",
                    }}
                    className="myne-modal__modal-edit-cadastro-content__right-subimit"
                  >
                    {props.type !== strDiscountLong ? (
                      <>
                        {props.type === strProductLong ? (
                          <section className="myne-modal__modal-edit-cadastro-content__right-subimit__stock-min">
                            <p>
                              {
                                activeLanguage?.services?.product?.modal_add
                                  ?.stock_min_value
                              }
                            </p>
                            <div>
                              {[...new Set(arrayMinStock)]?.map((item, i) => (
                                <button
                                  key={item}
                                  onClick={() =>
                                    item ===
                                      activeLanguage?.services?.product?.modal_add
                                        ?.other_stock
                                      ? setModalAddMinimumStock(true)
                                      : setMinimumStockSelect(item)
                                  }
                                  style={{
                                    background:
                                      item === minimumStockSelect
                                        ? "#AA00FF"
                                        : "",
                                    color:
                                      item === minimumStockSelect ? "#fff" : "",
                                  }}
                                >
                                  {item}
                                </button>
                              ))}
                              <button
                                onClick={() => setModalAddMinimumStock(true)}
                              >
                                {
                                  activeLanguage?.services?.product?.modal_add
                                    ?.other_stock
                                }
                              </button>
                            </div>
                          </section>
                        ) : (
                          ""
                        )}
                        {props.type === strProductLong ? (
                          <>
                            <UploadPhotos id={iD} photos={photos} />
                            <SwitchOnCatalog
                              setOnCatalog={setOnCatalog}
                              onCatalog={onCatalog}
                              item={item}
                              iD={iD}
                              isEditing
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <input
                      onChange={(e) =>
                        changeUploadImgs(
                          e,
                          uploadProductImages,
                          setUploadProductImages,
                          0
                        )
                      }
                      style={{ display: "none" }}
                      type="file"
                      id={`upload-product-add-image-${iD}-0`}
                    />
                    {ifPlusVerified ? (
                      <>
                        <input
                          onChange={(e) =>
                            changeUploadImgs(
                              e,
                              uploadProductImages,
                              setUploadProductImages,
                              1
                            )
                          }
                          style={{ display: "none" }}
                          type="file"
                          id={`upload-product-add-image-${iD}-1`}
                        />
                        <input
                          onChange={(e) =>
                            changeUploadImgs(
                              e,
                              uploadProductImages,
                              setUploadProductImages,
                              2
                            )
                          }
                          style={{ display: "none" }}
                          type="file"
                          id={`upload-product-add-image-${iD}-2`}
                        />
                      </>
                    ) : (
                      ""
                    )}

                    <button
                      onClick={() =>
                        saveService(
                          props.type,
                          item,
                          props.id,
                          () => {
                            setModalEdit(false);
                            // dispatch({
                            //   type: "SET_MESSAGES",
                            //   messages: {
                            //     messageSucesseEdit: true,
                            //     messageSucesseAdd: false,
                            //     messageSucesseRemove: false,
                            //     messageErro: false,
                            //     type: props.type,
                            //   },
                            // });
                            toast.success(
                              activeLanguage?.alerts?.update_service
                            );
                            analyticsEvent("EDIT_PRODUCT");
                            if (props.type === strProductLong) {
                              props.updateProducts();
                            }
                          },
                          (erro) => {
                            toast.error(activeLanguage?.alerts?.erro);
                            // dispatch({
                            //   type: "SET_MESSAGES",
                            //   messages: {
                            //     messageSucesseEdit: false,
                            //     messageSucesseAdd: false,
                            //     messageSucesseRemove: false,
                            //     messageErro: true,
                            //     type: props.type,
                            //   },
                            // });
                          },
                          uploadProductImages,
                          setLoading,
                          false,
                          activeLanguage,
                          onCatalog
                        )
                      }
                    >
                      {loading ? (
                        <LoadingInButton />
                      ) : (
                        <>
                          <IconSave />

                          <p>
                            {
                              activeLanguage?.services?.product?.modal_add
                                ?.save_btn
                            }
                          </p>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {/* CADASTRO ITEM */}
      {props.type === strClientLong ? (
        <div className="myne-item__cadastro-cliente-base myne-modal--animation-top">
          <Tooltip
            icon={
              <IconDelete
                className="myne-item__cadastro-cliente-base-icon"
                onClick={() => setModalRemove(!modalRemove)}
                style={{ width: 16, height: 16 }}
              />
            }
            direction={"bottom"}
            hover
            text={"Excluir cliente"}
          />
          <Tooltip
            icon={
              <IconEdit
                className="myne-item__cadastro-cliente-base-icon"
                onClick={() => setModalEdit(!modalEdit)}
                style={{ width: 16, height: 16 }}
              />
            }
            direction={"bottom"}
            hover
            text={"Editar cliente"}
          />

          <div
            onClick={() => {
              props.selectCliente?.id === props?.id
                ? props.setSelectCliente(null)
                : props.setSelectCliente({ ...props.item, id: props.id });
            }}
            className={
              props.selectCliente?.id === props?.id
                ? "myne-item__cadastro-cliente myne-item__cadastro-cliente-active"
                : "myne-item__cadastro-cliente"
            }
          >
            <div className={"myne-item__cadastro-cliente__user"}>
              <img
                src={photo ? photo : NULLuser}
                style={{ borderRadius: "100%" }}
                alt=""
              />
              <p>{props?.item?.name}</p>
            </div>

            <div className={"myne-item__cadastro-cliente__infos"}>
              <section>
                <IconPhone />
                {props?.item?.phone ? (
                  <p>{props?.item?.phone}</p>
                ) : (
                  <p style={{ color: "#FE2543" }}>
                    {activeLanguage?.services?.inputs?.no_item}
                  </p>
                )}
              </section>
              <section>
                <IconEmail />
                {props?.item?.email ? (
                  <p>{props?.item?.email}</p>
                ) : (
                  <p style={{ color: "#FE2543" }}>
                    {activeLanguage?.services?.inputs?.no_item}
                  </p>
                )}
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => setModalEdit(!modalEdit)}
          className="myne-item__cadastro myne-modal--animation-top"
        >
          <div className="myne-item__cadastro-left">
            {/* {props.type === "Clientes" ? (
          <Photo style={{ borderRadius: "100%" }} image={photo} />
        ) : props.type === "Descontos" ? (
          ""
        ) : props.type === "Produtos" || props.type === "Serviços" ? (
          photo !== null && photo !== undefined && photo !== "" ? (
            props.type === "Produtos" ? (
              <img
                src={NULLproductos}
                onLoad={(e) => {
                  e.target.src = photo;
                }}
                alt=""
              />
            ) : (
              <img
                src={NULLservices}
                onLoad={(e) => {
                  e.target.src = photo;
                }}
                alt=""
              />
            )
          ) : props.type === "Produtos" ? (
            <img src={NULLproductos} alt="" />
          ) : (
            <img src={NULLservices} alt="" />
          )
        ) : (
          ""
        )} */}

            {props.type === strDiscountLong ? (
              <IconDesconto style={{ marginRight: 12 }} />
            ) : props.type === strClientLong ? (
              <img
                src={photo ? photo : NULLuser}
                style={{ borderRadius: "100%" }}
                alt=""
              />
            ) : props.type === strProductLong ? (
              <ProductSlidePhotos photos={photo} />
            ) : (
              <img
                style={{ objectFit: photo ? "" : "contain" }}
                src={photo ? photo : NULLservices}
                alt=""
              />
            )}

            <section>
              <p>{props?.item?.name}</p>
              {props?.item?.description ? (
                <h5>{props?.item?.description}</h5>
              ) : (
                ""
              )}
            </section>
          </div>

          <div className="myne-item__cadastro-right">
            {props.type === strProductLong ? (
              <>
                <section>
                  <p>{activeLanguage?.services?.product?.value}</p>
                  <h5>
                    {props?.item?.salePrice
                      ? parseFloat(props?.item?.salePrice)?.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: activeMoeda?.currencyId,
                        }
                      )
                      : ""}
                  </h5>
                </section>
                <section>
                  <p>{activeLanguage?.services?.product?.stock}</p>
                  <h5
                    style={{
                      color:
                        props?.item?.stock === props?.item?.minimumStock
                          ? "#FFBD06"
                          : props?.item?.stock > props?.item?.minimumStock
                            ? "#58CC02"
                            : "#FE2543",
                    }}
                  >
                    {props?.item?.stock}
                  </h5>
                </section>
              </>
            ) : props.type === strClientLong ? (
              <>
                {props?.item?.phone ? (
                  <section>
                    <p>{activeLanguage?.services?.client?.telephone}</p>
                    <h5>{props?.item?.phone}</h5>
                  </section>
                ) : (
                  ""
                )}
              </>
            ) : props.type === strServiceLong ? (
              <>
                {props?.item?.salePrice ? (
                  <section>
                    <p>{activeLanguage?.services?.service?.value}</p>
                    <h5>
                      {props?.item?.salePrice
                        ? parseFloat(props?.item?.salePrice)?.toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: activeMoeda?.currencyId,
                          }
                        )
                        : ""}
                    </h5>
                  </section>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                <section>
                  <p>{strQuantityDiscount}</p>
                  <h5>
                    {props?.item?.type === 1
                      ? `${formatPrice(props?.item?.quantity, activeMoeda)}`
                      : `${parseFloat(props?.item?.quantity) * 100}%`}
                  </h5>
                </section>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ItemCadastro;

const IconMoney = ({ fill }) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3438 0.15625H1.65625C0.9375 0.15625 0.34375 0.75 0.34375 1.5V9.5C0.34375 10.25 0.9375 10.8438 1.65625 10.8438H12.3438C13.0625 10.8438 13.6562 10.25 13.6562 9.5V1.5C13.6562 0.75 13.0625 0.15625 12.3438 0.15625ZM12.3438 8.84375C12.3438 9.1875 12.0312 9.5 11.6562 9.5H2.34375C1.96875 9.5 1.65625 9.1875 1.65625 8.84375V2.15625C1.65625 1.8125 1.96875 1.5 2.34375 1.5H11.6562C12.0312 1.5 12.3438 1.8125 12.3438 2.15625V8.84375ZM8.34375 4.15625C8.6875 4.15625 9 3.875 9 3.5C9 3.125 8.6875 2.84375 8.34375 2.84375H7.65625V2.8125C7.65625 2.46875 7.375 2.15625 7 2.15625C6.625 2.15625 6.34375 2.46875 6.34375 2.8125V2.84375H5.65625C5.3125 2.84375 5 3.125 5 3.5V5.5C5 5.875 5.3125 6.15625 5.65625 6.15625H7.65625V6.84375H5.65625C5.3125 6.84375 5 7.125 5 7.5C5 7.875 5.3125 8.15625 5.65625 8.15625H6.34375C6.34375 8.53125 6.625 8.84375 7 8.84375C7.375 8.84375 7.65625 8.53125 7.65625 8.15625H8.34375C8.6875 8.15625 9 7.875 9 7.5V5.5C9 5.125 8.6875 4.84375 8.34375 4.84375H6.34375V4.15625H8.34375Z"
      style={{ fill: fill }}
    />
  </svg>
);

const IconPercentage = ({ fill }) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66868 0.498675L0.999345 9.16868C0.822888 9.34513 0.723755 9.58446 0.723755 9.83401C0.723755 10.0836 0.822888 10.3229 0.999345 10.4993C1.1758 10.6758 1.41513 10.7749 1.66468 10.7749C1.91423 10.7749 2.15355 10.6758 2.33001 10.4993L10.9993 1.83001C11.1703 1.65241 11.2648 1.41483 11.2625 1.16832C11.2602 0.921814 11.1613 0.686047 10.987 0.511686C10.8127 0.337326 10.577 0.238288 10.3305 0.235855C10.084 0.233422 9.84636 0.327788 9.66868 0.498675ZM2.66801 0.833342C2.49292 0.833167 2.3195 0.867481 2.15767 0.934325C1.99583 1.00117 1.84875 1.09924 1.72481 1.22292C1.60088 1.34661 1.50252 1.4935 1.43535 1.6552C1.36818 1.8169 1.33352 1.99025 1.33335 2.16534C1.33317 2.34044 1.36748 2.51385 1.43433 2.67569C1.50117 2.83752 1.59924 2.98461 1.72293 3.10854C1.84661 3.23248 1.9935 3.33084 2.1552 3.398C2.3169 3.46517 2.49025 3.49983 2.66535 3.50001C3.01897 3.50036 3.35825 3.36023 3.60854 3.11043C3.85884 2.86063 3.99966 2.52163 4.00001 2.16801C4.00037 1.81439 3.86023 1.47511 3.61043 1.22481C3.36063 0.97451 3.02163 0.833695 2.66801 0.833342ZM9.33468 7.50001C8.98106 7.49965 8.64178 7.63979 8.39148 7.88959C8.14118 8.13939 8.00037 8.47839 8.00001 8.83201C7.99966 9.18563 8.1398 9.52491 8.38959 9.77521C8.63939 10.0255 8.97839 10.1663 9.33201 10.1667C9.50711 10.1669 9.68052 10.1325 9.84236 10.0657C10.0042 9.99885 10.1513 9.90078 10.2752 9.77709C10.3991 9.65341 10.4975 9.50652 10.5647 9.34482C10.6318 9.18312 10.6665 9.00977 10.6667 8.83468C10.6669 8.65958 10.6325 8.48616 10.5657 8.32433C10.4989 8.16249 10.4008 8.01541 10.2771 7.89148C10.1534 7.76754 10.0065 7.66918 9.84482 7.60201C9.68312 7.53484 9.50978 7.50018 9.33468 7.50001Z"
      style={{ fill: fill }}
    />
  </svg>
);

const IconDesconto = ({ ...rest }) => (
  <svg
    {...rest}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="black" fillOpacity="0.06" />
    <path
      d="M20.5156 14.8906C21.9219 14.8906 22.625 15.4531 22.9062 16.2969C23 16.7188 23.4688 17 23.8906 17H23.9375C24.6406 17 25.2031 16.2969 24.9219 15.6406C24.5 14.4688 23.5156 13.5781 22.0156 13.2031V12.5C22.0156 11.6562 21.3125 11 20.5156 11C19.6719 11 19.0156 11.6562 19.0156 12.5V13.1562C18.5938 13.25 18.2656 13.3906 17.8906 13.5312L19.3906 15.0312C19.7188 14.9375 20.0938 14.8906 20.5156 14.8906ZM12.7812 12.6406C12.3594 13.0156 12.3594 13.625 12.7812 14.0469L15.5 16.7656C15.5 18.8281 17.0469 20 19.3906 20.7031L22.9062 24.1719C22.5781 24.6875 21.875 25.1094 20.5156 25.1094C18.8281 25.1094 17.9844 24.5 17.6562 23.6562C17.5156 23.2812 17.1875 23 16.7656 23H16.5781C15.875 23 15.3594 23.75 15.6406 24.4062C16.25 25.7188 17.5625 26.5156 19.0156 26.8438V27.5C19.0156 28.3438 19.6719 29 20.5156 29C21.3594 29 22.0156 28.3438 22.0156 27.5V26.8438C22.9531 26.6562 23.8438 26.2812 24.4531 25.7188L26 27.2188C26.375 27.6406 26.9844 27.6406 27.4062 27.2188C27.7812 26.8438 27.7812 26.2344 27.4062 25.8125L14.1875 12.6406C13.8125 12.2188 13.1562 12.2188 12.7812 12.6406Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

const IconEdit = ({ ...rest }) => (
  <svg
    width="13"
    {...rest}
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10.625V12.6562C0 12.8438 0.15625 13 0.34375 13H2.375C2.4375 13 2.53125 12.9688 2.59375 12.9062L9.875 5.625L7.375 3.125L0.09375 10.4062C0.03125 10.4688 0 10.5312 0 10.625ZM11.8125 3.6875C12.0625 3.4375 12.0625 3 11.8125 2.75L10.25 1.1875C10 0.9375 9.5625 0.9375 9.3125 1.1875L8.09375 2.40625L10.5938 4.90625L11.8125 3.6875Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

const IconPhone = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4141 9.46094L10.5156 9.21484C10.0586 9.17969 9.60156 9.32031 9.28516 9.67188L7.91406 11.043C5.76953 9.95312 4.04688 8.23047 2.95703 6.08594L4.36328 4.71484C4.67969 4.39844 4.82031 3.94141 4.78516 3.48438L4.57422 1.58594C4.46875 0.8125 3.83594 0.25 3.0625 0.25H1.76172C0.917969 0.25 0.214844 0.953125 0.285156 1.79688C0.671875 8.23047 5.80469 13.3281 12.2031 13.7148C13.0469 13.7852 13.75 13.082 13.75 12.2383V10.9375C13.75 10.1641 13.1875 9.53125 12.4141 9.46094Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconEmail = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0117 0.988281H1.98828C1.17969 0.988281 0.511719 1.69141 0.511719 2.5V11.5C0.511719 12.3086 1.17969 13.0117 1.98828 13.0117H14.0117C14.8203 13.0117 15.4883 12.3086 15.4883 11.5V2.5C15.4883 1.69141 14.8203 0.988281 14.0117 0.988281ZM13.6953 4.1875L8.80859 7.24609C8.31641 7.5625 7.68359 7.5625 7.19141 7.24609L2.30469 4.1875C2.12891 4.08203 1.98828 3.87109 1.98828 3.66016C1.98828 3.13281 2.55078 2.85156 2.97266 3.09766L8 6.26172L13.0273 3.09766C13.4492 2.85156 14.0117 3.13281 14.0117 3.66016C14.0117 3.87109 13.8711 4.08203 13.6953 4.1875Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconDelete = ({ ...res }) => (
  <svg
    {...res}
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 15.9844C1 17.1094 1.89062 18 3.01562 18H10.9844C12.1094 18 13 17.1094 13 15.9844V6C13 4.92188 12.1094 3.98438 10.9844 3.98438H3.01562C1.89062 3.98438 1 4.92188 1 6V15.9844ZM13 0.984375H10.5156L9.8125 0.28125C9.625 0.09375 9.34375 0 9.10938 0H4.89062C4.65625 0 4.375 0.09375 4.1875 0.28125L3.48438 0.984375H1C0.4375 0.984375 0.015625 1.45312 0.015625 2.01562C0.015625 2.53125 0.4375 3 1 3H13C13.5625 3 13.9844 2.53125 13.9844 2.01562C13.9844 1.45312 13.5625 0.984375 13 0.984375Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

const IconSave = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1836 0.707031C10.9023 0.390625 10.5156 0.25 10.1289 0.25H1.76172C0.917969 0.25 0.25 0.917969 0.25 1.76172V12.2383C0.25 13.082 0.917969 13.75 1.76172 13.75H12.2383C13.082 13.75 13.75 13.082 13.75 12.2383V3.87109C13.75 3.48438 13.6094 3.09766 13.293 2.81641L11.1836 0.707031ZM7 12.2383C5.76953 12.2383 4.75 11.2539 4.75 9.98828C4.75 8.75781 5.76953 7.73828 7 7.73828C8.23047 7.73828 9.25 8.75781 9.25 9.98828C9.25 11.2539 8.23047 12.2383 7 12.2383ZM7.73828 4.75H3.23828C2.42969 4.75 1.76172 4.08203 1.76172 3.23828C1.76172 2.42969 2.42969 1.76172 3.23828 1.76172H7.73828C8.58203 1.76172 9.25 2.42969 9.25 3.23828C9.25 4.08203 8.58203 4.75 7.73828 4.75Z"
      fill="white"
    />
  </svg>
);
const IconClose = ({ ...rest }) => (
  <svg
    width="20"
    {...rest}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875ZM14.3125 14.3125C14.75 13.8125 14.75 13.3438 14.3125 12.9062L11.4062 10L14.3125 7.09375C14.75 6.65625 14.75 6.1875 14.3125 5.6875C13.8125 5.25 13.3438 5.25 12.9062 5.6875L10 8.59375L7.09375 5.6875C6.65625 5.25 6.1875 5.25 5.6875 5.6875C5.25 6.1875 5.25 6.65625 5.6875 7.09375L8.59375 10L5.6875 12.9062C5.25 13.3438 5.25 13.8125 5.6875 14.3125C6.1875 14.75 6.65625 14.75 7.09375 14.3125L10 11.4062L12.9062 14.3125C13.3438 14.75 13.8125 14.75 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconProduto = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 14.5001C18.0005 14.6812 17.9514 14.859 17.8579 15.0141C17.7645 15.1693 17.6303 15.2959 17.47 15.3801L9.57 19.8201C9.40304 19.9373 9.204 20.0002 9 20.0002C8.796 20.0002 8.59696 19.9373 8.43 19.8201L0.530004 15.3801C0.369683 15.2959 0.235516 15.1693 0.142077 15.0141C0.048638 14.859 -0.00049917 14.6812 3.82324e-06 14.5001V5.50011C-0.00049917 5.319 0.048638 5.14122 0.142077 4.98608C0.235516 4.83093 0.369683 4.70436 0.530004 4.62011L8.43 0.180111C8.59696 0.0628934 8.796 0 9 0C9.204 0 9.40304 0.0628934 9.57 0.180111L17.47 4.62011C17.6303 4.70436 17.7645 4.83093 17.8579 4.98608C17.9514 5.14122 18.0005 5.319 18 5.50011V14.5001ZM9 2.15011L7.11 3.22011L13 6.61011L14.96 5.50011L9 2.15011ZM3.04 5.50011L9 8.85011L10.96 7.75011L5.08 4.35011L3.04 5.50011ZM2 13.9101L8 17.2901V10.5801L2 7.21011V13.9101ZM16 13.9101V7.21011L10 10.5801V17.2901L16 13.9101Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconClientes = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9 17.2C9.71535 17.1935 8.54999 16.8988 7.50466 16.3413C6.45932 15.7838 5.56541 14.9802 4.90004 14C4.90004 12 8.90004 10.9 10.9 10.9C12.9 10.9 16.9 12 16.9 14C16.2347 14.9802 15.3407 15.7838 14.2954 16.3413C13.25 16.8988 12.0847 17.1935 10.9 17.2ZM10.9 3C11.296 2.99267 11.6894 3.06526 12.0567 3.21343C12.4239 3.3616 12.7576 3.58231 13.0377 3.86236C13.3177 4.14241 13.5384 4.47605 13.6866 4.84334C13.8348 5.21063 13.9074 5.60402 13.9 6C13.9 6.79565 13.5839 7.55871 13.0213 8.12132C12.4587 8.68393 11.6957 9 10.9 9C10.1044 9 9.34132 8.68393 8.77871 8.12132C8.2161 7.55871 7.90004 6.79565 7.90004 6C7.89272 5.60402 7.96525 5.21063 8.11342 4.84334C8.26159 4.47605 8.4823 4.14241 8.76235 3.86236C9.0424 3.58231 9.37608 3.3616 9.74337 3.21343C10.1107 3.06526 10.5041 2.99267 10.9 3ZM10.9 0C8.92223 0 6.98881 0.58649 5.34432 1.6853C3.69983 2.78412 2.41809 4.3459 1.66121 6.17317C0.904337 8.00043 0.706331 10.0111 1.09218 11.9509C1.47804 13.8907 2.43041 15.6725 3.82894 17.0711C5.22746 18.4696 7.00931 19.422 8.94912 19.8079C10.8889 20.1937 12.8996 19.9957 14.7268 19.2388C16.5541 18.4819 18.1159 17.2002 19.2147 15.5557C20.3136 13.9112 20.9 11.9778 20.9 10C20.8924 7.35019 19.8363 4.81109 17.9626 2.93738C16.0889 1.06368 13.5499 0.00765122 10.9 0V0Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconServicos = ({ className, style }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      style={{ ...style }}
      d="M8.0625 1.9375C6.71875 0.59375 4.71875 0.3125 3.09375 1.125L5.53125 3.53125C5.78125 3.78125 5.78125 4.21875 5.53125 4.46875L4.46875 5.53125C4.1875 5.8125 3.78125 5.8125 3.53125 5.53125L1.09375 3.125C0.3125 4.71875 0.59375 6.71875 1.9375 8.0625C3.1875 9.3125 5 9.625 6.53125 9.0625L11.8438 14.375C12.5312 15.0312 13.625 15.0312 14.3125 14.375C15 13.6875 15 12.5625 14.3125 11.875L9.03125 6.59375C9.625 5.03125 9.3125 3.1875 8.0625 1.9375Z"
      fill="#aa00ff"
      fillOpacity="1"
    />
  </svg>
);

const IconDescontos = ({ className, style }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      style={{ ...style }}
      d="M6.34375 2.59375C7.28125 2.59375 7.75 2.96875 7.9375 3.53125C8 3.8125 8.3125 4 8.59375 4H8.625C9.09375 4 9.46875 3.53125 9.28125 3.09375C9 2.3125 8.34375 1.71875 7.34375 1.46875V1C7.34375 0.4375 6.875 0 6.34375 0C5.78125 0 5.34375 0.4375 5.34375 1V1.4375C5.0625 1.5 4.84375 1.59375 4.59375 1.6875L5.59375 2.6875C5.8125 2.625 6.0625 2.59375 6.34375 2.59375ZM1.1875 1.09375C0.90625 1.34375 0.90625 1.75 1.1875 2.03125L3 3.84375C3 5.21875 4.03125 6 5.59375 6.46875L7.9375 8.78125C7.71875 9.125 7.25 9.40625 6.34375 9.40625C5.21875 9.40625 4.65625 9 4.4375 8.4375C4.34375 8.1875 4.125 8 3.84375 8H3.71875C3.25 8 2.90625 8.5 3.09375 8.9375C3.5 9.8125 4.375 10.3438 5.34375 10.5625V11C5.34375 11.5625 5.78125 12 6.34375 12C6.90625 12 7.34375 11.5625 7.34375 11V10.5625C7.96875 10.4375 8.5625 10.1875 8.96875 9.8125L10 10.8125C10.25 11.0938 10.6562 11.0938 10.9375 10.8125C11.1875 10.5625 11.1875 10.1562 10.9375 9.875L2.125 1.09375C1.875 0.8125 1.4375 0.8125 1.1875 1.09375Z"
      fill="#aa00ff"
      fillOpacity="1"
    />
  </svg>
);
