import React, { useRef, useState } from "react";
import { useStateValue } from "../../providers/StateProvider";
import { IconAddPhoto } from "../../assets/icons/icons";
import ModalPlus from "../Modals/Plus/ModalPlus";
import { useOnClickOutside } from "../../providers/reducer";

const UploadPhotos = ({ id, photos }) => {
  const [{ activeLanguage, ifPlusVerified }] = useStateValue();
  // const [{ activeLanguage }] = useStateValue(); // for test 
  // const ifPlusVerified = true; // for test
  const [modalIsNotPlus, setModalIsNotPlus] = useState(false);
  const modalIsNotPlusRef = useRef();

  const [modalPlus, setModalPlus] = useState(false);

  useOnClickOutside(modalIsNotPlusRef, () => setModalIsNotPlus(false));

  return (
    <div className="myne-modal__modal-edit-cadastro-content__right-subimit__photos">
      <ModalPlus
        modal={modalPlus}
        setmodal={setModalPlus}
        // message_locked={activeLanguage?.be_plus?.locked_plus}
        message_locked={""}
      />

      <p
        className="myne-text-modal"
      >
        {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      > */}
        {activeLanguage?.services?.product?.modal_add?.photo}
        {/* <p className="myne-text-modal" style={{ fontSize: 14 }}>
          Foto
        </p>
        <IconAddPhoto /> */}
        {/* </div> */}
      </p>

      <label
        htmlFor={`upload-product-add-image-${id}-0`}
        style={{ minHeight: 96, maxHeight: 96, height: 96 }}
        // style={{ minHeight: 100, maxHeight: 100, height: 100 }}
        className="myne-modal__modal-edit-cadastro-content__right-subimit__photo"
      >
        {photos[0] ? <img src={photos[0]} alt="" /> : null}
        <IconAddPhoto />
      </label>
      <div style={{ position: "relative" }}>
        <section
          onMouseEnter={() => setModalIsNotPlus(true)}
          onMouseLeave={() => setModalIsNotPlus(false)}
          ref={modalIsNotPlusRef}
          className="myne-modal__modal-new-sale-content__area-modal-search myne-modal-more-photos"
          style={{
            opacity: modalIsNotPlus ? 1 : 0,
            visibility: modalIsNotPlus ? "visible" : "hidden",
            transform: modalIsNotPlus ? "scale(1)" : "scale(0)",
            left: "initial",
            top: "initial",
          }}
        >
          <b className="myne-text">Mais fotos</b>
          <p className="myne-text">
            Apenas os assinantes do Myne Plus podem adicionar mais fotos de
            produtos. Ao se tornar um assinante, você terá acesso a essa
            funcionalidade exclusiva e a uma série de outros recursos que
            levarão seu negócio a outro nível!
          </p>
          <button
            className="myne-btn active"
            onClick={() => setModalPlus(!modalPlus)}
          >
            Ver planos
          </button>
        </section>

        {!ifPlusVerified ? (
          <div
            onMouseEnter={() => setModalIsNotPlus(true)}
            onMouseLeave={() => setModalIsNotPlus(false)}
            className="myne-lock-plus"
          >
            <article>
              <IconLock />
              <p className="myne-text">Myne Plus</p>
            </article>
          </div>
        ) : (
          ""
        )}
        <label
          htmlFor={ifPlusVerified ? `upload-product-add-image-${id}-1` : ""}
          className="myne-modal__modal-edit-cadastro-content__right-subimit__photo"
          style={{
            minHeight: 60,
            maxHeight: 60,
            height: 60,
            background: !ifPlusVerified ? "#FFDBE0" : "",
          }}
        >
          {photos[1] ? <img src={photos[1]} alt="" /> : null}
          <IconAddPhoto style={{ opacity: !ifPlusVerified ? 0.15 : 1 }} />
        </label>
        <label
          htmlFor={ifPlusVerified ? `upload-product-add-image-${id}-2` : ""}
          className="myne-modal__modal-edit-cadastro-content__right-subimit__photo"
          style={{
            minHeight: 60,
            maxHeight: 60,
            height: 60,
            background: !ifPlusVerified ? "#FFDBE0" : "",
          }}
        >
          {photos[2] ? <img src={photos[2]} alt="" /> : null}
          <IconAddPhoto style={{ opacity: !ifPlusVerified ? 0.15 : 1 }} />
        </label>
      </div>
    </div>
  );
};

export default UploadPhotos;

const IconLock = () => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 5.34375C10.7188 5.34375 11.3438 5.9375 11.3438 6.65625V13.3438C11.3438 14.0625 10.7188 14.6562 10 14.6562H2C1.28125 14.6562 0.65625 14.0625 0.65625 13.3438V6.65625C0.65625 5.9375 1.28125 5.34375 2 5.34375H2.65625V4C2.65625 2.15625 4.15625 0.65625 6 0.65625C7.84375 0.65625 9.34375 2.15625 9.34375 4V5.34375H10ZM6 11.3438C6.71875 11.3438 7.34375 10.7188 7.34375 10C7.34375 9.28125 6.71875 8.65625 6 8.65625C5.28125 8.65625 4.65625 9.28125 4.65625 10C4.65625 10.7188 5.28125 11.3438 6 11.3438ZM4 5.34375H8V4C8 2.90625 7.09375 2 6 2C4.90625 2 4 2.90625 4 4V5.34375Z"
      fill="black"
      fill-opacity="0.36"
    />
  </svg>
);
