import { useEffect } from "react";
import { auth, db, firebase, storage } from "../services/Firebase";
import { StoreService } from "./catalogo";
import { langPt, mesesPt } from "./lang/index";
import { clientAlgolia } from "../services/algolia";
import { uploadImages } from "../shared/utils/helpers/upload";
import { daysToSalesFilter } from "./filterDates";

// let date = new Date();

export const initialState = {
  user: null,
  activeBtn: "Dia",
  menuOpen: false,
  // activeDate: `${date.getDate()} ${mesesPt[date.getMonth() + 1]
  //   ?.toLowerCase()
  //   ?.slice(0, 3)}`,
  activeDate: daysToSalesFilter[0]?.days,
  calendarDate: [],
  calendarDates: [],
  mesesNew: mesesPt,
  SaleFirestore: {
    NotPaid: 2,
    Paid: 1,
    Overdue: 3,
    Partly_Paid: 4,
  },
  messages: {
    messageSucesseEdit: false,
    messageSucesseAdd: false,
    messageSucesseRemove: false,
    messageSucesseAddVenda: false,
    messageSucesseRemoveVenda: false,
    messageErro: false,
  },
  ifPlusVerified: false,
  activeMoeda: {
    alpha3: "BRA",
    currencyId: "BRL",
    currencyName: "Brazilian real",
    currencySymbol: "R$",
    id: "BR",
    name: "Brazil",
  },
  activeLanguage: langPt,
};

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_ACTIVE: "SET_ACTIVE",
  SET_MESES: "SET_MESES",
  SET_MENU_OPEN: "SET_MENU_OPEN",
  SET_ACTIVE_DATE: "SET_ACTIVE_DATE",
  SET_CALENDAR_DATE: "SET_CALENDAR_DATE",
  SET_CALENDAR_DATES: "SET_CALENDAR_DATES",
  SET_MESSAGES: "SET_MESSAGES",
  SET_CHECK_USER_IF_PLUS: "SET_CHECK_USER_IF_PLUS",
  SET_ACTIVE_MOEDA: "SET_ACTIVE_MOEDA",
  SET_ACTIVE_LANGUAGE: "SET_ACTIVE_LANGUAGE",
};

export const logout = {
  ...(initialState.user = null),
};

export const getDatesRange = (startDate, stopDate, mesesNew) => {
  const ONE_DAY = 24 * 3600 * 1000;
  let currentDate = new Date(startDate);
  let days = [];
  while (currentDate <= stopDate) {
    let array = `${new Date(currentDate).getDate()} ${
      mesesNew[new Date(currentDate).getMonth()]
    }, ${new Date(currentDate).getFullYear()}`;
    days.push(array);
    currentDate = currentDate - 1 + 1 + ONE_DAY;
  }
  return days;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguage: action.activeLanguage,
      };
    case actionTypes.SET_MESES:
      return {
        ...state,
        mesesNew: action.mesesNew,
      };
    case actionTypes.SET_CHECK_USER_IF_PLUS:
      return {
        ...state,
        ifPlusVerified: action.ifPlusVerified,
      };
    case actionTypes.SET_ACTIVE_MOEDA:
      return {
        ...state,
        activeMoeda: action.activeMoeda,
      };
    case actionTypes.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SET_ACTIVE:
      return {
        ...state,
        activeBtn: action.activeBtn,
      };
    case actionTypes.SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.menuOpen,
      };
    case actionTypes.SET_ACTIVE_DATE:
      return {
        ...state,
        activeDate: action.activeDate,
      };
    case actionTypes.SET_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.calendarDate,
      };
    case actionTypes.SET_CALENDAR_DATES:
      return {
        ...state,
        calendarDates: action.calendarDates,
      };
    default:
      return state;
  }
};

export default reducer;

export const COPY = (text, setMessage) => {
  if (document.queryCommandSupported("copy")) {
    let textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    if (setMessage) {
      setMessage(true);
    }
  }
};

export function phoneMask(v) {
  let r = v?.toString()?.replace(/\D/g, "");
  r = r?.toString()?.replace(/^0/, "");

  if (r?.length > 11) {
    r = r?.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r?.length > 7) {
    r = r?.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r?.length > 2) {
    r = r?.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else if (v?.toString()?.trim() !== "") {
    r = r?.replace(/^(\d*)/, "($1");
  }
  return r;
}

export const insertStr = (string, valor) => {
  return (
    string?.toString()?.substr(0, 2) + valor + string?.toString()?.substr(2)
  );
};

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref?.current || ref?.current?.contains(event?.target)) {
        return;
      }

      handler(event);
      document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27) {
          handler(event);
        }
      };
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

// FOMART DATE

export const strFormatDate = (date, mesesNew) => {
  let language = "pt";
  const lang = navigator.language || navigator.userLanguage;

  if (lang !== "pt-BR" && lang !== "es") {
    language = "en";
  } else {
    language = lang === "pt-BR" ? "pt" : lang;
  }

  if (language === "pt") {
    // 17 de set de 2021
    return `${date?.getDate()} de ${mesesNew[date?.getMonth()]
      .toLowerCase()
      .slice(0, 3)} de ${date?.getFullYear()}`;
  } else if (language === "en") {
    // Sep 21, 2023
    return `${mesesNew[date?.getMonth()].slice(
      0,
      3
    )} ${date?.getDate()}, ${date?.getFullYear()}`;
  } else if (language === "es") {
    // 17 de sep de 2021
    return `${date?.getDate()} de ${mesesNew[date?.getMonth()]
      .toLowerCase()
      .slice(0, 3)} de ${date?.getFullYear()}`;
  } else {
    // Sep 21, 2023
    return `${mesesNew[date?.getMonth()].slice(
      0,
      3
    )} ${date?.getDate()}, ${date?.getFullYear()}`;
  }
};

export const formatDateCalendar = (date, mesesNew) => {
  let language = "pt";
  const lang = navigator.language || navigator.userLanguage;

  if (lang !== "pt-BR" && lang !== "es") {
    language = "en";
  } else {
    language = lang === "pt-BR" ? "pt" : lang;
  }
  if (language === "pt") {
    // 17 Setembro, 2021
    return `${date.getDate()} ${
      mesesNew[date.getMonth()]
    }, ${date.getFullYear()}`;
  } else if (language === "en") {
    // September 17, 2021
    return `${
      mesesNew[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  } else if (language === "es") {
    // 17 Septiembre, 2021
    return `${date.getDate()} ${
      mesesNew[date.getMonth()]
    }, ${date.getFullYear()}`;
  } else {
    // September 17, 2021
    return `${
      mesesNew[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatDateDay = (date, mesCurt, year, yearAtual) => {
  let language = "pt";
  const lang = navigator.language || navigator.userLanguage;

  if (lang !== "pt-BR" && lang !== "es") {
    language = "en";
  } else {
    language = lang === "pt-BR" ? "pt" : lang;
  }
  if (language === "pt") {
    return year === yearAtual
      ? `${date} ${mesCurt}`
      : `${date} ${mesCurt}, ${year}`;
  } else if (language === "en") {
    return year === yearAtual
      ? `${capitalizeFirstLetter(mesCurt)} ${date}`
      : `${capitalizeFirstLetter(mesCurt)} ${date}, ${year}`;
  } else if (language === "es") {
    return year === yearAtual
      ? `${date} ${mesCurt}`
      : `${date} ${mesCurt}, ${year}`;
  } else {
    return year === yearAtual
      ? `${capitalizeFirstLetter(mesCurt)} ${date}`
      : `${capitalizeFirstLetter(mesCurt)} ${date}, ${year}`;
  }
};

export const formatDateCalendar_TO_STR = () => {
  let language = "pt";
  const lang = navigator.language || navigator.userLanguage;

  if (lang !== "pt-BR" && lang !== "es") {
    language = "en";
  } else {
    language = lang === "pt-BR" ? "pt" : lang;
  }
  if (language === "pt") {
    return "a";
  } else if (language === "en") {
    return "to";
  } else if (language === "es") {
    return "al";
  } else {
    return "to";
  }
};

export const toLowerStr = (x) => {
  return x?.toString()?.toLowerCase();
};

// cpf

export const handleCpfCnpjChange = (event, setcpfCnpjValue) => {
  // Get only the numbers from the data input
  let data = event.target.value.replace(/\D/g, "");
  // Checking data length to define if it is cpf or cnpj
  if (data.length > 11) {
    // It's cnpj
    let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
      5,
      3
    )}/`;
    if (data.length > 12) {
      cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
    } else {
      cnpj += data.substr(8);
    }
    data = cnpj;
  } else {
    // It's cpf
    let cpf = "";
    let parts = Math.ceil(data.length / 3);
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${data.substr(i * 3)}`;
        break;
      }
      cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
    }
    data = cpf;
  }
  setcpfCnpjValue(data);
};

// get query

export function getQueryVariable(variable) {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

// formatNumber

export const formNum = (x) => {
  return x > 9 ? x : `0${x}`;
};

// GET (onSnapshot)

export const getProducts = (user, set, setLoading) => {
  const docReference = db
    .collection("users")
    .doc(user?.uid)
    .collection("products");

  if (setLoading) setLoading(true);

  docReference.onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      if (setLoading) setLoading(false);
      set(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    } else if (setLoading) {
      setTimeout(() => {
        if (snapshot.docs.length <= 0) {
          setLoading("not");
        } else {
          setLoading(true);
        }
      }, [800]);
    }
  });
};
export const getProductsDocs = async () => {
  const array = await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("products")
    .get();

  return array.docs;
};
export const getCategoriesDocs = async () => {
  const array = await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("categories")
    .get();

  return array.docs;
};
export const getClients = (user, set, setLoading) => {
  const docReference = db
    .collection("users")
    .doc(user?.uid)
    .collection("clients");

  setLoading(true);

  docReference.onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      setLoading(false);
      set(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    } else {
      setTimeout(() => {
        if (snapshot.docs.length <= 0) {
          setLoading("not");
        } else {
          setLoading(true);
        }
      }, [800]);
    }
  });
};
export const getServices = (user, set, setLoading) => {
  const docReference = db
    .collection("users")
    .doc(user?.uid)
    .collection("services");

  setLoading(true);

  docReference.onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      setLoading(false);
      set(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    } else {
      setTimeout(() => {
        if (snapshot.docs.length <= 0) {
          setLoading("not");
        } else {
          setLoading(true);
        }
      }, [800]);
    }
  });
};
export const getDiscounts = (user, set, setLoading) => {
  const docReference = db
    .collection("users")
    .doc(user?.uid)
    .collection("discounts");

  setLoading(true);

  docReference.onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      setLoading(false);
      set(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    } else {
      setTimeout(() => {
        if (snapshot.docs.length <= 0) {
          setLoading("not");
        } else {
          setLoading(true);
        }
      }, [800]);
    }
  });
};

// PRODUCT SALES

export const getProductSales = async () => {
  let array = await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("productSales")
    .get();

  return array.docs;
};

export const ProductSaleService_LIMIT_FREE = 40;

// PRODUCT SERVICE
export const ProductSaleService = {
  productSalesByProduct: async (ref, id) => {
    if (id) {
      let productSales = await getProductSales();

      return productSales?.filter((doc) => {
        let dataRef = doc.data()?.productRef;
        return dataRef?.path === ref;
      });
    }
  },
  productSalesBySale: async (ref, id) => {
    if (id) {
      let productSales = await getProductSales();

      return productSales?.filter((doc) => {
        let dataRef = doc.data()?.saleRef;
        return dataRef?.path === ref?.path;
      });
    }
  },
  productSalesByClient: async (ref, id) => {
    if (id) {
      let productSales = await getProductSales();

      return productSales?.filter((doc) => {
        let dataRef = doc.data()?.clientRef;
        return dataRef?.path === ref;
      });
    }
  },
  add: (item, id, batch) => {
    const gerateId = Math.random().toString(16).slice(2);

    const iD = id ? id : gerateId;

    if (batch) {
      batch.set(
        db
          .collection("users")
          .doc(auth?.currentUser?.uid)
          .collection("productSales")
          .doc(iD),
        item
      );
    } else {
      db.collection("users")
        .doc(auth?.currentUser?.uid)
        .collection("productSales")
        .doc(iD)
        .set(item);
    }
  },
};

// SALES
export const getSales = async () => {
  let array = await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("sales")
    .get();

  return array.docs;
};

// SALE SERVICE
export const SaleService = {
  salesByClient: async (ref, id) => {
    if (id) {
      let sales = await getSales();

      return sales?.filter((doc) => {
        let dataRef = doc.data()?.clientRef;
        return dataRef?.path === ref;
      });
    }
  },
  salesByDiscount: async (ref, id) => {
    if (id) {
      let sales = await getSales();

      return sales?.filter((doc) => {
        let dataRef = doc.data()?.discountRef;
        return dataRef?.path === ref;
      });
    }
  },
  add: (item, id) => {
    const gerateId = Math.random().toString(16).slice(2);

    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("sales")
      .doc(id ? id : gerateId)
      .set(item);
  },
};

// SALES

const getSeviceSales = async () => {
  let array = await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("serviceSales")
    .get();

  return array.docs;
};
const getInstallments = async () => {
  let array = await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("installments")
    .get();

  return array.docs;
};

// SALE Installments
export const InstallmentsService = {
  installmentsBySale: async (ref, id) => {
    if (id) {
      let installments = await getInstallments();

      return installments?.filter((doc) => {
        let dataRef = doc.data()?.saleRef;
        return dataRef?.path === ref?.path;
      });
    }
  },
};

// SALE SERVICE
export const ServiceSaleService = {
  serviceSalesByClient: async (ref, id) => {
    if (id) {
      let serviceSales = await getSeviceSales();

      return serviceSales?.filter((doc) => {
        let dataRef = doc.data()?.clientRef;
        return dataRef?.path === ref;
      });
    }
  },
  serviceSalesBySale: async (ref, id) => {
    if (id) {
      let serviceSales = await getSeviceSales();

      return serviceSales?.filter((doc) => {
        let dataRef = doc.data()?.saleRef;
        return dataRef?.path === ref?.path;
      });
    }
  },
  serviceSalesByService: async (ref, id) => {
    if (id) {
      let serviceSales = await getSeviceSales();

      return serviceSales?.filter((doc) => {
        let dataRef = doc.data()?.serviceRef;
        return dataRef?.path === ref;
      });
    }
  },
  add: (item, id) => {
    const gerateId = Math.random().toString(16).slice(2);

    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("serviceSales")
      .doc(id ? id : gerateId)
      .set(item);
  },
};

// ACTIONS SERVICES
export const deleteService = (type, item, id, then, erro, activeLanguage) => {
  const strProductLong = activeLanguage?.services?.product?.title;
  const strClientLong = activeLanguage?.services?.client?.title;
  const strServiceLong = activeLanguage?.services?.service?.title;
  const strDiscountLong = activeLanguage?.services?.discount?.title;

  switch (type) {
    case strProductLong:
      ProductFirestore.remove(id, then, erro, item);
      break;
    case strClientLong:
      ClientFirestore.remove(id, then, erro);
      break;
    case strServiceLong:
      ServicesFirestore.remove(id, then, erro);
      break;
    case strDiscountLong:
      DiscountsFirestore.remove(id, then, erro);
      break;

    default:
      break;
  }
};

const returnn = (item) => {
  return item === "" || item === undefined || isNaN(item) || item === null
    ? 0
    : item;
};

export const saveService = async (
  type,
  item,
  id,
  then,
  erro,
  uploadProductImage,
  setLoading,
  BATCHimportProduct,
  activeLanguage,
  onCatalog
) => {
  const strProductLong = activeLanguage?.services?.product?.title;
  const strClientLong = activeLanguage?.services?.client?.title;
  const strServiceLong = activeLanguage?.services?.service?.title;
  const strDiscountLong = activeLanguage?.services?.discount?.title;
  const str_req_fields = activeLanguage?.services?.inputs?.required_fields;

  switch (type) {
    case strProductLong:
      // --- PRODUTOS --- //
      if (
        item?.name !== "" &&
        item?.salePrice !== "" &&
        item?.salePrice !== null &&
        item?.salePrice !== undefined &&
        !isNaN(item?.salePrice)
      ) {
        item = {
          ...item,
          stock: returnn(item?.stock),
          costPrice: returnn(item?.costPrice),
        };
        const uploadProductImages =
          uploadProductImage != null
            ? typeof uploadProductImage[0] != "string" ||
              typeof uploadProductImage[1] != "string" ||
              typeof uploadProductImage[2] != "string"
            : null;

        if (BATCHimportProduct) {
          const batch = db.batch();
          // limit batch :: https://stackoverflow.com/questions/75973985/firebase-what-state-is-a-batched-write-in-after-a-commit

          // witch batch
          await ProductFirestore.add(id, item, then, erro, batch, onCatalog);
          const ref = `users/${auth?.currentUser?.uid}/products/${id}`;

          const productSalesUniqueProduct =
            await ProductSaleService.productSalesByProduct(ref, id);

          productSalesUniqueProduct?.map((doc) => {
            const productSaleFirestore = doc.data();
            productSaleFirestore.product = item;
            ProductSaleService.add(item, doc.id, batch);
          });

          await batch.commit().then(then).catch(erro);
        } else if (!uploadProductImage || !uploadProductImages) {
          await ProductFirestore.add(id, item, then, erro, null, onCatalog);
          const ref = `users/${auth?.currentUser?.uid}/products/${id}`;
          const productSalesUniqueProduct =
            await ProductSaleService.productSalesByProduct(ref, id);
          productSalesUniqueProduct?.forEach((doc) => {
            const productSaleFirestore = doc.data();
            productSaleFirestore.product = item;
            ProductSaleService.add(item, doc.id);
          });
        } else {
          // UPLOAD IMAGE
          const responseURL = await uploadImages(
            "products",
            uploadProductImage,
            setLoading,
            activeLanguage
          );

          item.image = responseURL[0] ?? "";
          item.images = responseURL;

          await ProductFirestore.add(id, item, then, erro, null, onCatalog);
          const ref = `users/${auth?.currentUser?.uid}/products/${id}`;

          const productSalesUniqueProduct =
            await ProductSaleService.productSalesByProduct(ref, id);

          productSalesUniqueProduct?.forEach((doc) => {
            const productSaleFirestore = doc.data();
            productSaleFirestore.product = item;
            ProductSaleService.add(item, doc.id);
          });
        }
      } else {
        alert(str_req_fields);
      }
      // --- // --- //
      break;
    case strClientLong:
      // --- CLIENTES --- //
      if (item?.name !== "") {
        ClientFirestore.add(id, item, then, erro);
        let refClient = `users/${auth?.currentUser?.uid}/clients/${id}`;

        let salesUniqueClient = await SaleService.salesByClient(refClient, id);

        salesUniqueClient?.forEach((doc) => {
          let saleFirestore = doc.data();
          saleFirestore.client = item;
          SaleService.add(saleFirestore, doc.id);
        });

        let productSalesUniqueClient =
          await ProductSaleService.productSalesByClient(refClient, id);

        productSalesUniqueClient?.forEach((doc) => {
          let productSaleFirestore = doc.data();
          productSaleFirestore.client = item;
          ProductSaleService.add(productSaleFirestore, doc.id);
        });

        let serviceSalesUniqueClient =
          await ServiceSaleService.serviceSalesByClient(refClient, id);

        serviceSalesUniqueClient?.forEach((doc) => {
          let serviceSaleFirestore = doc.data();
          serviceSaleFirestore.client = item;
          ServiceSaleService.add(serviceSaleFirestore, doc.id);
        });
      } else {
        alert(str_req_fields);
      }
      // --- // --- //
      break;
    case strServiceLong:
      // --- SERVIÇOS --- //
      if (
        item?.name !== "" &&
        item?.salePrice !== "" &&
        item?.salePrice !== null &&
        item?.salePrice !== undefined &&
        !isNaN(item?.salePrice)
      ) {
        item = {
          ...item,
          costPrice: returnn(item.costPrice),
        };

        ServicesFirestore.add(id, item, then, erro);
        let refService = `users/${auth?.currentUser?.uid}/services/${id}`;

        let serviceSalesUniqueService =
          await ServiceSaleService.serviceSalesByService(refService, id);

        serviceSalesUniqueService?.map((doc) => {
          let serviceSaleFirestore = doc.data();
          serviceSaleFirestore.service = item;
          ServiceSaleService.add(serviceSaleFirestore, doc.id);
        });
      } else {
        alert(str_req_fields);
      }
      // --- // --- //
      break;
    case strDiscountLong:
      // --- DESCONTOS --- //
      if (
        item?.name !== "" &&
        item?.quantity !== "" &&
        item?.quantity !== undefined &&
        !isNaN(item?.quantity) &&
        item?.quantity !== null
      ) {
        DiscountsFirestore.add(id, item, then, erro);
        let refDiscount = `users/${auth?.currentUser?.uid}/discounts/${id}`;

        let list = await SaleService.salesByDiscount(refDiscount, id);

        list?.map((doc) => {
          let saleFirestore = doc.data();
          saleFirestore.discount = item;
          SaleService.add(saleFirestore, doc.id);
        });
      } else {
        alert(str_req_fields);
      }
      // --- // --- //
      break;

    default:
      break;
  }
};

// product
export const migrateCategoriesIfNeed = async () => {
  const user = await getDataUser();

  if (!user.categoriesMigrated) {
    ProductService.updateCategoriesOfProduct();
    user.categoriesMigrated = true;
    await updateUser(user);
  }
};
export const checkIfNeedMigrateProduct = async () => {
  const user = await getDataUser();

  if (!user.productsMigratedToAlgolia) {
    ProductService.saveAllProductsToAlgolia();
    user.productsMigratedToAlgolia = true;
    // user.productsAlgoliaMigrateToSearch = true;
    await updateUser(user);
  }
};

// # Colocar o storeName em cada produto no Algolia
// # Check catalogo migrado
// # Check add algolia produto with storeName

export const checkIfNeedProductsAlgoliaMigrateToSearch = async () => {
  const user = await getDataUser();
  const storeName = user?.storeName;

  if (
    (storeName && !user.productsAlgoliaMigrateToSearch) ||
    !user.productsMigratedToAlgolia
  ) {
    ProductService.saveAllProductsToAlgolia(storeName);

    user.productsMigratedToAlgolia = true;
    if (storeName) {
      user.productsAlgoliaMigrateToSearch = true;
    }

    await updateUser(user);
    if (storeName) {
      await db.collection("stores").doc(storeName).update({
        productsAlgoliaMigrateToSearch: true,
      });
    }
  }
};

export const erroFunction = (erro) => console.log(erro);

export const queryAsync = async (query) => {
  try {
    const snapshot = await query?.get();
    return snapshot?.docs?.map((doc) => ({ id: doc?.id, data: doc?.data() }));
  } catch (error) {
    console.error("Erro ao executar a consulta:", error);
  }
};

export const strAllCategoriesProducts = "AllCategories-Myne-Catalog";

export const ProductService = {
  saveProductToAlgolia: async (product, then, erro) => {
    const userId = auth.currentUser.uid;
    const productId = product?.id;

    const productObj = {
      userId: userId,
      objectID: productId,
      name: product?.name,
      salePrice: product?.salePrice,
      stock: product?.stock,
      costPrice: product?.costPrice,
      image: product?.image,
      images: product?.images,
      description: product?.description,
      minimumStock: product?.minimumStock,
      barcode: product?.barcode,
      barcodeFormat: product?.barcodeFormat,
      categories: product?.categories,
      storeName: product?.storeName,
    };
    // const productJson = JSON.stringify(productObj, null, 2);

    // console.log(productJson);

    await clientAlgolia
      .saveObject({
        // indexName: "products",
        indexName: "prod_PRODUCTS_BY_USER",
        // body: productJson,
        body: productObj,
      })
      .then(then)
      .catch(erro);
  },
  removeProductFromAlgolia: async (product) => {
    await clientAlgolia.deleteObject({
      // indexName: "products",
      indexName: "prod_PRODUCTS_BY_USER",
      objectID: product?.id,
    });
  },
  saveAllProductsToAlgolia: async (storeName, productsToSave) => {
    const products = productsToSave ? productsToSave : await getProductsDocs();

    if (storeName) {
      await products.forEach((product) => {
        ProductService.saveProductToAlgolia(
          {
            id: product?.id,
            storeName: storeName,
            ...product?.data(),
          },
          () => {},
          erroFunction
        );
      });
    } else {
      await products.forEach((product) => {
        ProductService.saveProductToAlgolia(
          {
            id: product?.id,

            ...product?.data(),
          },
          () => {},
          erroFunction
        );
      });
    }
  },
  pageProductsLimit: 50,
  productsFirstBatch: async function (selectCategorie, filterSelect, filters) {
    try {
      const docReference = db
        .collection("users")
        .doc(userId())
        .collection("products");

      let query = docReference.orderBy(
        filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.fieldPath,
        filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.directionStr
      );

      let products = [];
      let lastKey = "";

      if (selectCategorie[0] === strAllCategoriesProducts) {
        query = query.limit(ProductService.pageProductsLimit);

        const data = await query.get();

        data.forEach((doc) => {
          products.push({ id: doc.id, data: doc.data() });

          lastKey = doc;
        });

        return { products, lastKey };
      } else {
        query = query.limit(ProductService.pageProductsLimit);

        const { products, lastKey } =
          await ProductService?.listProductByCategorySingle(
            selectCategorie,
            query
          );

        return { products, lastKey };
      }
    } catch (e) {
      console.log(e);
    }
  },
  productsNextBatch: async function (
    key,
    filterSelect,
    filters,
    selectCategorie
  ) {
    try {
      const docReference = db
        .collection("users")
        .doc(userId())
        .collection("products");

      let query = docReference
        .orderBy(
          filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.fieldPath,
          filters[filterSelect - 1]?.fieldFirestoreQueryOrder?.directionStr
        )
        .startAfter(key)
        .limit(ProductService.pageProductsLimit);
      let products = [];
      let lastKey = "";

      if (selectCategorie[0] === strAllCategoriesProducts) {
        const data = await query.get();

        data.forEach((doc) => {
          products.push({ id: doc.id, data: doc.data() });
          lastKey = doc;
        });

        return { products, lastKey };
      } else {
        query = query.limit(ProductService.pageProductsLimit);

        const { products, lastKey } =
          await ProductService?.listProductByCategorySingle(
            selectCategorie,
            query
          );

        return { products, lastKey };
      }
    } catch (e) {
      console.log(e);
    }
  },
  searchProducts: async (search) => {
    const userId = auth.currentUser.uid;

    const { results } = await clientAlgolia.search({
      requests: [
        {
          // indexName: "products",
          indexName: "prod_PRODUCTS_BY_USER",
          query: search,
          hitsPerPage: 50,
          filters: `userId:${userId}`,
        },
      ],
    });

    return results;
  },
  addList: (products) => {
    products?.map(async (product) => {
      const newCategories = [];
      product.categories?.forEach((categorie) => {
        newCategories.push({
          id: categorie.id,
          name: categorie.name,
        });
      });

      const productUpdate = {
        ...product,
        updatedAt: updatedAt,
        categories: newCategories,
      };

      await ProductFirestore?.add(
        productUpdate.id,
        productUpdate,
        () => console.log("Update categories"),
        erroFunction,
        null
      );
    });
  },
  updateCategoriesOfProduct: async () => {
    const collectionReference = db
      .collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("products");

    const querySnapshot = await collectionReference.get();
    const productsToUpdate = [];

    querySnapshot.docs.map(async (documentSnapshot) => {
      let hasProductToUpdate = false;
      try {
        const categories = documentSnapshot.data()?.categories;

        categories?.forEach((category) => {
          if (Object.keys(category).length > 2) {
            hasProductToUpdate = true;
          }
        });

        if (hasProductToUpdate) {
          const productToUpdate = documentSnapshot.data();
          if (productToUpdate != null) {
            productToUpdate.id = documentSnapshot.id;
            productToUpdate.documentPath = collectionReference.doc(
              documentSnapshot.id
            ).path;

            productsToUpdate.push(productToUpdate);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });

    ProductService?.addList(productsToUpdate);
  },
  listProductByCategorySingle: async (category, orderByQuery) => {
    const map = {
      id: category?.id,
      name: category?.name,
    };

    const query1 = orderByQuery?.where("categories", "array-contains", map);
    const list1 = await ProductService.queryAsyncKey(query1);

    const query2 = orderByQuery?.where("categories", "array-contains", {
      id: null,
      name: category?.name,
    });
    const list2 = await ProductService.queryAsyncKey(query2);

    const completeList = [...list1.products];
    list2.products.forEach((item) => {
      if (!completeList.some((product) => product?.id === item.id)) {
        completeList.push(item);
      }
    });

    return { products: completeList, lastKey: list2.lastKey };
  },
  queryAsyncKey: async (query) => {
    const products = [];
    let lastKey = "";

    try {
      const data = await query?.get();

      data.forEach((doc) => {
        products.push({ id: doc.id, data: doc.data() });

        lastKey = doc;
      });
      return { products, lastKey };
    } catch (error) {
      console.error("Error: ", error);
      return [];
    }
  },
};

const ProductFirestore = {
  add: async (id, item, then, erro, batch, onCatalog) => {
    const gerateId = Math.random().toString(16).slice(2);

    const iD = id ? id : gerateId;

    if (batch) {
      await batch.set(
        db
          .collection("users")
          .doc(auth?.currentUser?.uid)
          .collection("products")
          .doc(iD),
        item
      );
    } else {
      await db
        .collection("users")
        .doc(auth?.currentUser?.uid)
        .collection("products")
        .doc(iD)
        .set(item)
        .catch(erro);
    }

    if (!batch) then();

    if (onCatalog) {
      await StoreService.addItemToCatalog(item, iD, "products");
    }

    if (typeof onCatalog == "string") {
      await ProductService.saveProductToAlgolia(
        {
          id: iD,
          storeName: onCatalog,
          ...item,
        },
        () => {},
        erroFunction
      );
    } else if (typeof onCatalog !== "string") {
      const userFirestore = await getDataUser();

      if (userFirestore?.storeName) {
        await ProductService.saveProductToAlgolia(
          {
            id: iD,
            storeName: userFirestore?.storeName,
            ...item,
          },
          () => {},
          erroFunction
        );
      } else {
        await ProductService.saveProductToAlgolia(
          {
            id: iD,
            ...item,
          },
          () => {},
          erroFunction
        );
      }
    }
  },
  remove: async (id, then, erro, item) => {
    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("products")
      .doc(id)
      .delete()
      .catch(erro);

    if (item?.image !== null) {
      storage.refFromURL(item?.image).delete();
    }

    then();

    await StoreService.removeItemToCatalog(id, "products");
    ProductService.removeProductFromAlgolia({
      id: id,
      ...item,
    });
  },
};

// client

const ClientFirestore = {
  add: (id, item, then, erro) => {
    const gerateId = Math.random().toString(16).slice(2);

    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("clients")
      .doc(id ? id : gerateId)
      .set(item)
      .catch(erro);
    then();
  },
  remove: (id, then, erro) => {
    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("clients")
      .doc(id)
      .delete()
      .catch(erro);
    then();
  },
};

// service

const ServicesFirestore = {
  add: (id, item, then, erro) => {
    const gerateId = Math.random().toString(16).slice(2);

    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("services")
      .doc(id ? id : gerateId)
      .set(item)
      .catch(erro);
    then();
  },
  remove: (id, then, erro) => {
    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("services")
      .doc(id)
      .delete()
      .catch(erro);
    then();
  },
};

// discount

const DiscountsFirestore = {
  add: (id, item, then, erro) => {
    const gerateId = Math.random().toString(16).slice(2);

    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("discounts")
      .doc(id ? id : gerateId)
      .set(item)
      .catch(erro);
    then();
  },
  remove: (id, then, erro) => {
    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("discounts")
      .doc(id)
      .delete()
      .catch(erro);
    then();
  },
};

// categories

export const saveCategorie = (id, item, then, erro) => {
  if (item?.name !== "") {
    if (item?.id) {
      CategoriesFirestore.add(id, item, then, erro);
    } else {
      alert("Não é possivel editar essa categoria!");
    }
  } else {
    alert("Preencha o nome da categoria!");
  }
};
export const removeCategorie = (id, then, erro) => {
  CategoriesFirestore.remove(id, then, erro);
};

const CategoriesFirestore = {
  add: async (id, item, then, erro) => {
    const refcategorie = db
      .collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("categories");

    refcategorie.doc(id).set({ id: id, name: item?.name }).catch(erro);

    then();

    await StoreService.addItemToCatalog(item, id, "categories");
  },
  remove: async (id, then, erro) => {
    db.collection("users")
      .doc(auth?.currentUser?.uid)
      .collection("categories")
      .doc(id)
      .delete()
      .catch(erro);

    then();

    await StoreService.removeItemToCatalog(id, "categories");
  },
};

// user

export const UserService = {
  hasStore: async () => {
    const userFirestore = await getDataUser();
    return userFirestore?.storeName;
  },
};

export const removeAccount = async () => {
  await auth.currentUser.delete();
};

export const getDataUser = async () => {
  let user = [];
  await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .get()
    .then((data) => {
      user.push(data?.data());
    });
  return user[0];
};

export const userId = () => {
  return auth?.currentUser?.uid;
};

export const planIsMobile = async () => {
  let t = [];
  await db
    .collection("transactionsGooglePlay")
    .where("userId", "==", auth?.currentUser?.uid)
    .get()
    .then((data) => {
      data.docs.map((data) => {
        t.push(data);
      });
    });
  return t;
};

export const updateUser = async (update) => {
  await db
    .collection("users")
    .doc(userId())
    .update({ ...update, updatedAt: updatedAt });
};

// updatedAt

export const updatedAt = firebase.firestore.FieldValue.serverTimestamp();

// SAVE MOEDA and GET MOEDA

export const saveMoeda = async (moeda) => {
  await db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .update({
      preference: {
        currencyId: moeda?.currencyId,
        currencySymbol: moeda?.currencySymbol,
      },
      updatedAt,
    });
};

export const getMoeda = async () => {
  const user = await getDataUser();

  if (user?.preference) return user?.preference;
  else return initialState?.activeMoeda;
};

//
// saleValues

export const calculeDiscont = (discount, total, quantity) => {
  let totalDiscontDATA = 0;
  const discontoDATA = discount;
  const totalDATA = total;
  const totalDiscont = quantity;

  if (discontoDATA) {
    if (discontoDATA?.type === 0) {
      totalDiscontDATA += totalDATA - totalDATA * totalDiscont;
    } else {
      totalDiscontDATA += totalDATA - totalDiscont;
    }
  } else {
    if (!isNaN(totalDATA)) {
      totalDiscontDATA += totalDATA;
    }
  }

  return totalDiscontDATA;
};

export const calculeFaturamentoLiquidoDiscont = (discount, gain, quantity) => {
  let totalDiscontDATA = 0;
  const discontoDATA = discount;
  const totalGainDATA = gain;
  const totalDiscont = quantity;

  if (discontoDATA) {
    if (discontoDATA?.type === 0) {
      totalDiscontDATA += totalGainDATA - totalGainDATA * totalDiscont;
    } else {
      totalDiscontDATA += totalGainDATA - totalDiscont;
    }
  } else {
    if (!isNaN(totalGainDATA)) {
      totalDiscontDATA += totalGainDATA;
    }
  }

  return totalDiscontDATA;
};

export const ParcelaEmAtraso = (parcela) => {
  if (parcela && parcela?.payday) {
    let date = parcela?.payday?.toDate();
    if (
      `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` ===
      `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}`
    ) {
      return false;
    } else {
      return (
        new Date(date) < new Date() &&
        parcela?.status === initialState.SaleFirestore.NotPaid
      );
    }
  }
};

export function getExtension(filename) {
  return filename.split(".").pop();
}
