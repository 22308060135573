import React, { useState } from "react";
import { saveService } from "../../../providers/reducer";
import { useStateValue } from "../../../providers/StateProvider";
import { analyticsEvent } from "../../../services/analytics";
import InputForm from "../../inputs/InputForm";
import ModalNewCategorie from "../Categorie/ModalNewCategorie";
import SwitchOnCatalog from "../../Switch/SwitchOnCatalog";
import toast from "react-hot-toast";
import LoadingInButton from "../../Loading/LoadingInButton";
import UploadPhotos from "../../Photos/UploadPhotos";

function ModalEditService({
  setSelectsUsers,
  setModalSearchAdd,
  selectsUsers,
  setSelectsProducts,
  selectsProducts,
  addproductsale,
  setaddproductsale,
  setSelectsServices,
  selectsServices,
  addItemSelects,
  typeModal,
  modal,
  setmodal,
  filter,
  newcadastro,
  selectCliente,
  setSelectCliente,
  gerateId,
  updateProducts,
  ...props
}) {
  const [{ activeMoeda, activeLanguage, ifPlusVerified }, dispatch] =
    useStateValue();

  // STRINGS TYPES

  const strProductLong = activeLanguage?.services?.product?.title;
  const strClientLong = activeLanguage?.services?.client?.title;
  const strServiceLong = activeLanguage?.services?.service?.title;
  const strDiscountLong = activeLanguage?.services?.discount?.title;

  //

  // const [photo, setPhoto] = useState(null);
  const [photos, setPhotos] = useState(["", "", ""]);
  // const [uploadProductImage, setUploadProductImage] = useState(null);
  const [uploadProductImages, setUploadProductImages] = useState(["", "", ""]);
  const [loading, setLoading] = useState(false);

  const iD = gerateId;

  const changeUploadImgs = (e, upload, setUpload, id) => {
    if (e.target.files?.length === 0) {
      return;
    }

    setUpload(upload.map((file, i) => (i === id ? e.target.files[0] : file)));
    setPhotos(
      photos.map((image, i) =>
        i === id ? URL.createObjectURL(e.target.files[0]) : image
      )
    );
  };

  const checkMinWidth =
    filter === strClientLong ||
    filter === strServiceLong ||
    filter === strDiscountLong;

  const [modalSelectCategorie, setModalSelectCategorie] = useState(false);

  const apliqueCategorie = (selectCategories) => {
    props.setSelectCategories(selectCategories);
  };

  return (
    <div
      style={{
        zIndex: 9999999,
        opacity: modal ? "5" : "0",
        visibility: modal ? "visible" : "hidden",
      }}
      className="myne-modal"
    >
      <ModalNewCategorie
        modal={modalSelectCategorie}
        setmodal={setmodal}
        setmodalback={setModalSelectCategorie}
        aplicar={apliqueCategorie}
        backIcon
      />
      <div
        style={{
          transform: modal ? "scale(1)" : "scale(0.7)",
          width: checkMinWidth ? 390 : "",
          height: filter === strProductLong ? 600 : checkMinWidth ? 521 : "",
        }}
        className="myne-modal__modal-edit-cadastro"
      >
        {modal ? (
          <>
            <header className="myne-modal__modal-edit-header myne-modal--animation">
              <section className="myne-modal__modal-edit-header__left">
                {filter === strProductLong ? (
                  <IconProduto />
                ) : filter === strClientLong ? (
                  <IconClientes />
                ) : filter === strServiceLong ? (
                  <IconServicos />
                ) : (
                  <IconDescontos />
                )}
                <p>
                  {typeModal} {filter?.substring(0, filter?.length - 1)}
                </p>
              </section>

              <section className="myne-modal__modal-edit-header__icons">
                <IconClose onClick={() => setmodal(!modal)} />
              </section>
            </header>

            <div className="myne-modal__modal-edit-cadastro-content myne-modal--animation">
              <div
                style={{
                  width: checkMinWidth ? "100%" : "",
                  flex: checkMinWidth ? 1 : "",
                }}
                className="myne-modal__modal-edit-cadastro-content__inputs"
              >
                {filter === strProductLong ? (
                  <>
                    <InputForm
                      value={props.name}
                      onChange={(e) => props.setName(e.target.value)}
                      label={`${activeLanguage?.services?.inputs?.name}*`}
                    />
                    <InputForm
                      value={props.stock}
                      onChange={(e) => props.setStock(e.target.value)}
                      label={activeLanguage?.services?.inputs?.qty}
                      type="number"
                    />
                    <InputForm
                      value={props.costPrice}
                      onChange={(e) => props.setCostPrice(e.target.value)}
                      label={activeLanguage?.services?.inputs?.price_cost}
                      type="number"
                    />
                    <InputForm
                      value={props.salePrice}
                      onChange={(e) => props.setSalePrice(e.target.value)}
                      label={`${activeLanguage?.services?.inputs?.price_sale}*`}
                      type="number"
                    />
                    <InputForm
                      value={props.selectCategories?.map(
                        (categorie, i) =>
                          `${i !== 0 ? " " : ""}` + categorie?.name
                      )}
                      label={`Categoria`}
                      expandMore
                      readOnly={props.selectCategories?.length > 0}
                      onChange={() => {}}
                      onClickExpandMore={() => setModalSelectCategorie(true)}
                    />
                    <InputForm
                      value={props.description}
                      onChange={(e) => props.setDescription(e.target.value)}
                      label={activeLanguage?.services?.inputs?.description}
                    />
                  </>
                ) : filter === strClientLong ? (
                  <>
                    <InputForm
                      value={props.nomeCliente}
                      onChange={(e) => props.setNomeCliente(e.target.value)}
                      label={`${activeLanguage?.services?.inputs?.name}*`}
                    />
                    <InputForm
                      value={props.foneCliente}
                      onChange={(e) =>
                        props.setFoneCliente(props.phoneMask(e.target.value))
                      }
                      label={activeLanguage?.services?.inputs?.phone}
                      type="text"
                    />
                    <InputForm
                      value={props.emailCliente}
                      onChange={(e) => props.setEmailCliente(e.target.value)}
                      label={activeLanguage?.services?.inputs?.email}
                    />
                    <InputForm
                      value={props.commentsCliente}
                      onChange={(e) => props.setCommentsCliente(e.target.value)}
                      label={activeLanguage?.services?.inputs?.comment}
                    />
                  </>
                ) : filter === strServiceLong ? (
                  <>
                    <InputForm
                      value={props.name}
                      onChange={(e) => props.setName(e.target.value)}
                      label={`${activeLanguage?.services?.inputs?.name}*`}
                    />
                    <InputForm
                      value={props.costPrice}
                      onChange={(e) => props.setCostPrice(e.target.value)}
                      label={activeLanguage?.services?.inputs?.price_cost}
                      type="number"
                    />
                    <InputForm
                      value={props.salePrice}
                      onChange={(e) => props.setSalePrice(e.target.value)}
                      label={`${activeLanguage?.services?.inputs?.price_sale}*`}
                      type="number"
                    />
                    <InputForm
                      value={props.description}
                      onChange={(e) => props.setDescription(e.target.value)}
                      label={activeLanguage?.services?.inputs?.description}
                    />
                  </>
                ) : (
                  <>
                    <p className="myne-modal__modal-edit-cadastro-content__select-p">
                      {activeLanguage?.services?.discount?.modal_add?.type}
                    </p>

                    <div className="myne-modal__modal-edit-cadastro-content__select">
                      <button
                        style={{
                          fontWeight: props.type === 1 ? 500 : "",
                          border:
                            props.type === 1
                              ? "1px solid rgb(170, 0, 255)"
                              : "",
                          color: props.type === 1 ? "rgb(170, 0, 255)" : "",
                        }}
                        onClick={() => props.setType(1)}
                      >
                        <IconMoney
                          fill={
                            props.type === 1
                              ? "rgb(170, 0, 255)"
                              : "rgba(0, 0, 0, 0.87)"
                          }
                        />
                        {activeLanguage?.services?.discount?.modal_add?.money}
                      </button>
                      <button
                        style={{
                          fontWeight: props.type === 0 ? 500 : "",
                          border:
                            props.type === 0
                              ? "1px solid rgb(170, 0, 255)"
                              : "",
                          color: props.type === 0 ? "rgb(170, 0, 255)" : "",
                        }}
                        onClick={() => props.setType(0)}
                      >
                        <IconPercentage
                          fill={
                            props.type === 0
                              ? "rgb(170, 0, 255)"
                              : "rgba(0, 0, 0, 0.87)"
                          }
                        />{" "}
                        {activeLanguage?.services?.discount?.modal_add?.porcent}
                      </button>
                    </div>

                    <InputForm
                      marginTop={-5}
                      value={props.name}
                      onChange={(e) => props.setName(e.target.value)}
                      label={`${activeLanguage?.services?.discount?.modal_add?.input_name}*`}
                    />

                    {props.type === 1 ? (
                      <InputForm
                        value={props.quantity}
                        onChange={(e) => props.setQuantity(e.target.value)}
                        label={`${activeLanguage?.services?.discount?.modal_add?.value_discount} (${activeMoeda?.currencySymbol})*`}
                      />
                    ) : (
                      <InputForm
                        value={props.quantity}
                        onChange={(e) => props.setQuantity(e.target.value)}
                        label={`${activeLanguage?.services?.discount?.modal_add?.value_discount} (%)*`}
                      />
                    )}
                  </>
                )}
              </div>

              <div
                style={{
                  border: checkMinWidth ? "initial" : "",
                  width: checkMinWidth ? "initial" : "",
                  flex: checkMinWidth ? "initial" : "",
                  padding: checkMinWidth ? 0 : "",
                }}
                className="myne-modal__modal-edit-cadastro-content__right-subimit"
              >
                {filter !== strDiscountLong ? (
                  <>
                    {filter === strProductLong ? (
                      <section className="myne-modal__modal-edit-cadastro-content__right-subimit__stock-min">
                        <p>
                          {
                            activeLanguage?.services?.product?.modal_add
                              ?.stock_min_value
                          }
                        </p>
                        <div>
                          {[...new Set(props.arrayMinStock)]?.map((item, i) => (
                            <button
                              key={item + i}
                              onClick={() =>
                                item ===
                                activeLanguage?.services?.product?.modal_add
                                  ?.other_stock
                                  ? props.setModalAddMinimumStock(true)
                                  : props.setMinimumStockSelect(item)
                              }
                              style={{
                                background:
                                  item === props.minimumStockSelect
                                    ? "#AA00FF"
                                    : "",
                                color:
                                  item === props.minimumStockSelect
                                    ? "#fff"
                                    : "",
                              }}
                            >
                              {item}
                            </button>
                          ))}
                          <button
                            onClick={() => props.setModalAddMinimumStock(true)}
                          >
                            {
                              activeLanguage?.services?.product?.modal_add
                                ?.other_stock
                            }
                          </button>
                        </div>
                      </section>
                    ) : (
                      ""
                    )}
                    {filter === strProductLong ? (
                      <>
                        <UploadPhotos id={iD} photos={photos} />
                        <SwitchOnCatalog
                          setOnCatalog={props.setOnCatalog}
                          onCatalog={props.onCatalog}
                          item={props.item}
                          iD={iD}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                <input
                  onChange={(e) =>
                    changeUploadImgs(
                      e,
                      uploadProductImages,
                      setUploadProductImages,
                      0
                    )
                  }
                  style={{ display: "none" }}
                  type="file"
                  id={`upload-product-add-image-${iD}-0`}
                />
                {ifPlusVerified ? (
                  <>
                    <input
                      onChange={(e) =>
                        changeUploadImgs(
                          e,
                          uploadProductImages,
                          setUploadProductImages,
                          1
                        )
                      }
                      style={{ display: "none" }}
                      type="file"
                      id={`upload-product-add-image-${iD}-1`}
                    />
                    <input
                      onChange={(e) =>
                        changeUploadImgs(
                          e,
                          uploadProductImages,
                          setUploadProductImages,
                          2
                        )
                      }
                      style={{ display: "none" }}
                      type="file"
                      id={`upload-product-add-image-${iD}-2`}
                    />
                  </>
                ) : (
                  ""
                )}

                <button
                  onClick={() => {
                    // bug phone
                    let item = null;
                    if (filter === strClientLong) {
                      const returna = (item) => {
                        return !item ? "" : item;
                      };
                      item = {
                        ...props.item,
                        phone: returna(props.item.phone),
                      };
                    } else {
                      item = null;
                    }

                    saveService(
                      filter,
                      item ? item : props.item,
                      gerateId,
                      (then) => {
                        setmodal(true);
                        props.setName("");
                        props.setStock("");
                        props.setCostPrice("");
                        props.setSalePrice("");
                        props.setDescription("");
                        props.setMinimumStock(0);
                        props.setMinimumStockSelect(undefined);
                        props.setQuantity("");
                        props.setType(1);
                        props.setNomeCliente("");
                        props.setFoneCliente("");
                        props.setEmailCliente("");
                        props.setCommentsCliente("");
                        props.setSelectCategories([]);
                        setUploadProductImages(["", "", ""]);
                        setPhotos(["", "", ""]);
                        setmodal(false);

                        if (
                          typeModal === activeLanguage?.services?.modal_new &&
                          filter === strClientLong &&
                          newcadastro
                        ) {
                          setSelectCliente({ ...props.item, id: gerateId });
                        }

                        // dispatch({
                        //   type: "SET_MESSAGES",
                        //   messages: {
                        //     messageSucesseEdit: false,
                        //     messageSucesseAdd: true,
                        //     messageSucesseRemove: false,
                        //     messageErro: false,
                        //     type: filter,
                        //   },
                        // });
                        toast.success(
                          `${
                            activeLanguage?.alerts?.save_service?.alert
                          }${filter?.substring(0, filter?.length - 1)}${" "}${
                            activeLanguage?.alerts?.save_service?.no_plural
                          }${" "}${activeLanguage?.alerts?.save_service?.p2}`
                        );

                        if (addItemSelects) {
                          if (filter === strClientLong) {
                            addItemSelects(
                              { data: props.item, id: gerateId },
                              setSelectsUsers,
                              selectsUsers,
                              true
                            );
                            setModalSearchAdd(null);
                          } else if (filter === strProductLong) {
                            setaddproductsale({
                              type: "add",
                              item: { data: props.item, id: gerateId },
                            });
                            setModalSearchAdd(null);
                          } else if (filter === strServiceLong) {
                            addItemSelects(
                              { data: props.item, id: gerateId },
                              setSelectsServices,
                              selectsServices
                            );
                            setModalSearchAdd(null);
                          }
                        }

                        analyticsEvent(
                          `ADD_${filter
                            ?.substring(0, filter?.length - 1)
                            ?.toLowerCase()}`
                        );
                        if (filter === strProductLong) {
                          if (updateProducts) {
                            updateProducts();
                          }
                        }
                      },
                      (erro) => {
                        toast.error(activeLanguage?.alerts?.erro);
                        // dispatch({
                        //   type: "SET_MESSAGES",
                        //   messages: {
                        //     messageSucesseEdit: false,
                        //     messageSucesseAdd: false,
                        //     messageSucesseRemove: false,
                        //     messageErro: true,
                        //     type: filter,
                        //   },
                        // });
                      },
                      uploadProductImages,
                      setLoading,
                      false,
                      activeLanguage,
                      props?.onCatalog
                    );
                  }}
                >
                  {loading ? (
                    <LoadingInButton />
                  ) : (
                    <>
                      <IconSave />

                      <p>
                        {activeLanguage?.services?.product?.modal_add?.save_btn}
                      </p>
                    </>
                  )}
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ModalEditService;

const IconMoney = ({ fill }) => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3438 0.15625H1.65625C0.9375 0.15625 0.34375 0.75 0.34375 1.5V9.5C0.34375 10.25 0.9375 10.8438 1.65625 10.8438H12.3438C13.0625 10.8438 13.6562 10.25 13.6562 9.5V1.5C13.6562 0.75 13.0625 0.15625 12.3438 0.15625ZM12.3438 8.84375C12.3438 9.1875 12.0312 9.5 11.6562 9.5H2.34375C1.96875 9.5 1.65625 9.1875 1.65625 8.84375V2.15625C1.65625 1.8125 1.96875 1.5 2.34375 1.5H11.6562C12.0312 1.5 12.3438 1.8125 12.3438 2.15625V8.84375ZM8.34375 4.15625C8.6875 4.15625 9 3.875 9 3.5C9 3.125 8.6875 2.84375 8.34375 2.84375H7.65625V2.8125C7.65625 2.46875 7.375 2.15625 7 2.15625C6.625 2.15625 6.34375 2.46875 6.34375 2.8125V2.84375H5.65625C5.3125 2.84375 5 3.125 5 3.5V5.5C5 5.875 5.3125 6.15625 5.65625 6.15625H7.65625V6.84375H5.65625C5.3125 6.84375 5 7.125 5 7.5C5 7.875 5.3125 8.15625 5.65625 8.15625H6.34375C6.34375 8.53125 6.625 8.84375 7 8.84375C7.375 8.84375 7.65625 8.53125 7.65625 8.15625H8.34375C8.6875 8.15625 9 7.875 9 7.5V5.5C9 5.125 8.6875 4.84375 8.34375 4.84375H6.34375V4.15625H8.34375Z"
      style={{ fill: fill }}
    />
  </svg>
);

const IconPercentage = ({ fill }) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66868 0.498675L0.999345 9.16868C0.822888 9.34513 0.723755 9.58446 0.723755 9.83401C0.723755 10.0836 0.822888 10.3229 0.999345 10.4993C1.1758 10.6758 1.41513 10.7749 1.66468 10.7749C1.91423 10.7749 2.15355 10.6758 2.33001 10.4993L10.9993 1.83001C11.1703 1.65241 11.2648 1.41483 11.2625 1.16832C11.2602 0.921814 11.1613 0.686047 10.987 0.511686C10.8127 0.337326 10.577 0.238288 10.3305 0.235855C10.084 0.233422 9.84636 0.327788 9.66868 0.498675ZM2.66801 0.833342C2.49292 0.833167 2.3195 0.867481 2.15767 0.934325C1.99583 1.00117 1.84875 1.09924 1.72481 1.22292C1.60088 1.34661 1.50252 1.4935 1.43535 1.6552C1.36818 1.8169 1.33352 1.99025 1.33335 2.16534C1.33317 2.34044 1.36748 2.51385 1.43433 2.67569C1.50117 2.83752 1.59924 2.98461 1.72293 3.10854C1.84661 3.23248 1.9935 3.33084 2.1552 3.398C2.3169 3.46517 2.49025 3.49983 2.66535 3.50001C3.01897 3.50036 3.35825 3.36023 3.60854 3.11043C3.85884 2.86063 3.99966 2.52163 4.00001 2.16801C4.00037 1.81439 3.86023 1.47511 3.61043 1.22481C3.36063 0.97451 3.02163 0.833695 2.66801 0.833342ZM9.33468 7.50001C8.98106 7.49965 8.64178 7.63979 8.39148 7.88959C8.14118 8.13939 8.00037 8.47839 8.00001 8.83201C7.99966 9.18563 8.1398 9.52491 8.38959 9.77521C8.63939 10.0255 8.97839 10.1663 9.33201 10.1667C9.50711 10.1669 9.68052 10.1325 9.84236 10.0657C10.0042 9.99885 10.1513 9.90078 10.2752 9.77709C10.3991 9.65341 10.4975 9.50652 10.5647 9.34482C10.6318 9.18312 10.6665 9.00977 10.6667 8.83468C10.6669 8.65958 10.6325 8.48616 10.5657 8.32433C10.4989 8.16249 10.4008 8.01541 10.2771 7.89148C10.1534 7.76754 10.0065 7.66918 9.84482 7.60201C9.68312 7.53484 9.50978 7.50018 9.33468 7.50001Z"
      style={{ fill: fill }}
    />
  </svg>
);

const IconServicos = ({ className, style }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      style={{ ...style }}
      d="M8.0625 1.9375C6.71875 0.59375 4.71875 0.3125 3.09375 1.125L5.53125 3.53125C5.78125 3.78125 5.78125 4.21875 5.53125 4.46875L4.46875 5.53125C4.1875 5.8125 3.78125 5.8125 3.53125 5.53125L1.09375 3.125C0.3125 4.71875 0.59375 6.71875 1.9375 8.0625C3.1875 9.3125 5 9.625 6.53125 9.0625L11.8438 14.375C12.5312 15.0312 13.625 15.0312 14.3125 14.375C15 13.6875 15 12.5625 14.3125 11.875L9.03125 6.59375C9.625 5.03125 9.3125 3.1875 8.0625 1.9375Z"
      fill="#aa00ff"
      fillOpacity="1"
    />
  </svg>
);

const IconDescontos = ({ className, style }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      style={{ ...style }}
      d="M6.34375 2.59375C7.28125 2.59375 7.75 2.96875 7.9375 3.53125C8 3.8125 8.3125 4 8.59375 4H8.625C9.09375 4 9.46875 3.53125 9.28125 3.09375C9 2.3125 8.34375 1.71875 7.34375 1.46875V1C7.34375 0.4375 6.875 0 6.34375 0C5.78125 0 5.34375 0.4375 5.34375 1V1.4375C5.0625 1.5 4.84375 1.59375 4.59375 1.6875L5.59375 2.6875C5.8125 2.625 6.0625 2.59375 6.34375 2.59375ZM1.1875 1.09375C0.90625 1.34375 0.90625 1.75 1.1875 2.03125L3 3.84375C3 5.21875 4.03125 6 5.59375 6.46875L7.9375 8.78125C7.71875 9.125 7.25 9.40625 6.34375 9.40625C5.21875 9.40625 4.65625 9 4.4375 8.4375C4.34375 8.1875 4.125 8 3.84375 8H3.71875C3.25 8 2.90625 8.5 3.09375 8.9375C3.5 9.8125 4.375 10.3438 5.34375 10.5625V11C5.34375 11.5625 5.78125 12 6.34375 12C6.90625 12 7.34375 11.5625 7.34375 11V10.5625C7.96875 10.4375 8.5625 10.1875 8.96875 9.8125L10 10.8125C10.25 11.0938 10.6562 11.0938 10.9375 10.8125C11.1875 10.5625 11.1875 10.1562 10.9375 9.875L2.125 1.09375C1.875 0.8125 1.4375 0.8125 1.1875 1.09375Z"
      fill="#aa00ff"
      fillOpacity="1"
    />
  </svg>
);

const IconSave = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1836 0.707031C10.9023 0.390625 10.5156 0.25 10.1289 0.25H1.76172C0.917969 0.25 0.25 0.917969 0.25 1.76172V12.2383C0.25 13.082 0.917969 13.75 1.76172 13.75H12.2383C13.082 13.75 13.75 13.082 13.75 12.2383V3.87109C13.75 3.48438 13.6094 3.09766 13.293 2.81641L11.1836 0.707031ZM7 12.2383C5.76953 12.2383 4.75 11.2539 4.75 9.98828C4.75 8.75781 5.76953 7.73828 7 7.73828C8.23047 7.73828 9.25 8.75781 9.25 9.98828C9.25 11.2539 8.23047 12.2383 7 12.2383ZM7.73828 4.75H3.23828C2.42969 4.75 1.76172 4.08203 1.76172 3.23828C1.76172 2.42969 2.42969 1.76172 3.23828 1.76172H7.73828C8.58203 1.76172 9.25 2.42969 9.25 3.23828C9.25 4.08203 8.58203 4.75 7.73828 4.75Z"
      fill="white"
    />
  </svg>
);

const IconClose = ({ ...rest }) => (
  <svg
    width="20"
    {...rest}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.92188 2.96875C4.89062 1 7.25 0.015625 10 0.015625C12.75 0.015625 15.0938 1 17.0312 2.96875C19 4.90625 19.9844 7.25 19.9844 10C19.9844 12.75 19 15.1094 17.0312 17.0781C15.0938 19.0156 12.75 19.9844 10 19.9844C7.25 19.9844 4.89062 19.0156 2.92188 17.0781C0.984375 15.1094 0.015625 12.75 0.015625 10C0.015625 7.25 0.984375 4.90625 2.92188 2.96875ZM14.3125 14.3125C14.75 13.8125 14.75 13.3438 14.3125 12.9062L11.4062 10L14.3125 7.09375C14.75 6.65625 14.75 6.1875 14.3125 5.6875C13.8125 5.25 13.3438 5.25 12.9062 5.6875L10 8.59375L7.09375 5.6875C6.65625 5.25 6.1875 5.25 5.6875 5.6875C5.25 6.1875 5.25 6.65625 5.6875 7.09375L8.59375 10L5.6875 12.9062C5.25 13.3438 5.25 13.8125 5.6875 14.3125C6.1875 14.75 6.65625 14.75 7.09375 14.3125L10 11.4062L12.9062 14.3125C13.3438 14.75 13.8125 14.75 14.3125 14.3125Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
);

const IconProduto = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 14.5001C18.0005 14.6812 17.9514 14.859 17.8579 15.0141C17.7645 15.1693 17.6303 15.2959 17.47 15.3801L9.57 19.8201C9.40304 19.9373 9.204 20.0002 9 20.0002C8.796 20.0002 8.59696 19.9373 8.43 19.8201L0.530004 15.3801C0.369683 15.2959 0.235516 15.1693 0.142077 15.0141C0.048638 14.859 -0.00049917 14.6812 3.82324e-06 14.5001V5.50011C-0.00049917 5.319 0.048638 5.14122 0.142077 4.98608C0.235516 4.83093 0.369683 4.70436 0.530004 4.62011L8.43 0.180111C8.59696 0.0628934 8.796 0 9 0C9.204 0 9.40304 0.0628934 9.57 0.180111L17.47 4.62011C17.6303 4.70436 17.7645 4.83093 17.8579 4.98608C17.9514 5.14122 18.0005 5.319 18 5.50011V14.5001ZM9 2.15011L7.11 3.22011L13 6.61011L14.96 5.50011L9 2.15011ZM3.04 5.50011L9 8.85011L10.96 7.75011L5.08 4.35011L3.04 5.50011ZM2 13.9101L8 17.2901V10.5801L2 7.21011V13.9101ZM16 13.9101V7.21011L10 10.5801V17.2901L16 13.9101Z"
      fill="#AA00FF"
    />
  </svg>
);

const IconClientes = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 12C11.8359 12 13.3203 10.5156 13.3203 8.67969C13.3203 6.84375 11.8359 5.32031 10 5.32031C8.16406 5.32031 6.67969 6.84375 6.67969 8.67969C6.67969 10.5156 8.16406 12 10 12ZM10 13.6797C7.77344 13.6797 3.32031 14.7734 3.32031 17V17.8203C3.32031 18.2891 3.71094 18.6797 4.17969 18.6797H15.8203C16.2891 18.6797 16.6797 18.2891 16.6797 17.8203V17C16.6797 14.7734 12.2266 13.6797 10 13.6797Z"
      fill="#AA00FF"
    />
  </svg>
);
