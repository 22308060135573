import React, { useState, useEffect, useMemo } from "react";
import {
  ProductSaleService_LIMIT_FREE,
  ProductService,
  getClients,
  getDiscounts,
  getProducts,
  getServices,
  migrateCategoriesIfNeed,
  strAllCategoriesProducts,
  toLowerStr,
} from "../../../providers/reducer";
import { useStateValue } from "../../../providers/StateProvider";
import NULLproductos from "../../../assets/images/NULL/nullproducts.svg";
import NULLservices from "../../../assets/images/NULL/nullservices.svg";
import NULLuser from "../../User/svgnull.svg";
import { IconAdd } from "../../../assets/icons/icons";
import Categories from "../../Filters/FilterCategories";
import ModalPlus from "../Plus/ModalPlus";
import { auth, db } from "../../../services/Firebase";
import { formatPrice } from "../../../shared/utils/helpers/format";
import ProductSlidePhotos from "../../Photos/ProductSlidePhotos";

function ModalSearchAdd({
  modalNew,
  setModalNew,
  modal,
  setmodal,
  ref1,
  ref2,
  ref3,
  ref4,
  getTotal,
  setSelectsUsers,
  setSelectsServices,
  setSelectsDiscounts,
  checkAddedSelects,
  addItemSelects,
  removeItemSelects,
  selectsUsers,
  selectsServices,
  selectsDiscounts,
  addproductsale,
  setaddproductsale,
}) {
  const [valueSearch, setValueSearch] = useState("");
  const currentId =
    modal === 1
      ? `myne-modal__modal-new-sale__client`
      : modal === 2
        ? `myne-modal__modal-new-sale__product`
        : modal === 3
          ? `myne-modal__modal-new-sale__service`
          : `myne-modal__modal-new-sale__discount`;

  const [{ user, activeMoeda, activeLanguage, ifPlusVerified }] =
    useStateValue();

  const [currentItens, setCurrentItens] = useState([]);
  const [loading, setLoading] = useState(false);
  const collectionReference = db
    .collection("users")
    .doc(auth?.currentUser?.uid)
    .collection("products");

  const [selectCategories, setSelectCategories] = useState([
    strAllCategoriesProducts,
  ]);

  useEffect(() => {
    switch (modal) {
      case 1:
        getClients(user, setCurrentItens, setLoading);
        break;
      case 2:
        setLoading(true);
        async function listProductByCategorySingle() {
          if (selectCategories[0] === strAllCategoriesProducts) {
            getProducts(user, setCurrentItens, setLoading);
          } else {
            const data = await ProductService?.listProductByCategorySingle(
              selectCategories,
              collectionReference.orderBy("name")
            );
            const products = [];

            data?.products?.map((product) => {
              products.push(product);
            });
            setCurrentItens(products);
            setLoading(false);
            if (products?.length <= 0) {
              setLoading("not");
            }
          }
        }
        listProductByCategorySingle();

        break;
      case 3:
        getServices(user, setCurrentItens, setLoading);
        break;
      case 4:
        getDiscounts(user, setCurrentItens, setLoading);
        break;

      default:
        break;
    }
  }, [user, modal, selectCategories]);

  const sortCurrentItensAZ = useMemo(() => {
    const filter = currentItens.filter((item) => {
      if (item?.data?.id) {
        return toLowerStr(item?.data?.data?.name)?.includes(valueSearch?.toLowerCase());
      } else {
        return toLowerStr(item?.data?.name)?.includes(valueSearch?.toLowerCase());
      }
    });
    if (filter.length <= 0) {
      setLoading("not");
    } else {
      setLoading("");
    }
    return filter?.sort((x, y) => {
      let a = toLowerStr(x?.data?.name);
      let b = toLowerStr(y?.data?.name);

      return a?.localeCompare(b);
    });
  }, [currentItens, valueSearch]);

  // STRINGS TYPES

  const strProduct = activeLanguage?.services?.product?.title_min;
  const strClient = activeLanguage?.services?.client?.title_min;
  const strService = activeLanguage?.services?.service?.title_min;
  const strDiscount = activeLanguage?.services?.discount?.title_min;

  const type =
    modal === 1
      ? strClient?.toLowerCase()
      : modal === 2
        ? strProduct?.toLowerCase()
        : modal === 3
          ? strService?.toLowerCase()
          : strDiscount?.toLowerCase();

  // old filter
  // const filterCategories = useMemo(() => {
  //   if (modal === 2) {
  //     if (selectCategories[0] === strAllCategoriesProducts) {
  //       return sortCurrentItensAZ;
  //     } else {
  //       return sortCurrentItensAZ?.filter((product) => {
  //         // --> some
  //         return product?.data?.categories?.some((cate) => {
  //           return selectCategories?.includes(cate?.name);
  //         });
  //       });
  //     }
  //   } else {
  //     return sortCurrentItensAZ;
  //   }
  // }, [sortCurrentItensAZ, selectCategories, modal]);

  useEffect(() => {
    if (currentItens?.length !== 0 && modal === 2) {
      async function updateCategoriesProductsForFilter() {
        await migrateCategoriesIfNeed();
      }
      updateCategoriesProductsForFilter();
    }
  }, [currentItens, modal]);

  const [checkModal, setCheckModal] = useState(false);
  const [modalPlus, setModalPlus] = useState(false);

  return (
    <>
      <ModalPlus
        modal={modalPlus}
        setmodal={setModalPlus}
        message_locked={modalPlus}
      />

      <section
        ref={modal === 1 ? ref1 : modal === 2 ? ref2 : ref3}
        className="myne-modal__modal-new-sale-content__area-modal-search"
        style={{
          opacity: modal ? 1 : 0,
          visibility: modal ? "visible" : "hidden",
          transform: modal ? "scale(1)" : "scale(0)",
          left: `${document.getElementById(currentId)?.offsetLeft - 55}px`,
          top: `${document.getElementById(currentId)?.offsetTop + 21}px`,
          width: checkModal ? 611 : "",
          height: checkModal ? 479 : "",
        }}
      >
        {modal ? (
          <>
            <section className="myne-modal__modal-new-sale-content__area-modal-search-area__seach">
              <div className="myne-modal__modal-new-sale-content__area-modal-search__input">
                <input
                  placeholder={
                    activeLanguage?.modals?.new_sale?.details?.modals_btns
                      ?.input
                  }
                  value={valueSearch}
                  type="text"
                  onChange={(e) => setValueSearch(e.target.value)}
                />
                <IconSearch />
              </div>
            </section>

            <div
              style={{ height: 286 }}
              className="myne-modal__modal-new-sale-content__area-modal-search__itens"
            >
              <div
                onClick={() => {
                  if (modal === 2) {
                    if (
                      !ifPlusVerified &&
                      currentItens?.length >= ProductSaleService_LIMIT_FREE
                    ) {
                      setModalPlus(activeLanguage?.be_plus?.limit_products);
                    } else {
                      setModalNew(!modalNew);
                    }
                  } else {
                    setModalNew(!modalNew);
                  }
                }}
                className="myne-modal__modal-new-sale-content__area-modal-search__itens-user"
              >
                <IconAdd />
                <p>
                  {
                    activeLanguage?.modals?.new_sale?.details?.modals_btns
                      ?.title_type
                  }{" "}
                  {type}
                </p>
              </div>
              {modal === 2 ? (
                <Categories
                  padding="0"
                  setLoading={setLoading}
                  selectCategories={selectCategories}
                  setSelectCategories={setSelectCategories}
                  setCheckModal={setCheckModal}
                />
              ) : (
                ""
              )}
              {loading === "not" ? (
                <p
                  style={{ textAlign: "center", width: 312 }}
                  className="myne-text"
                >
                  {
                    activeLanguage?.modals?.new_sale?.details?.modals_btns
                      ?.no_itens?.p1
                  }{" "}
                  {type}s{" "}
                  {
                    activeLanguage?.modals?.new_sale?.details?.modals_btns
                      ?.no_itens?.p2
                  }
                </p>
              ) : loading ? (
                <div className="myne-modal__modal-new-sale-content-loading">
                  <div className="myne-circle-loading-3"></div>
                </div>
              ) : (
                sortCurrentItensAZ?.map((item, i) => {
                  const itemSelect = item?.data?.id ? item?.data : item;
                  const itemData = item?.data?.id ? item?.data?.data : item?.data;
                  return (
                    <div
                      key={itemSelect?.id}
                      onClick={() => {
                        if (modal === 1) {
                          addItemSelects(
                            itemSelect,
                            setSelectsUsers,
                            selectsUsers,
                            true
                          );
                        } else if (modal === 2) {
                          setaddproductsale({ type: "add", item: itemSelect });
                        } else if (modal === 3) {
                          addItemSelects(
                            itemSelect,
                            setSelectsServices,
                            selectsServices
                          );
                        } else if (modal === 4) {
                          if (itemData?.quantity > getTotal) {
                            setSelectsDiscounts(null);
                            alert(
                              activeLanguage?.modals?.new_sale?.details
                                ?.modals_btns?.discount_greater
                            );
                          } else {
                            setSelectsDiscounts(itemSelect);
                          }
                        }
                        setmodal(false);
                      }}
                      className="myne-modal__modal-new-sale-content__area-modal-search__itens-user"
                    >
                      {modal === 1 ? (
                        <>
                          <img
                            className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                            src={itemData?.image ? itemData?.image : NULLuser}
                            style={{ borderRadius: "100%" }}
                            alt=""
                          />
                          <p>{itemData?.name}</p>
                        </>
                      ) : (
                        <div className="myne-item__cadastro">
                          <div className="myne-item__cadastro-left">
                            {modal === 1 ? (
                              <img
                                className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                                src={
                                  itemData?.image ? itemData?.image : NULLuser
                                }
                                style={{ borderRadius: "100%" }}
                                alt=""
                              />
                            ) : modal === 2 ? (
                              // <img
                              //   className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                              //   src={
                              //     itemData?.image
                              //       ? itemData?.image
                              //       : NULLproductos
                              //   }
                              //   alt=""
                              // />
                              <ProductSlidePhotos className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img" photos={itemData?.images ?? itemData?.image} />
                            ) : modal === 3 ? (
                              <img
                                className="myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img"
                                style={{
                                  objectFit: itemData?.image ? "" : "contain",
                                }}
                                src={
                                  itemData?.image
                                    ? itemData?.image
                                    : NULLservices
                                }
                                alt=""
                              />
                            ) : (
                              <IconDesconto style={{ marginRight: 12 }} />
                            )}

                            <section>
                              <p>{itemData?.name}</p>
                              {itemData?.description ? (
                                <h5>{itemData?.description}</h5>
                              ) : (
                                ""
                              )}
                            </section>
                          </div>

                          <div className="myne-item__cadastro-right">
                            {type === strProduct?.toLowerCase() ? (
                              <>
                                <section>
                                  <p>
                                    {activeLanguage?.services?.product?.value}
                                  </p>
                                  <h5>
                                    {itemData?.salePrice
                                      ? formatPrice(
                                        parseFloat(itemData?.salePrice),
                                        activeMoeda
                                      )
                                      : ""}
                                  </h5>
                                </section>
                                <section>
                                  <p>
                                    {activeLanguage?.services?.product?.stock}
                                  </p>
                                  <h5
                                    style={{
                                      color:
                                        itemData?.stock ===
                                          itemData?.minimumStock
                                          ? "#FFBD06"
                                          : itemData?.stock >
                                            itemData?.minimumStock
                                            ? "#58CC02"
                                            : "#FE2543",
                                    }}
                                  >
                                    {itemData?.stock}
                                  </h5>
                                </section>
                              </>
                            ) : type ===
                              activeLanguage?.services?.client?.title_min?.toLowerCase() ? (
                              <>
                                {itemData?.phone ? (
                                  <section>
                                    <p>
                                      {
                                        activeLanguage?.services?.client
                                          ?.telephone
                                      }
                                    </p>
                                    <h5>{itemData?.phone}</h5>
                                  </section>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : type ===
                              activeLanguage?.services?.service?.title_min?.toLowerCase() ? (
                              <>
                                {itemData?.salePrice ? (
                                  <section>
                                    <p>
                                      {activeLanguage?.services?.service?.value}
                                    </p>
                                    <h5>
                                      {itemData?.salePrice
                                        ? formatPrice(
                                          parseFloat(itemData?.salePrice),
                                          activeMoeda
                                        )
                                        : ""}
                                    </h5>
                                  </section>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                <section>
                                  <p>{activeLanguage?.services?.discount?.qty}</p>
                                  <h5>
                                    {itemData?.type === 1
                                      ? `${itemData?.quantity?.toLocaleString(
                                        "pt-br",
                                        {
                                          style: "currency",
                                          currency: activeMoeda?.currencyId,
                                        }
                                      )}`
                                      : `${parseFloat(itemData?.quantity) * 100
                                      }%`}
                                  </h5>
                                </section>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </section>
    </>
  );
}

export default ModalSearchAdd;

const IconSearch = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5156 12.0156H12.7188L12.4375 11.7344C13.6094 10.3281 14.2656 8.40625 13.8906 6.39062C13.4219 3.625 11.125 1.375 8.3125 1.04688C4.09375 0.53125 0.53125 4.09375 1.04688 8.3125C1.375 11.125 3.625 13.4219 6.39062 13.8906C8.40625 14.2656 10.3281 13.6094 11.7344 12.4375L12.0156 12.7188V13.5156L16.2344 17.7344C16.6562 18.1562 17.3125 18.1562 17.7344 17.7344C18.1562 17.3594 18.1562 16.6562 17.7344 16.2812L13.5156 12.0156ZM7.51562 12.0156C5.03125 12.0156 3.01562 10 3.01562 7.51562C3.01562 5.03125 5.03125 3.01562 7.51562 3.01562C10 3.01562 12.0156 5.03125 12.0156 7.51562C12.0156 10 10 12.0156 7.51562 12.0156Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);

const IconDesconto = ({ ...rest }) => (
  <svg
    {...rest}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="black" fillOpacity="0.06" />
    <path
      d="M20.5156 14.8906C21.9219 14.8906 22.625 15.4531 22.9062 16.2969C23 16.7188 23.4688 17 23.8906 17H23.9375C24.6406 17 25.2031 16.2969 24.9219 15.6406C24.5 14.4688 23.5156 13.5781 22.0156 13.2031V12.5C22.0156 11.6562 21.3125 11 20.5156 11C19.6719 11 19.0156 11.6562 19.0156 12.5V13.1562C18.5938 13.25 18.2656 13.3906 17.8906 13.5312L19.3906 15.0312C19.7188 14.9375 20.0938 14.8906 20.5156 14.8906ZM12.7812 12.6406C12.3594 13.0156 12.3594 13.625 12.7812 14.0469L15.5 16.7656C15.5 18.8281 17.0469 20 19.3906 20.7031L22.9062 24.1719C22.5781 24.6875 21.875 25.1094 20.5156 25.1094C18.8281 25.1094 17.9844 24.5 17.6562 23.6562C17.5156 23.2812 17.1875 23 16.7656 23H16.5781C15.875 23 15.3594 23.75 15.6406 24.4062C16.25 25.7188 17.5625 26.5156 19.0156 26.8438V27.5C19.0156 28.3438 19.6719 29 20.5156 29C21.3594 29 22.0156 28.3438 22.0156 27.5V26.8438C22.9531 26.6562 23.8438 26.2812 24.4531 25.7188L26 27.2188C26.375 27.6406 26.9844 27.6406 27.4062 27.2188C27.7812 26.8438 27.7812 26.2344 27.4062 25.8125L14.1875 12.6406C13.8125 12.2188 13.1562 12.2188 12.7812 12.6406Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
