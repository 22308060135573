import { db } from "../services/Firebase";
import { uploadImageUnique } from "../shared/utils/helpers/upload";

export const getCompanieByUserId = async (userId) => {
  let companie = [];
  await db
    .collection("companies")
    .where("userId", "==", userId)
    .get()
    .then((data) => {
      data?.docs?.forEach((data) => {
        companie.push(data);
      });
    });
  return companie[0];
};

export const saveCompanie = async (
  companie,
  activeLanguage,
  setLoading,
  uploadLogo,
  setLoadingProgressUpload,
  then,
  erro
) => {
  try {
    if (uploadLogo) {
      const responseURL = await uploadImageUnique(
        'companies',
        uploadLogo,
        setLoading,
        activeLanguage,
        setLoadingProgressUpload
      );
      companie.data.logo = responseURL;
    }

    await db.collection("companies").doc(companie?.id).set(companie?.data);
    then();
  } catch (error) {
    erro(error);
  }
};
